import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit'
import io from "socket.io-client";

const messagesAdapter = createEntityAdapter()

const access = "full_access"

export const importsApi = createApi({

  reducerPath: 'importsApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Imports'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({

    getShipmentsImport: builder.query({
      query: (data) => ({
        url: `imports?page=${data.page}&size=${data.size}${data.query ? `&query=${data.query}` : ''}}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }),

      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
          }
          const listener = (event) => {
            if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return
            const isPartner = localStorage.getItem('isPartner')
            const userId = localStorage.getItem('userId')
            if (JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, event?.adminData)
              })
            } else {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, '')
              })
            }
          }
          socket.addEventListener('imports_list', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },

      keepUnusedDataFor: 86400000,
      providesTags: ['Imports'],
    }),






    getShipmentsImportManifests: builder.query({
      query: (data) => ({
        url: `imports/${data.id}?page=${data.page}&size=${data.size}${data.query ? `&query=${data.query}` : ''}}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }),

      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
          }
          const listener = (event) => {
            if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return

            const isPartner = localStorage.getItem('isPartner')
            const userId = localStorage.getItem('userId')

            if (JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              if (isPartner == 0) {
                updateCachedData((draft) => {
                  messagesAdapter.upsertOne(draft, event?.adminData || '')
                })
              } else {
                if (event.partner_id == userId) {
                  updateCachedData((draft) => {
                    messagesAdapter.upsertOne(draft, event?.partnerData || '')
                  })
                }
              }
            } else {
              if (isPartner == 0) {
                updateCachedData((draft) => {
                  messagesAdapter.upsertOne(draft, event?.adminData || '')
                })
              } else {
                if (event.partner_id == userId) {
                  updateCachedData((draft) => {
                    messagesAdapter.upsertOne(draft, event?.partnerData || '')
                  })
                }
              }
            }

          }
          socket.addEventListener('shipments_import_list_manifest', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },

      keepUnusedDataFor: 86400000,
      providesTags: ['Imports'],
    }),




    createShipmentsImport: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("myFile", data.image_file);
        formData.append("fileType", data.file_type);
        formData.append("merchantId", data.merchant_id);
        formData.append("storeId", data.store_id);
        return {
          url: `imports/${data.id}`,
          method: 'POST',
          body: formData,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            // 'Accept': 'application/json',
            // 'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Imports'],
    }),


    allMerchants: builder.query({
      query: (data) => ({
        url: `imports/merchants/1`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Imports'],
    }),

    allStores: builder.query({
      query: (data) => ({
        url: `imports/stores/2`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Imports'],
    }),





  }),

})

export const { useAllMerchantsQuery, useAllStoresQuery, useGetShipmentsImportQuery, useGetShipmentsImportManifestsQuery, useCreateShipmentsImportMutation } = importsApi