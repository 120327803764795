import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';

function ExtraUpdate({ getExtraUpdateModal, cancelExtraUpdateModal, uitemresult, orderData, getItemData, userId, userToken, updateitem }) {

  const [getPPrice, setPPrice] = useState();
  const [getPVat, setPVat] = useState();
  const [getPShipping, setPShipping] = useState();
  const [getFMile, setFMile] = useState();
  const [getLMile, setLMile] = useState();
  const [getReference, setReference] = useState();
  const [getSupplierName, setSupplierName] = useState();
  const [getTrackId, setTrackId] = useState();

  return (
    <>
      <Modal className="cancel-modal" show={getExtraUpdateModal} onHide={cancelExtraUpdateModal} centered animation={false}>
        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
              Update purchase information
            </div>
            <IconButton
              variant='outlined'
              size='sm'
              color='danger'
              style={{ minHeight: '27px', maxHeight: '27px' }}
              onClick={cancelExtraUpdateModal}
            >
              <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>

        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className='form-group'>
                <label>Purchase Price</label>
                <input className='form-control' defaultValue={getItemData?.pprice} type='number' onChange={((e) => setPPrice(e.target.value))} />
              </div>
            </div>
            <div className="col-md-6">
              <div className='form-group'>
                <label>Purchase VAT</label>
                <input className='form-control' defaultValue={getItemData?.pvat} type='number' onChange={((e) => setPVat(e.target.value))} />
              </div>
            </div>
            <div className="col-md-12">
              <div className='form-group'>
                <label>Purchase Shipping</label>
                <input className='form-control' defaultValue={getItemData?.pshipping} type='number' onChange={((e) => setPShipping(e.target.value))} />
              </div>
            </div>
            <div className="col-md-6">
              <div className='form-group'>
                <label>First Mile</label>
                <input className='form-control' defaultValue={getItemData?.fmile} type='number' onChange={((e) => setFMile(e.target.value))} />
              </div>
            </div>
            <div className="col-md-6">
              <div className='form-group'>
                <label>Last Mile</label>
                <input className='form-control' defaultValue={getItemData?.lmile} type='number' onChange={((e) => setLMile(e.target.value))} />
              </div>
            </div>

            <div className="col-md-6">
              <div className='form-group'>
                <label>Reference Number</label>
                <input className='form-control' defaultValue={getItemData?.s_ref} type='number' onChange={((e) => setReference(e.target.value))} />
              </div>
            </div>

            <div className="col-md-6">
              <div className='form-group'>
                <label>Supplier Name</label>
                <input className='form-control' defaultValue={getItemData?.s_name} type='text' onChange={((e) => setSupplierName(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className='form-group'>
                <label>Tracking ID</label>
                <input className='form-control' defaultValue={getItemData?.tracking_id} type='number' onChange={((e) => setTrackId(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className='form-group'>
                <Button
                  disabled={uitemresult?.isLoading}
                  onClick={(() =>
                    updateitem({
                      orderId: orderData?.id,
                      itemId: getItemData?.itemId,
                      state: 'extra_update',
                      pprice: getPPrice,
                      pvat: getPVat,
                      pshipping: getPShipping,
                      fmile: getFMile,
                      lmile: getLMile,
                      s_ref: getReference,
                      s_name: getSupplierName,
                      tracking_id: getTrackId,
                      userId: userId,
                      userToken: userToken
                    })
                  )}
                  style={{ width: '100%', fontWeight: 500 }}
                  variant="soft"
                  size="lg"
                >
                  {uitemresult?.isLoading ? 'updating' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default ExtraUpdate;
