import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useFetchQuery } from '../../services/orders';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import OrdersList from './OrdersList';
import { DatePicker, Radio } from 'antd';

const shippingStatusList = ['pending', 'approved', 'fraud', 'cancelled', 'require_attention']
const paymanrtType = ['thwifty', 'goshopright']
const statusList = [
  {
    name: 'Checkout Success',
    value: 'true'
  },
  {
    name: 'Checkout Failed',
    value: 'false'
  }
]
const userEmailIds = ['ali.akbar@ebtida.com', 'fahd@ebtida.com', 'hesham@ebtida.com', 'salman@ebtida.com', 'faiz@ebtida.com', 'hamza@ebtida.com', 'jauhar@ebtida.com', 'anas@ebtida.com']

function OrdersC({ page, limit, shippingStatus, thisShippingStatus, paymentStatus, thisPaymentStatus, paymentMethod, thisPaymentMethod, assignee, thisAssignee, thisDateRange }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const navigate = useNavigate();
  const ordersData = useFetchQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    shipping_status: shippingStatus,
    payment_status: paymentStatus,
    payment_method: paymentMethod,
    assignee: assignee,
    date_range: thisDateRange,
    userEmail: userEmail
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.data?.entities?.undefined?.orders;
  const pagination = ordersData?.data?.entities?.undefined?.pagination;
  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     refetch()
  //   }, 420000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    if (result) {
      navigate(`/order/${result}`)
    } else {
      return;
    }
  }

  // const onRangeChange = (dates, dateStrings) => {
  //   history.push(`${window.location.pathname}?page=${1}&date_range=${dateStrings[0]},${dateStrings[1]}`)
  // };
  const onRangeChange = (date, dateString) => {
    navigate(`${window.location.pathname}?page=${1}&date_range=${dateString}${thisShippingStatus}${thisPaymentStatus}${thisPaymentMethod}${thisAssignee}`)
  };


  return (
    <>
      <div className="tabel_cards">

        <div className="row sticky-pg" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                    <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                    <div className="Order-Pagination">
                      <Pagination
                        style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                        page={parseInt(page)}
                        count={parseInt(pagination?.totalPages)}
                        color="primary"
                        variant="outlined"
                        renderItem={(item) => (
                          <PaginationItem
                            className='list-paginatiion-item'
                            component={Link}
                            to={{
                              pathname: `${window.location.pathname}`,
                              search: `?page=${parseInt(item.page)}${thisShippingStatus}${thisPaymentStatus}${thisPaymentMethod}${thisAssignee}${thisDateRange}`
                            }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative', marginRight: '10px' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for order..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                        <SearchIcon style={{ fontSize: 'inherit' }} />
                      </IconButton>
                    </form>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>
                              Payment:&nbsp; <strong>{paymentStatus === 'true' ? 'Success' : 'Failed'}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          {statusList?.map((name, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${paymentStatus === name.value ? `` : `&payment_status=${name.value}`}`}${thisShippingStatus}${thisAssignee}${thisPaymentMethod}${thisDateRange}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={paymentStatus === name.value ? true : false} />
                                <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{name.name}</div>
                              </div>
                            </MenuItem>

                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Status:&nbsp; <strong>{shippingStatus?.replace(/_/g, ' ')}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          <MenuItem
                            className='orders-sub-side-menu'
                            disabled={shippingStatus === null}
                            component={Link}
                            to={`${window.location.pathname}`}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <input style={{ margin: '0px 5px' }} type='checkbox' checked={shippingStatus === null ? true : false} />
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>All</div>
                            </div>
                          </MenuItem>
                          {shippingStatusList?.map((name, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${shippingStatus === name ? `` : `&shipping_status=${name}`}`}${thisPaymentStatus}${thisAssignee}${thisPaymentMethod}${thisDateRange}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={shippingStatus === name ? true : false} />
                                <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{name?.replace(/_/g, ' ')}</div>
                              </div>
                            </MenuItem>

                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Domain:&nbsp; <strong>{!paymentMethod ? 'All' : paymentMethod?.replace(/_/g, ' ')}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          <MenuItem
                            className='orders-sub-side-menu'
                            disabled={paymentMethod === null}
                            component={Link}
                            to={`${window.location.pathname}${`?page=${1}`}${thisShippingStatus}${thisAssignee}${thisPaymentStatus}${thisDateRange}`}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <input style={{ margin: '0px 5px' }} type='checkbox' checked={paymentMethod === null ? true : false} />
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>All</div>
                            </div>
                          </MenuItem>


                          {paymanrtType?.map((name, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${paymentMethod === name ? `` : `&payment_method=${name}`}`}${thisShippingStatus}${thisAssignee}${thisPaymentStatus}${thisDateRange}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={paymentMethod === name ? true : false} />
                                <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{name?.replace(/_/g, ' ')}</div>
                              </div>
                            </MenuItem>

                          )}

                        </Menu>
                      </Dropdown>
                    </div>
                  </div>



                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Assignee:&nbsp; <strong>{assignee}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          {userEmailIds?.map((email, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${assignee === email ? `` : `&assignee=${email}`}`}${thisShippingStatus}${thisPaymentStatus}${thisPaymentMethod}${thisDateRange}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={assignee === email ? true : false} />
                                <div style={{ textAlign: 'left' }}>{email}</div>
                              </div>
                            </MenuItem>
                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>

                </div>
                <div className="table_search_card">
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 0px' }}>
                    <div style={{ position: 'relative' }}>
                      <DatePicker className='op-daterange-select' onChange={onRangeChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>

          <div style={{ padding: 0, width: '100%' }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(ordersData?.isLoading, ordersData?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table" style={{ margin: 0 }}>
                <thead>
                  <tr style={{ position: 'relative' }}>
                    <th style={{ width: '0px' }}>Order</th>
                    <th>Items</th>
                    <th>Amount</th>
                    <th>Shipping To</th>
                    <th>Domain</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>Placed On</th>
                  </tr>
                </thead>

                <tbody>
                  <OrdersList ordersData={ordersData} />
                </tbody>
              </table>

              {ordersData?.error?.originalStatus === 403 &&
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{ordersData?.error?.data}</div>
                </div>
              }

            </div>
            {orders?.length === 0 &&
              <div style={{ padding: '20px', textAlign: 'center' }}>No Data Found</div>
            }
          </div>
        </div>
        <br /><br />
      </div>
    </>
  );
}

export default OrdersC;
