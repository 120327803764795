import React from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useFetchOrdersSearchQuery } from '../../services/products';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment'
import AtmIcon from '@mui/icons-material/Atm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Tag } from 'antd';

function OrdersSearch() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const fetchOrderSearch = useFetchOrdersSearchQuery({
    page: page,
    size: limit,
    query: id,
    userId: userId,
    userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const orders = fetchOrderSearch?.data?.orders;
  const pagination = fetchOrderSearch?.data?.pagination;

  function shippingStatusC(data) {
    if (data === 'approved') {
      return 'badge badge-success'
    } else if (data === 'cancelled') {
      return 'badge badge-danger'
    } else if (data === 'fraud') {
      return 'badge badge-danger'
    } else if (data === 'require_attention') {
      return 'badge badge-dark'
    } else {
      return 'badge badge-warning'
    }
  }


  function borderColor(data) {
    let color
    if (data === 'approved') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }
  function borderColorSticky(data) {
    let color
    if (data === 'delivered') {
      color = '#f2f4f0'
    } else if (data === 'cancelled') {
      color = '#f8f4f3'
    } else {
      color = '#f7f5ee'
    }
    return color
  }


  function getPaymentTypeIcon(type) {
    if (type === 'card') {
      return <CreditCardIcon style={{ fontSize: '15px' }} />
    } else if (type === 'apple_pay') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'bank_transfer') {
      return <AtmIcon style={{ fontSize: '15px' }} />
    } else {
      return ''
    }
  }

  function getLoadingProgress(loading1, loader2) {
    if (loading1) {
      return true
    } else if (loader2) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards">


      <div className="row sticky-pg" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div>
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">
                    <Pagination
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
        <div style={{ padding: 0, width: '100%' }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(fetchOrderSearch?.isLoading, fetchOrderSearch?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table" style={{ margin: 0 }}>
              <thead>
                <tr style={{ position: 'relative' }}>
                  <div style={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    top: 0,
                    height: '100%',
                    background: '#fff0',
                  }}>
                  </div>
                  <th style={{ width: '0px' }}>Order</th>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Shipping To</th>
                  <th>Domain</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>Placed On</th>
                </tr>
              </thead>
              <tbody>
                {orders?.map((item, i) =>
                  <tr key={i} style={{ position: 'relative' }}>
                    <div style={{
                      position: 'absolute',
                      right: 0,
                      left: 0,
                      top: 0,
                      height: '100%',
                      background: borderColor(item.states),
                    }}>
                    </div>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Link to={`/order/${item.order_number}`} style={{ position: 'relative', zIndex: 111, color: !item.status ? 'tomato' : '#5a5858' }}>
                          {item.order_number}
                        </Link>
                      </div>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Link to={`/order/${item.order_number}`} style={{ position: 'relative', zIndex: 111, color: !item.status ? 'tomato' : '#5a5858' }}>
                        <div style={{ textAlign: 'start' }}>
                          {item?.user && <div className='alert alert-info' style={{ background: '#fff0', border: 'none', margin: 0, padding: 0 }}>{item?.user?.email}</div>}
                          {item?.shipping_address && <div className='alert alert-info' style={{ background: '#fff0', border: 'none', margin: 0, padding: 0 }}>{item?.shipping_address?.email}</div>}
                          {item?.guest_shipping?.length > 0 && <div className='alert alert-info' style={{ background: '#fff0', border: 'none', margin: 0, padding: 0 }}>{item?.guest_shipping?.[0]?.email}</div>}

                          {item?.user && <div className='alert alert-info' style={{ background: '#fff0', border: 'none', margin: 0, padding: 0 }}>{item?.user?.phone}</div>}
                          {item?.shipping_address && <div className='alert alert-info' style={{ background: '#fff0', border: 'none', margin: 0, padding: 0 }}>{item?.shipping_address?.phone}</div>}
                          {item?.guest_shipping?.length > 0 && <div className='alert alert-info' style={{ background: '#fff0', border: 'none', margin: 0, padding: 0 }}>{item?.guest_shipping?.[0]?.phone}</div>}
                        </div>
                      </Link>
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.status ?
                          <div>
                            {item.currency}  {Number(item.amount) + Number(item.shipping_price)}
                          </div>
                          :
                          <span
                            className="alert alert-danger boot-alert-style">
                            {item.currency}  {Number(item.amount) + Number(item.shipping_price)}
                          </span>
                        }
                      </div>
                    </td>
                    <td>
                      <div className="dFlex center nowrap jcr">
                        {!item.status ? <span className='badge badge-danger'>Payment Failed</span> :
                          <>
                            {item.checkout === 'failed' ? <span className='badge badge-danger'>Payment Failed</span> :
                              <span className={shippingStatusC(item.states)} style={{ textTransform: 'capitalize' }}>
                                {item.states?.replace(/_/g, ' ')}
                              </span>
                            }
                          </>
                        }
                      </div>
                    </td>
                    <td>
                      <div style={{ position: 'relative', zIndex: 111, color: !item.status && 'tomato' }}>{item.domain}</div>
                    </td>
                    <td className="sticky-column" style={{ background: borderColorSticky(item.shipping_status) }}>
                      <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.status && 'tomato' }}>
                        {moment(item?.createdAt).format('lll')}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {orders?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>No Data Found</div>
          }
        </div>
      </div>
    </div>
  );
}

export default OrdersSearch;
