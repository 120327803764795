import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/joy/Checkbox';

function UpdateDelivery({ getUpdateDeliveryModal, cancelUpdateDeliveryModal, getUpdateDeliveryData, uitemresult, updateItemState, shippingName, shippingEmail }) {

  const [getUpdateDeliveryDays, setUpdateDeliveryDays] = useState();
  const [getComment, setComment] = useState('');

  const [getEmailSend, setEmailSend] = useState(false);
  const handleChange = (data) => {
    setEmailSend(data);
  };

  return (
    <>
      <Modal className="cancel-modal" show={getUpdateDeliveryModal} onHide={cancelUpdateDeliveryModal} centered animation={false}>

        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
              Update delivery days
            </div>
            <IconButton
              variant='outlined'
              size='sm'
              color='danger'
              style={{ minHeight: '27px', maxHeight: '27px' }}
              onClick={cancelUpdateDeliveryModal}
            >
              <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Modal.Header>

        <Modal.Body>

          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0px 10px 0px' }}>
                  <Checkbox
                    className='update-check'
                    size="md"
                    onChange={((event) => handleChange(event.target.checked))}
                    label="Send Email"
                  />
                </div>
                <div className='form-group'>
                  <label>Addon delivery in days in numbers</label>
                  <input className='form-control' required onChange={((e) => setUpdateDeliveryDays(e.target.value))} />
                </div>
                <div className='form-group'>
                  <label>Comment</label>
                  <textarea className='form-control' required onChange={((e) => setComment(e.target.value))}></textarea>
                </div>

                <div className='form-group'>
                  <Button
                    disabled={uitemresult?.isLoading}
                    onClick={(() => updateItemState({
                      name: shippingName,
                      email: shippingEmail,
                      email_send: getEmailSend,
                      state: getUpdateDeliveryData?.state,
                      itemId: getUpdateDeliveryData?.itemId,
                      image: getUpdateDeliveryData?.image,
                      orderId: getUpdateDeliveryData?.orderId,
                      delivery_days: getUpdateDeliveryDays,
                      comment: getComment,
                      estimated_delivery: getUpdateDeliveryData?.estimated_delivery,
                    }))}
                    style={{ fontWeight: 500, width: '100%' }}
                    variant="soft"
                    size="lg"
                  >
                    {uitemresult?.isLoading ? 'updating' : 'Submit'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default UpdateDelivery;
