import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Modal, Spinner } from 'react-bootstrap'
import './index.scss';
import { useImportFileMutation, useFetchStoreProductsCashedQuery } from '../../services/products';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CSVLink, CSVDownload } from 'react-csv';
import Loader from '../../Helper/Loader';
import { useDropzone } from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};



const socket = io(process.env.REACT_APP_API_CONNECT);

function ProductsImport() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { storeId } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const store = new URLSearchParams(location.search).get('store') || null

  const [getFileType, setFileType] = useState('');
  const storeProductsInfo = useFetchStoreProductsCashedQuery({ import_type: getFileType, id: storeId, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const storeProducts = storeProductsInfo?.data?.results;

  const [updatefile, updatefileresult] = useImportFileMutation()
  const [getImportFile, setImportFile] = useState();

  function importProductsFile() {
    updatefile({
      image_file: getImportFile,
      import_type: getFileType,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      store_id: storeId,
    })
  }

  const [progress, setProgress] = useState({ progress: 0, type: '', in_progress: false });
  useEffect(() => {
    socket.on(`csv-file-uploading`, (data) => {
      setProgress(data?.results);
    });
    return () => {
      socket.off(`csv-file-uploading`);
    };
  }, [socket]);


  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  function buttonDisabled(loading, file, progress) {
    if (loading) {
      return true
    } else if (!file) {
      return true
    } else if (progress) {
      return true
    } else {
      return false
    }
  }

  const csvData = [
    ['title',
      'image_urls',
      'price_in_usd',
      'brand',
      'category',
      'weight_in_grams',
      'main_image',
      'country', 'uid',
      'in_stock',
      'condition',
      'delivery_days',
      'qty',
      'google_merchant',
      'features',
      'details',
      'variants',
      'current_variant',
      'specifications',
      'status'],
    [
      'product title',
      'https://microless.com/cdn/products/ac6258f5518377337ffa2c865096b921-md.jpg,https://microless.com/cdn/products/5a7b1eec58f28385835cd0b931e5ebc2-md.jpg',
      '200',
      'AMD',
      'laptop',
      '100',
      'https://prod-admin-images.s3.ap-south-1.amazonaws.com/WbjerLzUeW0cgVsOq5zQ/product/Ix1600.png',
      'AE',
      '119541',
      'In Stock',
      'New',
      2,
      10,
      0,
      ['features1', 'fetaures2'],
      'product deatils',
      `[{'129009': {'Generation:': '14th Gen', 'Series:': 'Core i7', 'Product Line Suffix:': 'K'}}, {'129006': {'Generation:': '14th Gen', 'Series:': 'Core i5', 'Product Line Suffix:': 'K'}}, {'129007': {'Generation:': '14th Gen', 'Series:': 'Core i9', 'Product Line Suffix:': 'KF'}}, {'112013': {'Generation:': '13th Gen', 'Series:': 'Core i7', 'Product Line Suffix:': 'K'}}]`,
      `{'Generation:': '14th Gen', 'Series:': 'Core i9', 'Product Line Suffix:': 'K'}`,
      `{'Brand': 'Intel', 'Model': 'i9-14900K Box', 'Microless SKU': '129005', 'Best Sellers Rank': '#2 inCPUs / Processors', 'Date first available': '18 October, 2023', 'Shipping Weight': '0.30 Kg', 'Shipping Dimensions': '6.50 cm x 15.00 cm x 16.51 cm'}`,
      0]
  ];

  const csvRefetchedData = [
    ['pid', 'price', 'currency', 'brand', 'country', 'in_stock', 'condition', 'google_merchant', 'status'],
    ['12345678', '99', 'USD', 'apple', 'US', '1', 'New', '1', '0']
  ];

  const MAX_FILE_SIZE = 200 * 1024 * 1024;

  const FileUpload = ({ setImportFile }) => {

    const onDropRejected = React.useCallback((fileRejections) => {
      fileRejections?.forEach((rejection) => {
        const { file, errors } = rejection;
        errors.forEach((error) => {
          if (error.code === "file-too-large") {
            alert(`File "${file.name}" is too large. Max size allowed is 200 MB.`);
          }
          if (error.code === "file-invalid-type") {
            alert(`File "${file.name}" is not a CSV file.`);
          }
        });
      });
    }, []);

    const onDrop = React.useCallback(async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      setImportFile(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      onDropRejected,
      accept: { "text/csv": [".csv"] },
      maxFiles: 1,
      maxSize: MAX_FILE_SIZE,
    });
    return (
      <>
        <IconButton
          title='Attach Invoice'
          {...getRootProps()}
          style={{
            border: '1px dashed #aaa',
            textAlign: 'center',
            width: '100%',
            padding: '10px',
            display: 'block'
          }}
        >
          <input {...getInputProps()} />
          <div><AttachFileIcon style={{ fontSize: '3rem', color: '#5f6264' }} /></div>
          <div> <small>Select a file or drag and drop it here</small></div>

        </IconButton>
      </>
    )
  }

  const fileSizeInMB = (getImportFile?.size / (1024 * 1024)).toFixed(4);
  const fileSizeInGB = (getImportFile?.size / (1024 * 1024 * 1024)).toFixed(2);


  function getProgressType(type) {
    if (type == 'uploading') {
      return 'badge-info'
    } else if (type == 'importing') {
      return 'badge-warning'
    } else if (type == 'completed') {
      return 'badge-success'
    } else {
      return 'badge-warning'
    }
  }

  return (
    <div className="tabel_cards">


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                  width: '100%',
                  whiteSpace: 'nowrap'
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size='sm' variant='soft' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="row" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>



            <div className="col-md-7">
              <div style={{ background: '#f0f4f840', padding: '20px', border: '1px solid #eee', borderRadius: '10px' }}>
                <div className="row">
                  <div className="col-md-12">
                    <div>

                      <div style={{ fontSize: '1rem', fontWeight: 500, textAlign: 'start' }}>
                        Import CSV for New Products or Refetched
                      </div>

                      <div style={{ display: 'block' }}>
                        <CSVLink data={csvData} filename={"import-products-sample.csv"} target="_blank">
                          <small>Product CSV Sample</small>
                        </CSVLink>
                      </div>
                      <div style={{ display: 'block' }}>
                        <CSVLink data={csvRefetchedData} filename={"import-refetched-products-sample.csv"} target="_blank">
                          <small>Refetch product CSV Sample</small>
                        </CSVLink>
                      </div>


                      {updatefileresult?.error?.data?.error &&
                        <div className='alert alert-danger' style={{ textAlign: 'center' }}>
                          {updatefileresult?.error?.data?.error}
                        </div>
                      }


                      {progress?.type &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={progress?.progress} />
                          </Box>

                          <div className={`badge ${getProgressType(progress?.type)}`} style={{ marginRight: '10px' }}>
                            {progress?.type}
                          </div>

                          {progress?.in_progress &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Loader />
                            </div>
                          }
                        </div>
                      }

                    </div>
                  </div>

                  <div className="col-md-12" style={{ display: 'block' }}>
                    <div className="row" style={{ justifyContent: 'center' }}>
                      <div className="col-md-12">
                        <div style={{ padding: '10px 0px 10px 0px', }}>
                          <span style={{ fontWeight: 600 }}>Store Name:&nbsp;</span>
                          {store &&
                            <span>{store}</span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {storeProducts &&
                    <div className="col-md-12" style={{ display: 'block' }}>
                      <div className="row" style={{ justifyContent: 'center' }}>

                        <div className="col-md-12">
                          <div style={{ fontSize: '1rem', fontWeight: '600', padding: '10px 0px 10px 0px', textAlign: 'start' }}>Last updated</div>
                        </div>

                        <div className="col-md-12">
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>File Name:</div>
                            <div>{storeProducts?.file_name}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>Progress:</div>
                            <div>{storeProducts?.progress}%</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>Rows:</div>
                            <div>{storeProducts?.limit}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>Processed:</div>
                            <div>{storeProducts?.processed}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>Chunk Count:</div>
                            <div>{storeProducts?.chunk_count}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: '10px', fontWeight: 500 }}>Chunk Size:</div>
                            <div>{storeProducts?.chunk_size}</div>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  }
                </div>
              </div>
            </div>




            <div className="col-md-5">
              <div style={{ background: '#f0f4f840', padding: '20px', border: '1px solid #eee', borderRadius: '10px' }}>
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>

                  </div>

                  <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-md-11">
                      <label>Select File Type</label>
                      <div className="form-group">
                        <select className="form-control import-file-select" onChange={(e) => setFileType(e.target.value)}>
                          <option selected value={''}>Products CSV</option>
                          <option value={'refetch'}>Refetched products CSV</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-11">
                      <FileUpload setImportFile={setImportFile} />
                      <div style={{ textAlign: 'start', padding: '10px 0px 10px 0px' }}>
                        {!isNaN(fileSizeInMB) &&
                          <div> <small><span style={{ fontWeight: 500 }}>File Size:</span> {fileSizeInMB} MB</small></div>
                        }
                        {getImportFile?.name &&
                          <div><small><span style={{ fontWeight: 500 }}>File Name:</span> {getImportFile?.name}</small></div>
                        }
                      </div>
                    </div>
                    {/* <div className="col-md-11">
                            <div className="form-group">
                              <input multiple type="file" className="form-control import-file-csv"
                                onChange={(e) => setImportFile(e.target.files[0])} />
                            </div>
                          </div> */}
                    <div className="col-md-11">
                      <div className="form-group" style={{ textAlign: 'center' }}>
                        <Button
                          variant="soft"
                          size="lg"
                          style={{ fontWeight: 500, textTransform: 'capitalize', width: '100%' }}
                          disabled={buttonDisabled(updatefileresult.isLoading, getImportFile, progress?.in_progress)}
                          onClick={importProductsFile}>
                          {progress?.type ? progress?.type : 'Update'}
                        </Button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      < br />
    </div>
  );
}

export default ProductsImport;
