import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import { useFetchQuery } from '../../services/orderItems';
import moment from 'moment'
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/joy/Button';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
function DelayedOrders() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const totadtdate = moment().utc('Asia/Dubai').format('YYYY-MM-DD')

  const { data: ordersData, refetch, isLoading, isFetching } = useFetchQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
    payment_status: true,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.entities?.undefined?.order_items;

  function borderColor(data) {
    let color
    if (data === 'delivered') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }

  function getThisStatus(state, sstate, ostate) {
    if (ostate === 'cancelled') return <span className="badge badge-danger">Canceled</span>
    if (ostate === 'fraud') return <span className="badge badge-danger">Fraud</span>
    if (state === 'cancelled') return <span className="badge badge-danger">Canceled</span>
    if (sstate === 'processing') {
      return <span className="badge badge-info" style={{ textTransform: 'capitalize' }}>{sstate?.replace(/_/g, ' ')}</span>
    } else if (sstate === 'delivered') {
      return <span className="badge badge-success" style={{ textTransform: 'capitalize' }}>{sstate?.replace(/_/g, ' ')}</span>
    } else {
      return <span className="badge badge-warning" style={{ textTransform: 'capitalize' }}>{sstate?.replace(/_/g, ' ')}</span>
    }
  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function tableBgColor(state, sstate, ostate) {
    if (ostate === 'cancelled') return '#fcf8f8'
    if (ostate === 'fraud') return '#fcf8f8'
    if (state === 'cancelled') return '#fcf8f8'
    if (sstate === 'processing') {
      return '#f6f8f8'
    } else if (sstate === 'delivered') {
      return '#f6f8f5'
    } else {
      return '#fbf9f3'
    }
  }

  function oiBgColor(data) {
    if (data === 'approved') {
      return 'badge badge-success'
    } else if (data === 'cancelled') {
      return 'badge badge-danger'
    } else if (data === 'highlight') {
      return 'badge badge-info'
    } else if (data === 'requires_attention') {
      return 'badge badge-dark'
    } else {
      return 'badge badge-warning'
    }
  }


  const ComponentToPrintWrapper = ({ item }) => {
    return (
      <tr style={{ position: 'relative', background: tableBgColor(item.state, item.shipping_state, item.order?.states) }}>
        <td>
          <Link style={{ color: !item.order?.status ? 'tomato' : '#5a5858' }} to={`/order/${item.order?.order_number}`}>
            {item.order?.order_number}
          </Link>
        </td>
        <td style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link className="orders-page-item-link" to={`/order/${item.order?.order_number}`}
                style={{ margin: '0 5px', border: `1px solid ${borderColor(item.order?.shipping_status)}`, background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                <span
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    lineHeight: '15px',
                    background: '#fe7f4a',
                    borderRadius: '100px',
                    width: '15px',
                    height: '15px',
                    textAlign: 'center',
                    fontSize: '12px',
                    color: '#fff',
                    fontWeight: 400,
                  }}
                >{item.quantity}</span>
              </Link>
            </div>
          </div>
        </td>
        <td>
          <Link to={`/order/${item.order?.order_number}`} style={{ color: '#5a5858' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                alt=""
              />
              <span className='Ip-title' style={{ margin: '0px 5px', color: !item.order?.status && 'tomato' }}> {item.title}</span>
            </div>
          </Link>
        </td>

        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.order?.status ?
              <div>
                {item.currency}  {Number(item.price)}
              </div>
              :
              <span
                className="alert alert-danger boot-alert-style">
                {item.currency}  {Number(item.price)}
              </span>
            }
          </div>
        </td>
        <td>
          <div className="dFlex center nowrap jcr">
            {getThisStatus(item.state, item.shipping_state, item.order?.states)}
          </div>
        </td>
        <td>
          <div className={oiBgColor(item.state)} >
            <span style={{ textTransform: 'capitalize' }}>{item.state == 'highlight' ? 'highlighted' : item.state?.replace('_', ' ')}</span>
          </div>
        </td>
        <td className="sticky-column" style={{ background: tableBgColor(item.state, item.shipping_state, item.order?.states) }}>
          <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.order?.status && 'tomato' }}>
            {moment(item?.createdAt).format('lll')}
          </div>

        </td>
      </tr>
    );
  };

  return (
    <>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi" style={{ borderTop: '1px solid #e7e9f1' }}>
          <div className="col-md-12">
            <div>
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                  <div style={{ fontSize: '1rem' }}>Recent Items</div>
                  <div className="Order-Pagination">
                    <Button
                      className='b-hover-bn pg-button-hp'
                      component={Link}
                      to={`/items`}
                      style={{ fontWeight: 400 }}
                      variant='soft'
                      size='sm'>
                      <div style={{ marginRight: '5px' }}>View All Items</div>
                      <ArrowForwardOutlinedIcon style={{ fontSize: '1rem' }} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12 list-card-op" style={{ position: 'relative', padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(isLoading, isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table" style={{ margin: 0 }}>
              <thead>
                <tr style={{ position: 'relative' }}>
                  <th>Order Number</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Price</th>
                  <th>Shipping Status</th>
                  <th>Item Status</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                    Placed On
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders?.map(function (item, index) {
                  return <ComponentToPrintWrapper key={index} item={item} />;
                })}
              </tbody>
            </table>
          </div>
          {orders?.length === 0 &&
            <h5 className="data_not-found">No data available in table</h5>
          }
        </div>
      </div>
    </>
  );
}

export default DelayedOrders;
