import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import { useShipmentCreatedQuery, useShipmentDepartedQuery, useShipmentNewCreatedQuery } from '../../services/shipments';
import Loader from '../../Helper/Loader'
import Button from '@mui/joy/Button';
import { addScanValue } from "../../reducers/authReducer";
import { useDispatch } from "react-redux";

function OrdersInfo({ pendingCounts }) {
  const { userId, userToken, userEmail, userName, status, admin, scanValue } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const state = new URLSearchParams(location.search).get('state') || null
  const dispatch = useDispatch();
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''
  const date_range = new URLSearchParams(location.search).get('date_range') || null
  const thisDateRange = date_range ? `&date_range=${date_range}` : ''

  const userData = {
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
  }

  const { data: created } = useShipmentCreatedQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })
  const { data: newcreated } = useShipmentNewCreatedQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })
  const newCreatedCounts = newcreated?.entities?.undefined?.count;
  const { data: departed, isLoading } = useShipmentDepartedQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })

  function resetBoxes() {
    dispatch(addScanValue(0));
  }

  return (
    <>
      <div className="col-md-6 list-card-op" style={{ position: 'relative' }}>
        <div className="db-section-card">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', marginTop: '15px' }}>
            <Link
              className='b-hover-bn'
              to={`${window.location.pathname}?state=created${thisSearch}${thisDateRange}`}
              style={{ display: 'flex', alignItems: 'center', color: state == 'created' ? '#007bff' : '#3c4043', fontSize: '0.9rem', marginRight: '10px' }}>
              <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>Created:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }} className='badge badge-info'>{created}</small>
              }
            </Link>
            <Link
              className='b-hover-bn'
              to={`${window.location.pathname}?state=departed${thisSearch}${thisDateRange}`}
              style={{ display: 'flex', alignItems: 'center', color: state == 'departed' ? '#007bff' : '#3c4043', fontSize: '0.9rem' }}>
              <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>Departed:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }} className='badge badge-success'>{departed}</small>
              }
            </Link>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* <span style={{ fontSize: '1.1rem', fontWeight: 400, }}>All Manifests</span> */}
            <span className='db-card-count' style={{ fontSize: '1.1rem' }}>
              {isLoading &&
                <Loader />}
            </span>
          </div>
        </div>
      </div>

      <div className="col-md-6 list-card-op" style={{ position: 'relative' }}>
        <div className="db-section-card">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', marginTop: '15px' }}>
            <Link
              className='b-hover-bn'
              to={`${window.location.pathname}?state=pending${thisSearch}${thisDateRange}`}
              style={{ display: 'flex', alignItems: 'center', color: state == 'pending' || !state ? '#007bff' : '#3c4043', fontSize: '0.9rem', marginRight: '10px' }}>
              <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>Pending:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }} className='badge badge-warning'>{newCreatedCounts}</small>
              }
            </Link>
            <div
              disabled
              className='b-hover-bn'
              style={{ display: 'flex', alignItems: 'center', color: '#3c4043', fontSize: '0.9rem' }}>
              <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>Scanned:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }} className='badge badge-dark'>{scanValue}</small>
              }
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* <span style={{ fontSize: '1.1rem', fontWeight: 400, }}>Status</span> */}
            <span className='db-card-count' style={{ fontSize: '1.1rem' }}>
              {isLoading &&
                <Loader />}
            </span>
            <div style={{ textAlign: 'end', marginTop: '5px' }}>
              <Button
                style={{ width: 'auto', minHeight: '25px', maxHeight: '25px' }}
                variant='outlined'
                size='sm'
                color='danger'
                onClick={(() => {
                  if (window.confirm('Are you sure to reset?'))
                    resetBoxes()
                }
                )}
              >
                <small>Reset</small>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrdersInfo;
