import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';

function ItemQuantity({ getItemQuantityPopup, cancelItemQuantityModal, uitemresult, orderData, getItemId, userId, userToken, updateitem }) {

  const [getQuantity, setQuantity] = useState(0);

  return (
    <>
      <Modal className="cancel-modal" show={getItemQuantityPopup} onHide={cancelItemQuantityModal} centered animation={false}>

        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
             Update Quantity
            </div>
            <IconButton
              variant='outlined'
              size='sm'
              color='danger'
              style={{ minHeight: '27px', maxHeight: '27px' }}
              onClick={cancelItemQuantityModal}
            >
              <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                <div className='form-group'>
                  <label>Quantity</label>
                  <input className='form-control' type='number' required onChange={((e) => setQuantity(e.target.value))} />
                </div>
                <div className='form-group'>
                  <Button
                    disabled={uitemresult?.isLoading}
                    onClick={(() =>
                      getQuantity && updateitem({
                        orderId: orderData?.id,
                        itemId: getItemId,
                        state: 'update_quantity',
                        quantity: getQuantity,
                        userId: userId,
                        userToken: userToken
                      })
                    )}
                    style={{ width: '100%', fontWeight: 500 }}
                    variant="soft"
                    size="lg"
                  >
                    {uitemresult?.isLoading ? 'updating' : 'Submit'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default ItemQuantity;
