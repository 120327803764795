import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import HeaderMenu from '../components/HeaderMenu'
import './index.scss';
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
// import OrdersMessages from '../components/OrdersMessages';
function Admin(props) {
  const { userId, userToken } = useSelector((state) => state.auth);
  const Components = props.component;

  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };
  React.useEffect(() => {
    if (isMobcreen) {
      setStatesss(true)
    } else {
      setStatesss(false)
    }
  }, [isMobcreen]);

  return (
    <>
      <Helmet>
        <title>Admin | {window.location.hostname}</title>
      </Helmet>
      <div className="page-section">
        <HeaderMenu collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        <div className={`page-sub-section ${collapsed && 'body-collapsed'}`}>
          {userToken ? <Components /> : <Navigate to="/login"></Navigate>}
        </div>
        {/* <div style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 9999 }}>
          <OrdersMessages />
        </div> */}
      </div>
    </>
  );
}

export default Admin;
