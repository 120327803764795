import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { Spinner, Modal } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/team';
import { message, Tag, Switch as Clickable } from "antd";
import Moment from 'moment';
import Loader from '../../Helper/Loader';
import Button from '@mui/joy/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/joy/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

function Team() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin, accessKey } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();

  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const [checked, setChecked] = React.useState([]);
  const handleChange = (data) => {
    setChecked(data);
  };

  const [admin_access, setAdminAccess] = useState([]);
  const [admin_action, setAdminAction] = useState([]);

  const handleAdminAccess = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked) {
      if (!admin_access) {
        setAdminAccess([value]);
      } else {
        setAdminAccess(prevState => [...prevState, value]);
      }
    } else {
      setAdminAccess(prevState => prevState.filter(item => item !== value));
    }
  };

  const handleAdminAction = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked) {
      if (!admin_action) {
        setAdminAction([value]);
      } else {
        setAdminAction(prevState => [...prevState, value]);
      }
    } else {
      setAdminAction(prevState => prevState.filter(item => item !== value));
    }
  };

  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    search: search,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;

  const [createUser, createresult] = useCreateUserMutation()
  const [updateUser, updateresult] = useUpdateUserMutation()

  const hostname = window.location.hostname?.split('.');
  const thisDomain = window.location.hostname === 'localhost' ? 'localhost' : hostname[1]

  function createUserFunction(data) {
    createUser({
      email: data.email,
      password: data.password,
      reference: data.reference,
      user_role: 'admin',
      admin_access: { access: admin_access, action: admin_action },
      userToken: userToken,
      userId: userId,
      userRole: userRole,
      domain: thisDomain
    })
  }

  function ebtidaEModal() {
    setNewModal(true)
    setAdminAccess([])
    setAdminAction([])
  }
  function closeNewModal() {
    setNewModal(false)
  }

  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState([])
  const [getUserRole, setUserRole] = useState('')
  function updateUserModal(data) {
    setUserUpdateModal(true)
    setUserUpdateData(data)
    setAdminAccess(data?.admin_access?.access)
    setAdminAction(data?.admin_access?.action)
  }
  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  const bookItemData = {
    id: getUserUpdateData?.id,
    admin_access: { access: admin_access, action: admin_action },
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    userEmail: getUserUpdateData?.email
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }
    if (updateresult?.isSuccess) {
      setUserUpdateModal(false)
      setChecked([])
    }
  }, [updateresult, createresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset2()
    navigate(`${window.location.pathname}`)
  }

  return (
    <>
      <div className="tabel_cards">
        <Modal className="team-page-modal" show={getUserUpdateModal} onHide={closeUserUpdateModal} animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeUserUpdateModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: 'center', padding: '10px', fontSize: '1.2rem', fontWeight: 500 }}>Update Account</div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '1rem', fontWeight: 600 }}>Admin Access</label>
                  <div className='t-a-access-section' style={{ padding: '5px' }}>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item' style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControlLabel
                          disabled={admin_access?.length > 0 && !admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox
                            defaultChecked={getUserUpdateData?.admin_access?.access?.includes('full_access')}
                            name={'admin_access'}
                            value={'full_access'} />}
                          label="Full Access" />
                      </div>
                    </div>
                  </div>

                  <div className='t-a-access-section'>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Google Merchants</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('google_merchants')} name={'admin_access'} value={'google_merchants'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('google_merchants_edit')} name={'admin_action'} value={'google_merchants_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Shipments</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('shipments')} name={'admin_access'} value={'shipments'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('shipments_edit')} name={'admin_action'} value={'shipments_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Consolidation</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('consolidation')} name={'admin_access'} value={'consolidation'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('consolidation_edit')} name={'admin_action'} value={'consolidation_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Coupons</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('coupons')} name='admin_access' value={'coupons'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('coupons_edit')} name={'admin_action'} value={'coupons_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Stores</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('stores')} name='admin_access' value={'stores'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('stores_edit')} name={'admin_action'} value={'stores_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Products</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('products')} name='admin_access' value={'products'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('products_edit')} name={'admin_action'} value={'products_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Inventory</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('inventory')} name='admin_access' value={'inventory'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('inventory_edit')} name={'admin_action'} value={'inventory_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Orders</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('all_orders')} name='admin_access' value={'all_orders'} />}
                          label="All Orders" />
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('orders')} name='admin_access' value={'orders'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('orders_edit')} name={'admin_action'} value={'orders_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Refunds</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('refunds')} name='admin_access' value={'refunds'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('refunds_edit')} name={'admin_action'} value={'refunds_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Countries Route</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('countries')} name='admin_access' value={'countries'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('countries_edit')} name={'admin_action'} value={'countries_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Team</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('team')} name='admin_access' value={'team'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('team_edit')} name={'admin_action'} value={'team_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Users</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('users')} name='admin_access' value={'users'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('users_edit')} name={'admin_action'} value={'users_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Media</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('media')} name='admin_access' value={'media'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('media_edit')} name={'admin_action'} value={'media_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                    <div className='t-a-access-card'>
                      <div className='t-a-access-item-title'>Generate Orders CSV</div>
                      <div className='t-a-access-item'>
                        <FormControlLabel
                          style={{ margin: '0px 10px' }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAccess}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.access?.includes('orders_csv')} name='admin_access' value={'orders_csv'} />}
                          label="View" />
                        <FormControlLabel
                          style={{ margin: 0 }}
                          disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                          onChange={handleAdminAction}
                          control={<Checkbox defaultChecked={getUserUpdateData?.admin_access?.action?.includes('orders_csv_edit')} name={'admin_action'} value={'orders_csv_edit'} />}
                          label="Edit" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    style={{ fontWeight: 400 }}
                    variant="soft"
                    size="md"
                    onClick={() => !updateresult.isLoading && updateUser(bookItemData)}>
                    {updateresult.isLoading ? <Loader /> : 'Update'}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="team-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeNewModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: 'center', padding: '10px', fontSize: '1.2rem', fontWeight: 500 }}>Create New Account</div>
              </div>
            </div>

            <form onSubmit={handleSubmit(createUserFunction)}>
              {createresult?.error?.data?.error &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', fontWeight: 400, textAlign: 'center' }}>
                  <span className="col-md-12 alert alert-danger">{createresult?.error?.data?.error}</span>
                </div>
              }
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>Reference</label>
                    <input type="text" className="form-control"
                      id="reference"
                      aria-invalid={errors.reference ? "true" : "false"}
                      {...register('reference', { required: true })}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>Email</label>
                    <input type="email" className="form-control"
                      id="email"
                      aria-invalid={errors.email ? "true" : "false"}
                      {...register('email', { required: true })} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>Password</label>
                    <input type="password" autoComplete="off" className="form-control"
                      id="password"
                      aria-invalid={errors.password ? "true" : "false"}
                      {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                    {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                    {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                    {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ fontSize: '1rem', fontWeight: 600 }}>Admin Access</label>
                    <div className='t-a-access-section' style={{ padding: '5px' }}>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item' style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <FormControlLabel
                            disabled={admin_access?.length > 0 && (!admin_access?.includes('full_access'))}
                            onChange={handleAdminAccess}
                            control={<Checkbox name={'admin_access'} value={'full_access'} />}
                            label="Full Access" />
                        </div>
                      </div>
                    </div>
                    <div className='t-a-access-section'>

                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Google Merchants</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name={'admin_access'} value={'google_merchants'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'google_merchants_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Shipments</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name={'admin_access'} value={'shipments'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'shipments_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Consolidation</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name={'admin_access'} value={'consolidation'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'consolidation_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Coupons</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'coupons'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'coupons_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Stores</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'stores'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'stores_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Products</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'products'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'products_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Inventory</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'inventory'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'inventory_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Orders</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'all_orders'} />}
                            label="All Orders" />
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'orders'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'orders_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Refunds</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'refunds'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'refunds_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Countries Route</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'countries'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'countries_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Team</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'team'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'team_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Users</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'users'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'users_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Media</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'media'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'media_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                      <div className='t-a-access-card'>
                        <div className='t-a-access-item-title'>Generate Orders CSV</div>
                        <div className='t-a-access-item'>
                          <FormControlLabel
                            style={{ margin: '0px 10px' }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAccess}
                            control={<Checkbox name='admin_access' value={'orders_csv'} />}
                            label="View" />
                          <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={admin_access?.length > 0 && admin_access?.includes('full_access')}
                            onChange={handleAdminAction}
                            control={<Checkbox name={'admin_access'} value={'orders_csv_edit'} />}
                            label="Edit" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                      style={{ fontWeight: 400 }}
                      disabled={createresult.isLoading ? true : false}
                      variant="soft"
                      size="md"
                      type="submit">
                      {createresult.isLoading ? 'Loading...' : 'Create'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">

                    <Pagination
                      className='list-paginatiion-table'
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      disabled={usersInfo?.isLoading ? true : false}
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}${thisSearch}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit2(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for user..."
                        id="orderId"
                        aria-invalid={errors2.orderId ? "true" : "false"}
                        {...register2("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          disabled={!checked?.event}
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '14px', minHeight: '35px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Action</span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          <MenuItem
                            disabled={!checked?.event}
                            key={'1'}
                            className='orders-sub-side-menu'
                            onClick={() => updateUserModal(checked?.data)}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>Update</div>
                            </div>
                          </MenuItem>
                          <MenuItem
                            disabled={!checked?.event}
                            key={'2'}
                            className='orders-sub-side-menu'
                            onClick={() => updateUser({
                              id: checked?.data?.id,
                              status: checked?.data?.status ? false : true,
                              status_update: 'status_update',
                              userId: userId,
                              userToken: userToken,
                              userRole: userRole
                            })}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{checked?.data?.status ? 'Disable' : 'Enable'}</div>
                            </div>
                          </MenuItem>
                          <MenuItem
                            disabled={!checked?.event}
                            key={'3'}
                            className='orders-sub-side-menu'
                            onClick={() => updateUser({
                              id: checked?.data?.id,
                              admin: checked?.data?.admin ? false : true,
                              admin_update: 'admin_update',
                              userId: userId,
                              userToken: userToken,
                              userRole: userRole
                            })}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>Remove as admin</div>
                            </div>
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="table_search_card">
                  <div>
                    <Button
                      className='pg-button-hp'
                      style={{ fontWeight: 400 }}
                      variant="soft"
                      size="sm"
                      onClick={() => ebtidaEModal()}>
                      <span style={{ marginRight: '5px' }}>Add New</span>
                      <AddCircleOutlinedIcon style={{ fontSize: '1.1rem', }} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(usersInfo?.isLoading, usersInfo?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox disabled size='sm' />
                      </div>
                    </th>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Ref.</th>
                    <th>Updated At</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>
                      Manage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) =>
                    <tr key={i}>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            className='update-check'
                            size="sm"
                            checked={checked?.data?.id === item?.id ? checked?.event : false}
                            onChange={((event) => handleChange({
                              event: event.target.checked,
                              data: item,
                            }))}
                          />
                        </div>
                      </td>
                      <td>
                        <div>{item.id}</div>
                      </td>
                      <td>
                        <Link to={`/orders/user/${item.id}`}>
                          <div> {item.name && item.name + ', '}{item.email ? item.email : item.username}</div>
                        </Link>
                      </td>
                      <td>
                        <div>{item.phone}</div>
                      </td>
                      <td>
                        {item.reference &&
                          <span>{item.reference}</span>
                        }
                      </td>
                      <td>
                        <div>{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
                      </td>
                      <td className="sticky-column" style={{ textAlign: 'right' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                          {item.state &&
                            <Tag className="booksTag" color={"#7dc487"}>
                              <small>{item.state}</small>
                            </Tag>
                          }
                          <div className={item.status ? `badge badge-success` : `badge badge-danger`}>{item.status ? 'Active' : 'Disabled'}</div>
                        </div>
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
            {users?.length === 0 ?
              <h5 className="data_not-found">No data available in table</h5>
              : null}
            {usersInfo?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{usersInfo?.error?.data}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
