import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetAllRefundsQuery } from '../../services/refund';
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from 'react-bootstrap'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import AtmIcon from '@mui/icons-material/Atm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';

function Refunds() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin, accessKey } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const { data: usersInfo, isSuccess, isLoading, isFetching, error, refetch } = useGetAllRefundsQuery({
    page: page,
    size: limit,
    search: search,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.refunds;
  const pagination = usersInfo?.pagination;

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset()
    navigate(`${window.location.pathname}`)
  }

  const stateArray = ['succeeded', 'SUCCESS', 'success', 'sucess']

  function getState(state) {
    if (stateArray?.includes(state)) {
      return <span className='badge badge-success' style={{ textTransform: 'capitalize' }}>{state}</span>
    } else {
      return <span className='badge badge-danger' style={{ textTransform: 'capitalize' }}>{state}</span>
    }
  }


  const [getUpdateStatus, setUpdateStatus] = useState(false)

  useEffect(() => {
    if (getUpdateStatus) {
      refetch()
    }
  }, [getUpdateStatus]);


  useEffect(() => {
    if (isSuccess) {
      setUpdateStatus(false)
    }
  }, [isSuccess]);


  function getPaymentTypeIcon(type) {
    if (type === 'card') {
      return <CreditCardIcon style={{ fontSize: '15px' }} />
    } else if (type === 'apple_pay') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'payby_apple') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'bank_transfer') {
      return <AtmIcon style={{ fontSize: '15px' }} />
    } else if (type === 'tap') {
      return <img src='Logo_7de102aab6.svg' style={{ width: '20px' }} />
    } else if (type === 'tap_tabby') {
      return <img src='tabby-image.png' style={{ width: '20px' }} />
    } else if (type === 'auth_success') {
      return <img src='stripe-image.png' style={{ width: '20px' }} />
    } else {
      return ''
    }
  }






  return (
    <>

      <div className="tabel_cards">

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">

                    <Pagination
                      className='list-paginatiion-table'
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      disabled={isLoading ? true : false}
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}${thisSearch}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for user..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                </div>
                <div className="table_search_card">
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(isLoading, isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Order Number</th>
                    <th>Amount</th>
                    <th>State</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) =>
                    <tr key={i}>
                      <td>
                        <Dropdown>
                          <MenuButton
                            variant='outlined'
                            size='sm'
                            style={{ borderRadius: '4px', minHeight: '18px', maxHeight: '18px', padding: '0px', marginRight: '5px' }}
                            className="Header-button-connect">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ArrowDropDownIcon style={{ fontSize: '1.2rem' }} />
                            </div>
                          </MenuButton>
                          <Menu>
                            <MenuItem key={i}> </MenuItem>
                          </Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <Link to={`/order/${item.order_number}`}>
                          <div> {item.order_number}</div>
                        </Link>
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center', textTransform: 'uppercase' }}>
                          {item.order?.payment_type &&
                            <div style={{ marginRight: '3px', width: '20px', height: '20px', objectFit: 'contain', padding: '1px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {getPaymentTypeIcon(item.order?.payment_type)}
                            </div>
                          }
                          <div>{item.currency}&nbsp;{item.amount}</div>
                        </div>
                      </td>
                      <td>
                        <div>{getState(item.state)}</div>
                      </td>
                      <td className="sticky-column" style={{ textAlign: 'right' }}>
                        <div>{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
            {users?.length === 0 ?
              <h5 className="data_not-found">No data available in table</h5>
              : null}
            {error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{error?.data}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Refunds;
