import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useFetchGoogleMerchantsQuery, useCreateGoogleMrchantAccountMutation, useUpdateGoogleMerchantAccountMutation, useDeleteMerchantMutation, useSyncProductsMutation } from '../../services/google_merchant';
import { useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Divider from '@mui/material/Divider';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

function GoogleMerchants() {
  const { userId, userToken, userEmail, userName, admin, accessKey } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const status = new URLSearchParams(location.search).get('status') || 'active'
  const thisStatus = status ? `&status=${status}` : ''
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const [getSearchId, setSearchId] = useState("")

  const productInfo = useFetchGoogleMerchantsQuery({ status: status, page: page, size: limit, userId: userId, userToken: userToken, admin: admin, search: search, accessKey: accessKey }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const googleMerchants = productInfo?.data?.google_merchants;
  const pagination = productInfo?.data?.pagination;

  const [createNew, createresult] = useCreateGoogleMrchantAccountMutation()
  const [deleteList, deleteresult] = useDeleteMerchantMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);

  const [getMerchantId, setMerchantId] = useState();
  const [getDomain, setDomain] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getAccountName, setAccountName] = useState("")
  const [getAnalyticId, setAnalyticId] = useState(0)
  const [getLimits, setLimits] = useState("")
  const [getID, setID] = useState("")

  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setMerchantId(data.merchant_id)
    setDomain(data.domain)
    setCountry(data.country)
    setAccountName(data.account_name)
    setAnalyticId(data.analytics_id)
    setLimits(data.limits)
    setID(data.id)
  }

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  const [updatePost, updateresult] = useUpdateGoogleMerchantAccountMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
      reset()
    }
  }, [updateresult, createresult]);

  function createNewPost(data) {
    createNew({
      merchant_id: data.merchant_id,
      domain: data.domain,
      country: data.country,
      account_name: data.account_name,
      analytics_id: data.analytics_id,
      limits: data.limits,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  const updateData = {
    id: getID,
    merchant_id: getMerchantId,
    domain: getDomain,
    country: getCountry,
    account_name: getAccountName,
    analytics_id: getAnalyticId,
    limits: getLimits,
    userId: userId,
    userToken: userToken,
    admin: admin
  }


  const [createsync, syncresult] = useSyncProductsMutation()
  const [getStoreId, setStoreId] = useState();
  const [getSyncModal, setSyncModal] = useState(false);
  const [getSyncData, setSyncData] = useState(false);
  const [getSyncType, setSyncType] = useState('');

  function syncGoogleMerchant(data) {
    setSyncModal(true)
    setSyncData(data?.data)
    setSyncType(data?.type)
  }

  function closeSyncModal(data) {
    setSyncModal(false)
  }
  useEffect(() => {
    if (syncresult?.isSuccess) {
      setSyncModal(false)
      setStoreId()
    }
  }, [syncresult]);

  function getLoadingProgress(thisLoading, thisFetching, loadingD) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else if (loadingD) {
      return true
    } else {
      return false
    }
  }


  function getStateFunc(state) {
    if (state === 'error') {
      return <span className='badge badge-danger' style={{ height: '20px', display: 'flex', alignItems: 'center' }}>Error</span>
    } else if (state === 'finished') {
      return <span className='badge badge-success' style={{ height: '20px', display: 'flex', alignItems: 'center' }}>Finished</span>
    } else if (state === 'syncing') {
      return <span className='badge badge-info' style={{ height: '20px', display: 'flex', alignItems: 'center' }}>Syncing</span>
    } else if (state === 'deleting') {
      return <span className='badge badge-danger' style={{ height: '20px', display: 'flex', alignItems: 'center' }}>deleting</span>
    } else {
      return <span className='badge badge-warning' style={{ height: '20px', display: 'flex', alignItems: 'center' }}>Pending</span>
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset2()
    navigate(`${window.location.pathname}`)
  }

  return (
    <>
      <div className="tabel_cards">

        <Modal className="sync-modal" show={getSyncModal} onHide={closeSyncModal} centered animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeSyncModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h6 style={{ textAlign: 'center', padding: '10px', textTransform: 'capitalize' }}>
                  <span className={`badge ${getSyncType === 'sync' ? `badge-success` : `badge-danger`}`}>{getSyncType?.replace(/_/g, ' ')}</span> products to google merchants
                </h6>
              </div>
            </div>
            <form onSubmit={handleSubmit(createNewPost)}>
              <div className="row">

                <div className="col-md-12">
                  <div className="form-group">
                    <div>
                      <div>Merchant Id: {getSyncData?.merchant_id}</div>
                      <div>Domain: {getSyncData?.domain}</div>
                      <div>Country: {getSyncData?.country}</div>
                      <div>Store Id {getStoreId}</div>
                      <div>Sync type:<span className={`badge ${getSyncType === 'sync' ? `badge-success` : `badge-danger`}`}>{getSyncType?.replace(/_/g, ' ')}</span></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Store ID</label>
                    <input type="text"
                      className="form-control"
                      defaultValue={getStoreId}
                      onChange={(e) => setStoreId(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                      disabled={!getStoreId}
                      variant="soft"
                      size="sm"
                      style={{ fontWeight: 500 }}
                      onClick={(() => getStoreId && createsync({
                        id: getSyncData?.id,
                        merchantId: getSyncData?.merchant_id,
                        country: getSyncData?.country,
                        limits: getSyncData?.limits,
                        domain: getSyncData?.domain,
                        sync_type: getSyncType === 'sync' ? '' : 'delete',
                        storeId: getStoreId,
                        userId: userId,
                        userToken: userToken,
                        admin: admin
                      }))}
                    >
                      {'Sync Now'}
                    </Button>
                  </div>
                </div>
              </div>
            </form >
          </Modal.Body >
        </Modal>

        <Modal className="sync-modal" show={show} onHide={handleClose} centered animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={handleClose}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Add New Account</h5>
              </div>
            </div>

            <form onSubmit={handleSubmit(createNewPost)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Merchant ID</label>
                    <input type="text" className="form-control" id="merchant_id"
                      aria-invalid={errors.merchant_id ? "true" : "false"}
                      {...register('merchant_id', { required: true })} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Domain</label>
                    <input type="text" className="form-control" id="domain"
                      aria-invalid={errors.domain ? "true" : "false"}
                      {...register('domain', { required: true })} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control" id="country"
                      aria-invalid={errors.country ? "true" : "false"}
                      {...register('country', { required: true })}>
                      <option value={''} selected disabled>Select</option>
                      <option value="AE">UAE</option>
                      <option value="IN">India</option>
                      <option value="PK">Pakistan</option>
                      <option value="KW">Kuawait</option>
                      <option value="OM">Oman</option>
                      <option value="BH">Nahrain</option>
                      <option value="UK">UK</option>
                      <option value="QA">Qatar</option>
                      <option value="SA">Saudi</option>
                      <option value="AU">Australia</option>
                      <option value="CA">Canada</option>
                      <option value="NZ">New Zealand</option>
                      <option value="IE">Ireland</option>
                      <option value="ZA">South Africa</option>
                      <option value="DE">Germany</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Account Name</label>
                    <input type="text" className="form-control" id="account_name"
                      aria-invalid={errors.account_name ? "true" : "false"}
                      {...register('account_name', { required: true })} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Analytic ID</label>
                    <input type="text" className="form-control" id="analytics_id"
                      aria-invalid={errors.analytics_id ? "true" : "false"}
                      {...register('analytics_id', { required: true })} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>limits</label>
                    <input type="number" className="form-control" id="limits"
                      aria-invalid={errors.limits ? "true" : "false"}
                      {...register('limits', { required: true })} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                      variant={createresult?.isLoading ? 'outlined' : 'soft'}
                      loading={createresult?.isLoading}
                      loadingIndicator="Loading…"
                      size="sm"
                      style={{ fontWeight: 500 }}
                      type="submit">
                      Create
                    </Button>
                  </div>
                </div>
              </div>

            </form >
          </Modal.Body >
        </Modal>

        <Modal className="sync-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={handleCloseEdit}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Account</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Merchant ID</label>
                  <input type="text" className="form-control" defaultValue={getMerchantId} onChange={(e) => setMerchantId(e.target.value)} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Domain</label>
                  <input type="text" className="form-control" defaultValue={getDomain} onChange={(e) => setDomain(e.target.value)} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Country</label>
                  <select className="form-control" defaultValue={getCountry} onChange={(e) => setCountry(e.target.value)}>
                    <option value={''} selected disabled>Select</option>
                    <option value="AE">UAE</option>
                    <option value="IN">India</option>
                    <option value="PK">Pakistan</option>
                    <option value="KW">Kuawait</option>
                    <option value="OM">Oman</option>
                    <option value="BH">Nahrain</option>
                    <option value="UK">UK</option>
                    <option value="QA">Qatar</option>
                    <option value="SA">Saudi</option>
                    <option value="AU">Australia</option>
                    <option value="CA">Canada</option>
                    <option value="NZ">New Zealand</option>
                    <option value="IE">Ireland</option>
                    <option value="ZA">South Africa</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Account Name</label>
                  <input type="text" className="form-control" defaultValue={getAccountName} onChange={(e) => setAccountName(e.target.value)} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Analytic ID</label>
                  <input type="text" className="form-control" defaultValue={getAnalyticId} onChange={(e) => setAnalyticId(e.target.value)} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>limits</label>
                  <input type="number" className="form-control" defaultValue={getLimits} onChange={(e) => setLimits(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    variant={updateresult?.isLoading ? 'outlined' : 'soft'}
                    loading={updateresult?.isLoading}
                    loadingIndicator="Loading…"
                    size="sm"
                    style={{ fontWeight: 500 }}
                    onClick={() => { !updateresult?.isLoading && updatePost(updateData) }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div
                    style={{ padding: 0, margin: 0, background: '#fff0', border: 'none', fontSize: '15px' }}
                    className='alert alert-info'>
                    Showing {pagination?.totalCounts && pagination?.totalCounts} entries
                  </div>
                  <div className="Order-Pagination">
                    <Pagination
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}${thisStatus}${thisSearch}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit2(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search..."
                        id="orderId"
                        aria-invalid={errors2.orderId ? "true" : "false"}
                        {...register2("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <Dropdown>
                      <MenuButton
                        size='sm'
                        variant='outlined'
                        style={{ borderRadius: '6px', fontWeight: 500, minHeight: '35px', maxHeight: '35px' }}
                        className="Header-button-connect">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <small style={{ textTransform: 'capitalize' }}>Filter:&nbsp; {status}</small>
                          <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                        </div>
                      </MenuButton>
                      <Menu>
                        <MenuItem disabled={status === 'all'} component={Link} to={`${window.location.pathname}?status=all`}>All</MenuItem>
                        <MenuItem disabled={status === 'active'} component={Link} to={`${window.location.pathname}?status=active`}>Active Accounts</MenuItem>
                        <MenuItem disabled={status === 'inactive'} component={Link} to={`${window.location.pathname}?status=inactive`}>Inactive Accounts</MenuItem>
                      </Menu>
                    </Dropdown>

                  </div>
                </div>
                <div className="table_search_card">
                  <div>
                    <Button
                      className='pg-button-hp'
                      style={{ fontWeight: 400 }}
                      variant="soft"
                      size="sm"
                      onClick={() => handleOpen(true)}>
                      <span style={{ marginRight: '5px' }}>Add New</span>
                      <AddCircleOutlinedIcon style={{ fontSize: '1.1rem' }} />
                    </Button>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(productInfo?.isLoading, productInfo?.isFetching, deleteresult?.isLoading) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th>Merchant</th>
                    <th>Account</th>
                    <th>Domain</th>
                    <th>Country</th>
                    <th>Limit</th>
                    <th>Store</th>
                    <th>Created At</th>
                    <th className="sticky-column tar" style={{ textAlign: 'right', width: '1px' }}>State</th>
                  </tr>
                </thead>

                <tbody>
                  {googleMerchants?.map((item, i) =>
                    <tr key={i}>
                      <td>
                        <Link to={`/google-merchant/${item.merchant_id}`} style={{ whiteSpace: 'initial' }}>
                          {item.merchant_id}
                        </Link>
                      </td>
                      <td>{item.account_name}</td>
                      <td> {item.domain} </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.country}
                        </div>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        <span><span className='badge badge-secondary'>{item.limits}</span></span>
                        <span className='badge badge-success' style={{ margin: '0px 4px' }}>
                          <span>{item.success}</span>
                        </span>
                        <span className='badge badge-danger'>
                          <span>{item.errors}</span>
                        </span>
                      </td>
                      <td>{item.store_id}</td>
                      <td>{moment.utc(item.updatedAt).format('lll')}</td>
                      <td className="sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <div style={{ margin: '0px 10px' }}>{getStateFunc(item.state)}</div>
                          <Dropdown>
                            <MenuButton
                              size='sm'
                              style={{ padding: '0px', minHeight: '20px', borderRadius: '6px' }}
                              className="Header-button-connect">
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ArrowDropDownIcon style={{ fontSize: '20px' }} />
                              </div>
                            </MenuButton>
                            <Menu>

                              <MenuItem disabled={item.state === 'finished'} key='1' className='orders-sub-side-menu'
                                onClick={() => syncGoogleMerchant({ data: item, type: 'sync' })}
                              >
                                {item.state === 'pending' ? 'Start' : 'Restart'} Sync
                              </MenuItem>

                              <MenuItem key='2' className='orders-sub-side-menu'
                                onClick={() => syncGoogleMerchant({ data: item, type: 'out_of_sync' })}
                              >
                                Delete offline/disabled products
                              </MenuItem>
                              <Divider />
                              <MenuItem key='3' className='orders-sub-side-menu'
                                onClick={(() => {
                                  if (window.confirm('Are you sure to change?'))
                                    deleteList({
                                      id: item.id,
                                      reset: true,
                                      userId: userId,
                                      userToken: userToken,
                                      admin: admin
                                    })
                                })}
                              >
                                Reset Sync
                              </MenuItem>
                              <MenuItem key='4' className='orders-sub-side-menu'
                                onClick={() => handleOpenEdit(item)}
                              >
                                Update
                              </MenuItem>
                              <MenuItem key='5' className='orders-sub-side-menu'
                                onClick={() => {
                                  if (window.confirm('Are you sure to change?'))
                                    deleteList({
                                      id: item.id,
                                      status: item.status ? false : true,
                                      userId: userId,
                                      userToken: userToken,
                                      admin: admin
                                    })
                                }}
                              >
                                Mark as {item.status ? 'Active' : 'Inactive'}
                              </MenuItem>
                            </Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {googleMerchants?.length === 0 &&
              <h5 className="data_not-found">No data available in table</h5>
            }
            {productInfo?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{productInfo?.error?.data}</div>
              </div>
            }
          </div>
        </div>
        <br /><br />
      </div>
    </>
  );
}

export default GoogleMerchants;
