import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import { useIndexQuery, useUpdateMutation } from '../../services/shipments';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/joy/IconButton';
import moment from 'moment'
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/joy/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import { DatePicker, Radio } from 'antd';
import OrdersInfo from './OrdersInfo';
import { useDispatch } from "react-redux";
import { addScanValue } from "../../reducers/authReducer";
import { notification, Space } from 'antd';
import "antd/dist/antd.min.css";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import Loader from '../../Helper/Loader';
function DelayedOrders() {
  const { userId, userToken, userEmail, userName, status, admin, scanValue } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const navigate = useNavigate();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''
  const state = new URLSearchParams(location.search).get('state') || null
  const thisState = state ? `&state=${state}` : ''
  const date_range = new URLSearchParams(location.search).get('date_range') || null
  const thisDateRange = date_range ? `&date_range=${date_range}` : ''

  const totadtdate = moment().utc('Asia/Dubai').format('YYYY-MM-DD')

  const onRangeChange = (date, dateString) => {
    navigate(`${window.location.pathname}?date_range=${dateString}${thisState}${thisSearch}`)
  };
  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`${window.location.pathname}?search=${result}${thisState}${thisDateRange}`)
  }

  function clearSearch() {
    reset2()
    navigate(`${window.location.pathname}`)
  }

  const { data: ordersData, refetch, isLoading, isFetching } = useIndexQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
    email: userEmail,
    search: thisSearch,
    state: thisState,
    date_range: thisDateRange,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.entities?.undefined?.results;
  const pagination = ordersData?.entities?.undefined?.pagination;


  const [updateshipment, updateresult] = useUpdateMutation()

  const [barcode, setBarcode] = useState("");
  const handleInputChange = (event) => {
    setBarcode(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      updateshipment({
        userId: userId,
        userToken: userToken,
        admin: admin,
        userEmail: userEmail,
        awb_number: barcode,
        id: barcode
      })
      setBarcode("");
    }
  };

  function resetBoxes() {
    dispatch(addScanValue(0));
  }


  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {
    if (updateresult?.error) {
      setBarcode('');
      api['error']({
        message: updateresult?.error?.data?.error,
        showProgress: true,
        pauseOnHover: false,
        placement: 'top',
        duration: 3
      });
    }
    if (updateresult?.isSuccess) {
      setBarcode('');
      if (updateresult?.data?.scan_value) {
        dispatch(addScanValue(Number(scanValue) + Number(updateresult?.data?.scan_value)));
      }
      api['success']({
        message: updateresult?.data?.success,
        showProgress: true,
        pauseOnHover: false,
        placement: 'top',
        duration: 3
      });
    }

  }, [updateresult]);


  function getLoadingProgress(thisLoading, thisFetching, loading2) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else if (loading2) {
      return true
    } else {
      return false
    }
  }

  const findDuplicates = (arr) => {
    const counts = {};
    arr?.forEach((i) => {
      counts[i.awb_number] = (counts[i.awb_number] || 0) + 1;
    });
    return Object.keys(counts).filter((key) => counts[key] > 1);
  };

  const duplicateAWBs = findDuplicates(orders);

  const ComponentToPrintWrapper = ({ item, duplicateAWBs }) => {
    return (
      <tr style={{ position: 'relative' }}>
        <td
          className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`}
        >
          <Link
            style={{ color: '#5c5e5f' }}
            to={`/order/${item.order?.order_number}`}>
            {item.awb_number}
          </Link>
        </td>

        <td
          className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`}
        >
          <Link
            style={{ color: '#5c5e5f' }}
            to={`/order/${item.order.order_number}`}>
            {item.order.order_number}
          </Link>
        </td>

        <td
          className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`}
          style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={`${item.image}`} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
          </div>
        </td>

        <td className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`} >
          {item.m_departed === 1 ?
            <span className='badge badge-success'>Departed</span> :
            <span className='badge badge-warning'>Pending</span>
          }
        </td>

        <td className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`}>{item.order.country} </td>
        <td className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`}> {moment(item?.manifest_date).format('lll')} </td>

        <td className={`alert ${duplicateAWBs.includes(item.awb_number) ? "alert-info" : "alert-light"}`}>
          <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.order?.status && 'tomato' }}>
            <Dropdown>
              <MenuButton
                variant='outlined'
                size='sm'
                style={{ borderRadius: '4px', minHeight: '18px', maxHeight: '18px', padding: '0px', marginRight: '5px' }}
                className="Header-button-connect">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropDownIcon style={{ fontSize: '1.2rem' }} />
                </div>
              </MenuButton>
              <Menu>
                {item.m_departed === 1 &&
                  <MenuItem
                    className='orders-sub-side-menu'
                    onClick={() => {
                      if (window.confirm('Are you sure to update?'))
                        updateshipment({
                          userId: userId,
                          userToken: userToken,
                          admin: admin,
                          userEmail: userEmail,
                          state: 'remove_departed',
                          id: item.id
                        })
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                      <div style={{ textAlign: 'left' }}>{'Remove From Departed'}</div>
                    </div>
                  </MenuItem>
                }
                {item.m_departed === 0 &&
                  <MenuItem
                    className='orders-sub-side-menu'
                    onClick={() => {
                      if (window.confirm('Are you sure to update?'))
                        updateshipment({
                          userId: userId,
                          userToken: userToken,
                          admin: admin,
                          userEmail: userEmail,
                          state: 'add_departed',
                          id: item.id
                        })
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                      <div style={{ textAlign: 'left' }}>{'Mark as Departed'}</div>
                    </div>
                  </MenuItem>
                }

                <MenuItem
                  className='orders-sub-side-menu'
                  onClick={() => {
                    if (window.confirm('Are you sure to reset?'))
                      updateshipment({
                        userId: userId,
                        userToken: userToken,
                        admin: admin,
                        userEmail: userEmail,
                        state: 'remove_shipment',
                        id: item.id
                      })
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                    <div style={{ textAlign: 'left' }}>{'Remove From Shipment'}</div>
                  </div>
                </MenuItem>
              </Menu>
            </Dropdown>
          </div>

        </td>
      </tr>
    );
  };

  return (
    <>
      {contextHolder}

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <OrdersInfo pendingCounts={pagination?.totalCounts} />
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton" style={{ background: '#fff0', border: 'none' }}>
              <div className="row" style={{ margin: 0, padding: 0, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="col-md-6">
                  <span
                    className='alert alert-info'
                    style={{ background: '#fff0', border: 'none', padding: 0, margin: 0, textTransform: 'capitalize' , fontWeight: 500, fontSize: '1.1rem'}}>
                    {state || 'Pending'} Shipments
                  </span>
                </div>
                <div className="col-md-4">
                  <div className='form-group' style={{ position: 'relative', margin: 0, display: 'flex', alignItems: 'center', border: '1px solid #b9d9ff', borderRadius: '7px', padding: '5px', background: '#fafafa' }}>
                    <input
                      type="text"
                      value={barcode}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      autoFocus
                      style={{ background: '#fafafa', height: '40px', borderRadius: 0, border: 'none' }}
                      className='form-control'
                      placeholder='Scan barcode' />
                    {updateresult?.isLoading && <Loader />}
                    <QrCodeScannerOutlinedIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div className="row" style={{ margin: 0, padding: 0, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="col-md-6" style={{ padding: 0 }}>
                  <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ position: 'relative' }}>
                        <form onSubmit={handleSubmit2(searchOrder)} className="table_search_card">
                          <input
                            defaultValue={search}
                            type="text"
                            className="form-control table_search"
                            placeholder="Search for item..."
                            id="orderId"
                            aria-invalid={errors2.orderId ? "true" : "false"}
                            {...register2("orderId", { required: true })}
                          />
                          {!search &&
                            <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                              <SearchIcon style={{ fontSize: 'inherit' }} />
                            </IconButton>
                          }
                          {search &&
                            <IconButton
                              size='sm'
                              style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                              className="table_search_icon"
                              onClick={(() => clearSearch())}
                            >
                              <CloseIcon style={{ fontSize: 'inherit' }} />
                            </IconButton>}
                        </form>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DatePicker className='sp-daterange-select' onChange={onRangeChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0, borderTop: '1px solid #eee' }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div style={{ marginRight: '10px' }}>Showing {pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    disabled={isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}${thisSearch}${thisState}${thisDateRange}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12 list-card-op" style={{ position: 'relative', padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(isLoading, isFetching, updateresult?.isLoading) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table" style={{ margin: 0 }}>
              <thead>
                <tr style={{ position: 'relative' }}>
                  <th>AWB Number</th>
                  <th>Order Number</th>
                  <th>Image</th>
                  <th>Status</th>
                  <th>Shipping Country</th>
                  <th>Created At</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                    Manage
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders?.map(function (item, index) {
                  return <ComponentToPrintWrapper key={index} item={item} duplicateAWBs={duplicateAWBs} />;
                })}
              </tbody>
            </table>
          </div>
          {orders?.length === 0 &&
            <h5 className="data_not-found">No data available in table</h5>
          }
        </div>
      </div>
    </>
  );
}

export default DelayedOrders;
