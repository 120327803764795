import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


import { createEntityAdapter } from '@reduxjs/toolkit'
import io from "socket.io-client";
const messagesAdapter = createEntityAdapter()
const access = "full_access"


export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Media'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getAllMedia: builder.query({
      query: (data) => ({
        url: `media/?page=${data.page}&size=${data.size}${data.search ? `&search=${data.search}` : ''}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Media'],
    }),

    updateMedia: builder.mutation({
      query: (data) => {
        let formData;
        if (data.itemId) {
          formData = new FormData();
          formData.append('myFile', data.acceptedFiles[0]);
          formData.append('itemId', data.itemId); // Dynamic item ID
        } else {

          formData = new FormData();
          Object.values(data.image_file).forEach(file => {
            formData.append("myFile", file);
          });
        }
        return {
          url: `media`,
          method: 'POST',
          body: formData,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Media'],
    }),

    deleteMedia: builder.mutation({
      query: (data) => {
        return {
          url: `media/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Media'],
    }),



    createOrdersCSV: builder.mutation({
      query: (data) => ({
        url: `generate/csv/orders`,
        method: 'POST',
        body: data,
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Media'],
    }),

    getOrdersCSV: builder.query({
      query: (data) => ({
        url: `generate/csv/orders`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),

      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
            shipping_status: arg.shipping_status
          }
          const listener = (event) => {
            // if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return
            const values = event.email?.split(',');
            const localDomain = localStorage.getItem('domain')
            const adminDomain = process.env.REACT_APP_API_DOMAIN

            // if (event.adminAccess && !JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
            //   if (values?.includes(arg.userEmail)) {
            //     updateCachedData((draft) => {
            //       messagesAdapter.upsertOne(draft, event?.domain === localDomain ? event.data : '')
            //     })
            //   }
            // }
            if (!event.adminAccess && JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, event?.data)
              })
            }

          }
          socket.addEventListener('orderscsvgenerating', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },

      // keepUnusedDataFor: 1,
      providesTags: ['Media'],
    }),











    createAllOrdersCSV: builder.mutation({
      query: (data) => ({
        url: `generate/csv/all/orders`,
        method: 'POST',
        body: data,
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Media'],
    }),

    getAllOrdersCSV: builder.query({
      query: (data) => ({
        url: `generate/csv/all/orders`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),

      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
            shipping_status: arg.shipping_status
          }
          const listener = (event) => {
            // if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return
            const values = event.email?.split(',');
            const localDomain = localStorage.getItem('domain')
            const adminDomain = process.env.REACT_APP_API_DOMAIN

            // if (event.adminAccess && !JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
            //   if (values?.includes(arg.userEmail)) {
            //     updateCachedData((draft) => {
            //       messagesAdapter.upsertOne(draft, event?.domain === localDomain ? event.data : '')
            //     })
            //   }
            // }
            if (!event.adminAccess && JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, event?.data)
              })
            }

          }
          socket.addEventListener('orderscsvgeneratingall', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },

      // keepUnusedDataFor: 1,
      providesTags: ['Media'],
    }),



  }),

})

export const { useGetAllMediaQuery, useUpdateMediaMutation, useDeleteMediaMutation, useGetOrdersCSVQuery, useCreateOrdersCSVMutation, useGetAllOrdersCSVQuery, useCreateAllOrdersCSVMutation } = mediaApi