import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useShowQuery, useCreateRefundMutation, useFetchRefundQuery } from '../../services/refund';
import { useSelector } from "react-redux";
import Button from '@mui/joy/Button';
import LinearProgress from '@mui/material/LinearProgress';
import $ from 'jquery';

function Tap({ orderInfo }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const { id } = useParams();
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const [getRefundAmount, setRefundAmount] = useState()
  const [getRefundReason, setRefundReason] = useState("")
  const [getRefundError, setRefundError] = useState("")

  const orderData = orderInfo?.data?.order

  const refundInfo = useFetchRefundQuery({ id: orderData?.piid, type: 'tap', userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const refundData = refundInfo?.data?.refund?.refunds

  const [createRefund, refundResult] = useCreateRefundMutation()

  function refundRequest() {
    if (Number(getRefundAmount) > Number(orderData?.amount + Number(orderData?.shipping_price))) {
      setRefundError('Refund amount must be less then or equel to the order amount')
    } else if (Number(getRefundAmount) === 0 || !getRefundAmount) {
      setRefundError('Refund amount is not specified')
    } else {
      createRefund({
        piid: orderData?.piid,
        type: 'tap',
        refund: true,
        userId: userId,
        id: orderData?.id,
        currency: orderData?.currency,
        order_number: orderData?.order_number,
        userToken: userToken,
        refund_amount: getRefundAmount,
        refund_reason: getRefundReason
      })
    }
  }

  useEffect(() => {
    if (refundResult?.isSuccess) {
      setRefundAmount()
      setRefundReason('')
    }
    if (refundResult?.error) {
      setRefundError(refundResult?.error?.data?.error)
    }
  }, [refundResult]);

  function isCouponDiscount() {
    if (orderData?.coupon?.coupon_code) {
      return Number(orderData?.amount) + Number(orderData?.coupon?.discount)
    } else {
      return Number(orderData?.amount)
    }
  }

  function getLinearProgress() {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
        <LinearProgress style={{ height: '2px' }} />
      </div>
    )
  }

  const closeAlert = () => {
    setRefundError('')
    $("#myAlert").alert('close');

  };

  const closeAlertSuccess = () => {
    $("#myAlertSuccess").alert('close');

  };

  return (
    <>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-6 list-card-op" style={{ position: 'relative' }}>
          {!orderInfo?.isLoading &&
            <div className="tabelOverflow details-card" style={{ position: 'relative', overflow: 'hidden', whiteSpace: 'normal', border: '1px solid #eee' }}>
              {refundResult?.isLoading &&
                getLinearProgress()
              }

              <div className="row">
                <div className="col-md-12">
                  {refundInfo?.error?.originalStatus === 403 &&
                    <div className='badge badge-danger' style={{ background: '#fff0', border: 'none' }}>{refundInfo?.error?.data}</div>
                  }
                </div>
              </div>
              <br />
              {getRefundError &&
                <div className="row">
                  <div className="col-md-12">
                    <div id="myAlert" className='alert alert-danger alert-dismissible fade show' role="alert" style={{ textAlign: 'left' }}>
                      {getRefundError && getRefundError}

                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeAlert} >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              }

              {refundResult?.isSuccess &&
                <>
                  {refundResult?.data?.success &&
                    <div className="row">
                      <div className="col-md-12">
                        <div id="myAlertSuccess" className='alert alert-success alert-dismissible fade show' role="alert" style={{ textAlign: 'left' }}>
                          {refundResult?.data?.success}
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeAlertSuccess} >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                </>
              }

              <div className="row">
                <div className="col-md-12">
                  <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                    <div className='form-group'>
                      <label>Refund Amount</label>
                      <input className='form-control' required onChange={((e) => setRefundAmount(e.target.value))} style={{ height: '45px', background: '##f7f7f7' }} />
                    </div>
                    <div className='form-group'>
                      <label>Refund Reason</label>
                      <textarea style={{ background: '##f7f7f7' }} className='form-control' onChange={((e) => setRefundReason(e.target.value))}></textarea>
                    </div>
                    <div className='form-group'>
                      <Button
                        disabled={refundResult?.isLoading}
                        onClick={(() => refundRequest())}
                        style={{ width: '100%', fontWeight: 500 }}
                        variant="soft"
                        size="lg"
                      >
                        {refundResult?.isLoading ? 'updating' : 'Refund'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className='op-info-text'>
                  <span style={{ fontWeight: 500 }}>
                    Total Amount:&nbsp;
                  </span>
                  <span style={{ fontWeight: 500 }}>
                    {orderData?.currency} {Number(orderData?.amount) + Number(orderData?.shipping_price)}
                  </span>
                </div>
              </>
              <br />
            </div>
          }
        </div>

        <div className="col-md-6 list-card-op" style={{ position: 'relative' }}>
          <div className='details-card' style={{ position: 'relative', padding: '0px', border: 'none' }}>
            <div className="tabelOverflow details-card" style={{ position: 'relative', overflow: 'hidden', whiteSpace: 'normal', border: '1px solid #eee' }}>
              {refundInfo?.isLoading &&
                getLinearProgress()
              }
              {!refundInfo?.isLoading &&
                <>
                  {refundData ?
                    <>
                      {refundData?.map((item, i) =>
                        <div key={i} style={{ background: '#fcfcfc', borderBottom: '2px solid #adadad', paddingBottom: '10px', marginBottom: '10px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Reason: &nbsp;</div>
                            <div>{item?.reason}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Status: &nbsp;</div>
                            <div>{item?.status}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Amount: &nbsp;</div>
                            <div>{item?.currency} {item?.amount}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>ID: &nbsp;</div>
                            <div>{item?.id}</div>
                          </div>
                        </div>
                      )}
                    </> :
                    <div className='alert alert-info' style={{ textAlign: 'center', background: '#fff0', padding: 0, border: 'none' }}>No refund initiated</div>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />


    </>
  );
}

export default Tap;
