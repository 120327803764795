import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import { useFetchQuery, useUpdateMutation } from '../../services/consolidation';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/joy/Button';
import ReactToPrint from "react-to-print";
import { useDispatch } from "react-redux";
import { addScanValue } from "../../reducers/authReducer";
import { notification, Space } from 'antd';
import bwipjs from "bwip-js";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import "antd/dist/antd.min.css";
function DelayedOrders({ id }) {
  const { userId, userToken, userEmail, userName, status, admin, scanValue } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const history = useNavigate();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 48
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''
  const state = new URLSearchParams(location.search).get('state') || null
  const thisState = state ? `&state=${state}` : ''
  const date_range = new URLSearchParams(location.search).get('date_range') || null
  const thisDateRange = date_range ? `&date_range=${date_range}` : ''


  const { data: ordersData, refetch, isLoading, isFetching } = useFetchQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
    email: userEmail,
    id: id
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.results;
  const pagination = ordersData?.pagination;

  const [updateshipment, updateresult] = useUpdateMutation()
  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {
    if (updateresult?.error) {
      api['error']({
        message: updateresult?.error?.data?.error,
        showProgress: true,
        pauseOnHover: false,
        placement: 'top',
        duration: 3
      });
    }
    if (updateresult?.isSuccess) {
      dispatch(addScanValue(Number(scanValue) + Number(updateresult?.data?.scan_value)));
      api['success']({
        message: updateresult?.data?.success,
        showProgress: true,
        pauseOnHover: false,
        placement: 'top',
        duration: 3
      });
    }

  }, [updateresult]);


  function setIsLoadind(loading, fetching) {
    if (loading) {
      return true
    } else if (fetching) {
      return true
    } else {
      return false
    }
  }


  const ComponentToPrintWrapper = ({ item }) => {
    const componentRef = React.useRef();

    const ComponentToPrint = React.forwardRef(({ value, orderId, title }, ref) => {
      const canvasRef = React.useRef();
      React.useEffect(() => {
        if (canvasRef.current) {
          try {
            bwipjs.toCanvas(canvasRef.current, {
              bcid: "code128",
              text: `${value}`,
              scale: 3,
              height: 8,
              includetext: true,
              textxalign: "center",
              textsize: 6,
              includetext: false
            });
          } catch (err) {
            console.error("Barcode generation failed:", err);
          }
        }
      }, [value]);
      return (
        <div className='print-only'>
          <div ref={ref} className="react-barcode" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '12px' }}>{orderId} - {value}</div>
              <canvas ref={canvasRef}></canvas>
              <div className='react-barcode-title'>{title?.substring(0, 34)?.replace(/-/g, ' ')}...</div>
            </div>
          </div>
        </div>
      );
    });


    function functionIdLink(id, pid, store_id) {
      if (Number(store_id) === 3) {
        return pid
      } else {
        return id
      }
    }

    return (
      <div className="col-md-3 list-card-op" style={{ position: 'relative', padding: '10px' }}>
        <ComponentToPrint ref={componentRef} value={item.id} orderId={item?.id} title={item.title} />
        <div style={{ padding: '15px', border: '1px solid #ddd', borderRadius: '10px', position: 'relative' }}>

          {updateresult?.isLoading && updateresult?.originalArgs?.id === item.id &&
            <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
              <LinearProgress style={{ height: '2px' }} />
            </div>
          }
          <a
            href={`https://www.thwifty.ae/product/${item.store_id}/${functionIdLink(item.product_id, item.pid, item.store_id)}`}
            target='_blank'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={`${item.image}`} style={{ width: '180px', height: '180px', objectFit: 'contain' }} />
            </div>
            <div className='con-title' style={{ fontSize: '0.8rem' }}>{item.title}</div>
          </a>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              className='pg-button-hp'
              disabled={updateresult?.isLoading}
              onClick={(() => updateshipment({
                userId: userId,
                userToken: userToken,
                admin: admin,
                state: 'good',
                id: item.id
              }))}
              variant='soft'
              size='sm'
              color={item.status === 1 ? `success` : 'primary'}
              style={{ fontWeight: 500, minHeight: '27px', maxHeight: '27px', marginRight: '10px' }}>
              <small>OK</small>
            </Button>
            <Button
              className='pg-button-hp'
              disabled={updateresult?.isLoading}
              onClick={(() => updateshipment({
                userId: userId,
                userToken: userToken,
                admin: admin,
                state: 'damaged',
                id: item.id
              }))}
              variant='soft'
              size='sm'
              color={item.status === 2 ? `danger` : 'primary'}
              style={{ fontWeight: 500, minHeight: '27px', maxHeight: '27px', marginRight: '10px' }}>
              <small>Damaged</small>
            </Button>
            <Button
              className='pg-button-hp'
              disabled={updateresult?.isLoading}
              onClick={(() => updateshipment({
                userId: userId,
                userToken: userToken,
                admin: admin,
                state: 'wrong',
                id: item.id
              }))}
              variant='soft'
              size='sm'
              color={item.status === 3 ? `danger` : 'primary'}
              style={{ fontWeight: 500, minHeight: '27px', maxHeight: '27px', marginRight: '10px' }}>
              <small>Wrong</small>
            </Button>
          </div>
          <span style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <ReactToPrint
              trigger={() =>
                <IconButton variant="outlined" size='sm' style={{ minHeight: '25px', margin: item.inventory ? '0px 10px' : 0, }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                    <path d="M2 2h2v2H2z" />
                    <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                    <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                    <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                    <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
                  </svg>
                </IconButton>
              }
              content={() => componentRef.current}
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {contextHolder}

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12 list-card-op" style={{ position: 'relative' }}>
          <div style={{ position: 'relative' }}>
            {setIsLoadind(isLoading, isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <div className="newFlexPagi">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div style={{ marginRight: '10px' }}>Showing {pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    disabled={isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}&id=${id}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {orders?.length == 0 &&
              <h5 className="data_not-found">No data available in table</h5>
            }
          </div>
        </div>

        {orders?.map(function (item, index) {
          return <ComponentToPrintWrapper key={index} item={item} />;
        })}

      </div>
    </>
  );
}

export default DelayedOrders;
