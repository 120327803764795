import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import { useDispatch } from "react-redux";
import "antd/dist/antd.min.css";
import DelayedOrders from './DelayedOrders';
import LinearProgress from '@mui/material/LinearProgress';

function DistributionComponent() {
  const { userId, userToken, userEmail, userName, status, admin, scanValue } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const navigate = useNavigate();
  // const { id } = useParams();
  const id = new URLSearchParams(location.search).get('id') || null
  const [barcode, setBarcode] = useState("");
  const handleInputChange = (event) => {
    setBarcode(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`${window.location.pathname}?id=${barcode}`)
      setBarcode("");
    }
  };
 
 
  return (
    <>
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton" style={{ background: '#fff0', border: 'none', position: 'relative' }}>
         
              <div className="row" style={{ margin: 0, padding: 0, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="col-md-6" style={{ padding: 0 }}>

                </div>
                <div className="col-md-4" style={{ padding: 0 }}>
                  <div className='form-group' style={{ position: 'relative', margin: 0, display: 'flex', alignItems: 'center', border: '1px solid #b9d9ff', borderRadius: '7px', padding: '5px', background: '#fafafa' }}>
                    <input
                      type="text"
                      value={barcode}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      autoFocus
                      style={{ background: '#fafafa', height: '40px', borderRadius: 0, border: 'none' }}
                      className='form-control'
                      placeholder='Scan barcode or search item' />
                    <QrCodeScannerOutlinedIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {id &&
        <DelayedOrders id={id} />
      }

    </>
  );
}

export default DistributionComponent;
