import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import Loader from '../../Helper/Loader';

function ManifestDetails({ getManifestModal, closeManifestModal, getmManifestDetails, viewmanifest, userId, userToken, viewmanifestresult }) {

  return (
    <>
      <Modal className="manifestDeatils-modal" show={getManifestModal} onHide={closeManifestModal} centered animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px', zIndex: 111 }}
            onClick={closeManifestModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <IconButton
                style={{ cursor: 'pointer', position: 'absolute', left: '0px', top: '0px', zIndex: 111 }}
                onClick={(() => viewmanifest({
                  awb_number: getmManifestDetails?.data?.awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true,
                  pdf: 'pdf46'
                }))}
              >
                {viewmanifestresult?.isLoading ? <Loader /> :
                  <PrintIcon />
                }
              </IconButton>
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Manifest Details</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                <div className='form-group'>
                  {getmManifestDetails?.error &&
                    <div className='alert alert-danger'>{getmManifestDetails?.error} </div>
                  }
                  {getmManifestDetails?.data &&
                    <div>
                      {Array.isArray(getmManifestDetails?.data) ?
                        <>
                          {getmManifestDetails?.data?.map((item, index) => (
                            <div key={index}>
                              <>
                                {Object.keys(item).map((key) => (
                                  <div key={key} style={{
                                    wordBreak: 'break-all', display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #eee',
                                    paddingBottom: '10px',
                                    marginBottom: '10px', flexWrap: 'wrap'
                                  }}>
                                    <strong style={{ textTransform: 'capitalize' }}>{key?.replace(/_/g, ' ')}:</strong> {item[key]}
                                  </div>
                                ))}
                              </>
                            </div>
                          ))}
                        </>
                        :
                        <>
                          {Object.keys(getmManifestDetails?.data).map((key) => (
                            <div style={{
                              wordBreak: 'break-all', display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              borderBottom: '1px solid #eee',
                              paddingBottom: '10px',
                              marginBottom: '10px', flexWrap: 'wrap'
                            }} key={key}>
                              <strong style={{ textTransform: 'capitalize' }}>{key?.replace(/_/g, ' ')}:</strong> {getmManifestDetails?.data[key]}
                            </div>
                          ))}
                        </>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default ManifestDetails;
