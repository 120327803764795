import React, { useState, useEffect, Fragment } from 'react';
import Divider from '@mui/material/Divider';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ViewManifest({ item, viewmanifest, userId, userToken, orderData }) {

  return (
    <>
      {item?.awb_number &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown>
            <MenuButton
              size='sm'
              className='m-margin-0'
              style={{ minHeight: '25px', maxHeight: '25px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small>Track</small>
                <ArrowDropDownIcon style={{ fontSize: '1.3rem' }} />
              </div>
            </MenuButton>
            <Menu className='listUpdate-card'>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: item?.awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true
                }))}
              >
                <span>View Manifest</span>
              </MenuItem>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: item?.awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  track_shipment: true
                }))}
              >
                <span>Track Shipment</span>
              </MenuItem>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: item?.awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true,
                  pdf: 'pdf'
                }))}
              >
                <span>Print pdf</span>
              </MenuItem>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: item?.awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true,
                  pdf: 'pdf46'
                }))}
              >
                <span>Print pdf_4x6</span>
              </MenuItem>

              <Divider />
              {item?.package_weight &&
                <MenuItem disabled>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>Package Weight</div>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>{Number(item?.package_weight) / 1000}kg</div>
                </MenuItem>
              }
              {item?.customs_value &&
                <MenuItem disabled>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>Package Customs Value</div>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>{orderData?.currency} {item?.customs_value}</div>
                </MenuItem>
              }

            </Menu>
          </Dropdown>
          <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
            <div style={{ fontSize: '13px' }}>
              <span style={{ fontWeight: 600 }}>AWB:</span> &nbsp;{item?.awb_number}
            </div>
          </div>
        </div>
      }
    </>
  )

}

export default ViewManifest;
