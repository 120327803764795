import React from 'react';
import OrdersInfo from '../../components/Dashboard/OrdersInfo';
import DelayedOrders from '../../components/Dashboard/DelayedOrders';
import YearChart from '../../components/Dashboard/YearChart';


function Dashboard() {

  // function playSound() {
  //   const audio = new Audio("/notification-cound.wav");
  //   audio.play();
  // }

  return (
    <div className="tabel_cards">
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <OrdersInfo />
      </div>
      <br />
      <YearChart />
      <br />
      <DelayedOrders />
    </div>

  );
}

export default Dashboard;
