import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import "antd/dist/antd.min.css";
import { useImportFileOrdersMutation, useFetchActionOrdersCashedQuery } from '../../services/action';
import { useSelector } from "react-redux";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Button from '@mui/joy/Button';
import Loader from '../../Helper/Loader';


function OrdersAction() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { action, merchant_id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const storeProductsInfo = useFetchActionOrdersCashedQuery({ id: merchant_id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const cachedData = storeProductsInfo?.data?.entities?.undefined?.results;


  const [updatefile, updatefileresult] = useImportFileOrdersMutation()
  const [getUpdateImage, setUpdateImage] = useState();
  function updateBookImage() {
    updatefile({
      image_file: getUpdateImage,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      merchant_id: merchant_id,
    })
  }


  const navigate = useNavigate();
  const handleBack = () => { navigate(-1) };


  function buttonDisabled(loading, file) {
    if (loading) {
      return true
    } else if (!file) {
      return true
    } else {
      return false
    }
  }

  function geyStatus(status) {
    if (status === 'completed') {
      return 'badge badge-success'
    } else if (status === 'in_progress') {
      return 'badge badge-warning'
    } else {
      return 'badge badge-danger'
    }
  }

  function buttonDisabled2(loading, status) {
    if (loading) {
      return true
    } else if (status === 'in_progress') {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className="tabel_cards">

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'auto',
                    width: '100%',
                    whiteSpace: 'nowrap'
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button size='sm' variant="soft" onClick={handleBack}><ArrowBackOutlinedIcon /></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>

            <div className="row" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-5" style={{ padding: 0 }}>
                <div style={{ background: '#fff', padding: '20px', border: '1px solid #eee' }}>
                  <div className="row">

                    {updatefileresult?.error?.data?.error &&
                      <div className='alert alert-danger' style={{ textAlign: 'center', width: '100%', background: '#fff0', border: 'none', padding: 0 }}>
                        {updatefileresult?.error?.data?.error}
                      </div>
                    }


                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          style={{ height: '100%', padding: '15px', background: '#f9f9f9' }}
                          multiple type="file"
                          className="form-control"
                          onChange={(e) => setUpdateImage(e.target.files[0])} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group" style={{ textAlign: 'center' }}>
                        <Button
                          disabled={buttonDisabled2(updatefileresult?.isLoading, cachedData?.status)}
                          style={{ fontWeight: 400 }}
                          variant="soft"
                          size="lg"
                          onClick={updateBookImage}>
                          {buttonDisabled2(updatefileresult?.isLoading, cachedData?.status) ? "Generating" : 'Generate New CSV File'}
                        </Button>
                       
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                          <div style={{ textTransform: 'capitalize' }} className={geyStatus(cachedData?.status)}>{cachedData?.status?.replace('_', ' ')}</div>
                          {buttonDisabled2(updatefileresult?.isLoading, cachedData?.status) &&
                            <div style={{ margin: '0px 10px' }}><Loader /></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </>
  );
}

export default OrdersAction;
