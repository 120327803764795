import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import { useOrdersCountQuery, useOrdersSuccessCountQuery, useOrdersFailedCountQuery, useIndexCountDeliveredQuery, useIndexCountPendingQuery } from '../../services/index';
import Loader from '../../Helper/Loader'
import Button from '@mui/joy/Button';
function OrdersInfo() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const userData = {
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
  }


  const { data: ordersCountData } = useOrdersCountQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })

  const { data: successCountData, isLoading } = useOrdersSuccessCountQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })

  const { data: failedCountData } = useOrdersFailedCountQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })

  const { data: deliveredCount } = useIndexCountDeliveredQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })

  const { data: pendingCount } = useIndexCountPendingQuery(userData, { count: 1 }, { refetchOnMountOrArgChange: true })




  return (
    <>
      <div className="col-md-6 list-card-op" style={{ position: 'relative' }}>
        <div className="db-section-card">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '1.1rem', fontWeight: 400, }}>All Orders</span>
            <span className='db-card-count' style={{ fontSize: '1.1rem' }}>
              {isLoading ?
                <Loader /> :
                successCountData + failedCountData
              }
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', marginTop: '15px' }}>
            <Link
              className='b-hover-bn'
              to={`/orders`}
              style={{ display: 'flex', alignItems: 'center', color: '#3c4043', fontSize: '0.9rem' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>Success:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center' }} className='badge badge-success'>{successCountData}</small>
              }
            </Link>
            <Link
              className='b-hover-bn'
              to={`/orders?payment_status=false`}
              style={{ display: 'flex', alignItems: 'center', color: '#3c4043', fontSize: '0.9rem' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>Failed:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center' }} className='badge badge-danger'>{failedCountData}</small>
              }
            </Link>
          </div>
        </div>
      </div>



      <div className="col-md-6 list-card-op" style={{ position: 'relative' }}>
        <div className="db-section-card">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '1.1rem', fontWeight: 400, }}>Delivered Items</span>
            <span className='db-card-count' style={{ fontSize: '1.1rem' }}>
              {isLoading ?
                <Loader /> :
                deliveredCount
              }
            </span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', marginTop: '15px' }}>
            <Link
              className='b-hover-bn'
              to={`/orders?shipping_status=pending`}
              style={{ display: 'flex', alignItems: 'center', color: '#3c4043', fontSize: '0.9rem' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>Pending:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center' }} className='badge badge-warning'>{pendingCount}</small>
              }
            </Link>
            <Link
              className='b-hover-bn'
              to={`/orders?shipping_status=cancelled`}
              style={{ display: 'flex', alignItems: 'center', color: '#3c4043', fontSize: '0.9rem' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>Cancelled<span className='mobile-hide'>/Refunded</span>:&nbsp;</span>
              {!isLoading &&
                <small style={{ display: 'flex', alignItems: 'center' }} className='badge badge-danger'>{ordersCountData}</small>
              }
            </Link>
          </div>

        </div>
      </div>



    </>
  );
}

export default OrdersInfo;
