import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/joy/Button';
import { useForm } from "react-hook-form";
import { useUpdateOrderMessageMutation } from '../../services/messages';
var moment = require('moment');

function UpdateMessage({ messages, orderData, userId, userToken, userEmail }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getMessage, setMessage] = useState('');
  const [createmessage, messageresult] = useUpdateOrderMessageMutation()

  function submitMessage(data) {
    createmessage({
      id: orderData?.id,
      order_number: orderData?.order_number,
      message: data.message,
      userId: userId,
      userToken: userToken,
      user_email: userEmail
    })
  }

  useEffect(() => {
    if (messageresult?.isSuccess) {
      reset()
    }
  }, [messageresult]);

  function getMessageCard(type) {
    if (type === 'item_cancel') {
      return 'alert-danger'
    } else if (type === 'order_cancel') {
      return 'alert-danger'
    } else if (type === 'update') {
      return 'alert-warning'
    } else if (type === 'require_attention') {
      return 'alert-dark'
    } else {
      return 'alert-info'
    }
  }

  function getMessageCardType(type) {
    if (type === 'item_cancel') {
      return 'badge-danger'
    } else if (type === 'order_cancel') {
      return 'badge-danger'
    } else if (type === 'update') {
      return 'badge-warning'
    } else if (type === 'require_attention') {
      return 'badge-dark'
    } else {
      return 'badge-info'
    }
  }

  return (
    <>
      <br />
      <div
        className='messages-scroll'
        style={{
          maxHeight: '300px',
          minHeight: 0,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}>
        {messages?.map((item, i) =>
          <div key={i}
            className={`alert ${getMessageCard(item.type)}`}
            style={{ fontSize: '13px', marginBottom: '3px', padding: '4px 0.3rem', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <small style={{ fontWeight: 600 }}>{``}{item.user_email}&nbsp;</small>
              {item.type &&
                <small className={`badge ${getMessageCardType(item.type)}`}>{``}{item.type?.replace(/_/g, ' ')}&nbsp;</small>
              }
            </div>
            {/* <div style={{ whiteSpace: 'pre-wrap' }}>{item.message}</div> */}
            <div
              style={{ lineHeight: 1.1, paddingTop: '3px' }}
              dangerouslySetInnerHTML={{ __html: item.message?.replace(/\n/g, '<br>') }} />
            <div style={{ lineHeight: 1, textAlign: 'right' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'right', fontSize: '12px', padding: '4px 0px 2px 0px' }}>
                <small style={{ fontWeight: 600 }}>{moment(item?.createdAt).format('lll')}</small>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit(submitMessage)}>
            <div className='form-group' style={{ display: 'flex', margin: 0 }}>
              <textarea
                style={{ height: '46px' }}
                className='form-control'
                id="message"
                aria-invalid={errors.message ? "true" : "false"}
                {...register('message', { required: true })}
              >
              </textarea>

              <Button
                type='submit'
                disabled={messageresult?.isLoading}
                style={{ textTransform: 'capitalize', fontWeight: 400, margin: '0px 5px' }}
                variant="soft"
                size="sm"
              >
                {messageresult?.isLoading ? 'updating...' : 'Send'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )

}

export default UpdateMessage;
