import React from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useFetchProductsSearchQuery } from '../../services/products';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';

function ProductsSearch() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const fetchSearchProducts = useFetchProductsSearchQuery({
    page: page,
    size: limit,
    query: id,
    userId: userId,
    userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const products = fetchSearchProducts?.data?.products;
  const pagination = fetchSearchProducts?.data?.pagination;

  function shippingStatusC(data) {
    if (data === 'approved') {
      return 'badge badge-success'
    } else if (data === 'cancelled') {
      return 'badge badge-danger'
    } else if (data === 'fraud') {
      return 'badge badge-danger'
    } else if (data === 'require_attention') {
      return 'badge badge-dark'
    } else {
      return 'badge badge-warning'
    }
  }


  function borderColor(data) {
    let color
    if (data === 'approved') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }
  function borderColorSticky(data) {
    let color
    if (data === 'delivered') {
      color = '#f2f4f0'
    } else if (data === 'cancelled') {
      color = '#f8f4f3'
    } else {
      color = '#f7f5ee'
    }
    return color
  }

  function getLoadingProgress(loading1, loader2) {
    if (loading1) {
      return true
    } else if (loader2) {
      return true
    } else {
      return false
    }
  }

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  return (
    <div className="tabel_cards">


      <div className="row sticky-pg" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div>
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">
                    <Pagination
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
        <div style={{ padding: 0, width: '100%' }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(fetchSearchProducts?.isLoading, fetchSearchProducts?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table" style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th className="sticky-column tar" style={{ textAlign: 'right', width: '1px' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {products?.map((item, i) =>
                  <tr key={i}>
                    <td>{functionIdLink(item.id, item.pid, item.store_id)}</td>
                    <td style={{ textAlign: 'center' }}>
                      {item.images ?
                        <img src={`${item.images?.split(',')[0]}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} /> :
                        <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                      }
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => window.open(`https://www.thwifty.ae/product/${item.store_id}/${functionIdLink(item.id, item.pid, item.store_id)}`, '_blank').focus()}
                        >
                          <LanguageIcon style={{ fontSize: '1rem' }} />
                        </IconButton>

                        <Link to={`/product/${item.id}`} style={{ whiteSpace: 'initial', margin: '0px 5px' }}>
                          {item.title}
                        </Link>
                      </div>
                    </td>
                    <td>{item.currency} {item.price}</td>
                    <td>{item.country}</td>
                    <td className="sticky-column">
                      <div className="dFlex center nowrap jcr">
                        <span className={`badge ${item.status ? `badge-danger` : `badge-success`}`}>
                          {item.status ? 'inactive' : 'active'}
                        </span>
                        <span style={{ margin: '0px 10px' }} className={`badge ${item.in_stock ? `badge-success` : `badge-danger`}`}>
                          {item.in_stock ? 'in stock' : 'out of stock'}
                        </span>
                        <span className={`badge ${item.google_merchant ? `badge-success` : `badge-danger`}`}>
                          {'google merchant'}
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {products?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>No Data Found</div>
          }
        </div>
      </div>
    </div>
  );
}

export default ProductsSearch;
