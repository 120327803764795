import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useUpdateMutation, useShowQuery, useUpdateItemMutation, useFetchQuery, useCreateManifestMutation, useViewManifestMutation, useUpdateShippingStatusMutation, useShowRefundQuery, useDeleteInventoryMutation } from '../../services/orders';

import { useFetchOrderMessagesQuery } from '../../services/messages';
import { useSelector } from "react-redux";
import Button from '@mui/joy/Button';
import Loader from '../../Helper/Loader';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ItemList from './ItemList';
import ShippingAddress from './ShippingAddress';
import OrderCancel from './OrderCancel';
import ItemCancel from './ItemCancel';
import UpdateDelivery from './UpdateDelivery';
import UpdateMessage from './UpdateMessage';
import ItemWeight from './ItemWeight';
import ItemQuantity from './ItemQuantity';
import ExtraUpdate from './ExtraUpdate';
import ManifestDetails from './ManifestDetails';
import PageError from '../PageError';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SearchIcon from "@material-ui/icons/Search";
import Refund from './Refund';
import CreateManifest from './CreateManifest';
import Messages from './Messages';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

var moment = require('moment');
const orderStates = ['pending', 'approved', 'fraud']

function Order() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const { id } = useParams();
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [getSearchQuery, searchQuery] = useState("")
  useFetchQuery({ page: 1, size: 10, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const orderInfo = useShowQuery(
    { id: id, userId: userId, userToken: userToken, admin: admin },
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  )
  const orderData = orderInfo?.data?.order
  const [updateOrder, upResult] = useUpdateMutation()
  const [updateitem, uitemresult] = useUpdateItemMutation()

  // messages
  const { data: messagesInfo, refetch } = useFetchOrderMessagesQuery({
    id: id,
    userId: userId,
    userToken: userToken,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const messages = messagesInfo?.messages;





  const refundedInfo = useShowRefundQuery({
    id: id,
    userId: userId,
    userToken: userToken,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const refunded = refundedInfo?.data?.refunded;

  // this working
  const [itemShippingStatus, itemShippingStatusResult] = useUpdateShippingStatusMutation()
  const [viewmanifest, viewmanifestresult] = useViewManifestMutation()
  const [createManifest, manifetsresult] = useCreateManifestMutation()

  const [open, setOpen] = React.useState(false);
  const [openItem, setOpenItem] = React.useState(false);
  const [openItemId, setOpenItemId] = React.useState();

  function updateState(data) {
    updateOrder({
      id: orderData?.id,
      state: data,
      userId: userId,
      userToken: userToken
    })
  }

  function updateItemState(data) {
    updateitem({
      name: data?.name,
      email: data?.email,
      email_send: data?.email_send,
      domain: orderData?.domain,
      order_number: orderData?.order_number,
      orderId: data.orderId,
      itemId: data?.itemId,
      image: data?.image,
      state: data.state,
      comment: data.comment,
      delivery_days: data?.delivery_days,
      update_comment: data.update_comment,
      estimated_delivery: data?.estimated_delivery,
      item_shipping_status: data?.item_shipping_status,
      userId: userId,
      userToken: userToken,
      item_state: data.item_state,
      item_tracking: data.item_tracking,
      tracking_id: data.tracking_id,
    })
  }

  const [getEditShow, setEditShow] = useState(false);
  const [getOrderUpdateActionType, setOrderUpdateActionType] = useState('');
  function handleCloseEdit() {
    setEditShow(false)
  }
  function cancelOrder(type) {
    setEditShow(true)
    setOrderUpdateActionType(type)
  }

  const [getItemPopup, setItemPopup] = useState(false);
  const [getItemId, setItemId] = useState();
  const [getItemImage, setItemImage] = useState('');
  const [getNote, setNote] = useState('');
  function updateItemPopup(data) {
    setItemPopup(true)
    setItemId(data.itemId)
    setItemImage(data.image)
  }
  function cancelItemPopup() {
    setItemPopup(false)
  }

  const [getItemWeightPopup, setItemWeightPopup] = useState(false);
  const [getWeight, setWeight] = useState('');
  function itemWeightModal(data) {
    setItemWeightPopup(true)
    setItemId(data.itemId)
  }
  function cancelItemWeightModal() {
    setItemWeightPopup(false)
  }

  const [getItemQuantityPopup, setItemQuantityPopup] = useState(false);
  function itemQuantityModal(data) {
    setItemQuantityPopup(true)
    setItemId(data.itemId)
  }
  function cancelItemQuantityModal() {
    setItemQuantityPopup(false)
  }



  const [getExtraUpdateModal, setExtraUpdateModal] = useState(false);
  const [getItemData, setItemData] = useState('');
  function extraUpdateModal(data) {
    setExtraUpdateModal(true)
    setItemData(data)
  }
  function cancelExtraUpdateModal() {
    setExtraUpdateModal(false)
  }


  function orderStateButton(data) {
    const checkthis = orderData?.states?.split(',')?.filter((item, i) => item === data)
    if (checkthis?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function getDeliverydays(data, created) {
    if (Number(data) > 0) {
      return <>{moment(created).add(Number(data), 'days').format('ddd Do MMM YY')}</>
    } else {
      return moment(created).add(10, 'days').format('ddd Do MMM YY')
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  function borderColor(data) {
    let color
    if (data === 'approved') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else if (data === 'fraud') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }

  const groupData = orderData?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const [getUpdateDeliveryModal, setUpdateDeliveryModal] = useState(false);
  const [getUpdateDeliveryData, setUpdateDeliveryData] = useState([]);
  function updateDeliveryDays(data) {
    setUpdateDeliveryModal(true)
    setUpdateDeliveryData(data)
  }

  function cancelUpdateDeliveryModal() {
    setUpdateDeliveryModal(false)
  }

  function itemIds(items) {
    let orderItemIds = []
    items?.map((item) =>
      orderItemIds.push(item.id)
    )
    return orderItemIds
  }

  const [getManifestModal, setManifestModal] = useState(false);
  const [getmManifestDetails, setManifestDetails] = useState([]);
  function closeManifestModal() {
    setManifestModal(false)
  }

  useEffect(() => {
    const orderStatesUpdate = ['require_attention', 'cancelled']
    const itemStatesUpdate = ['update_delivery', 'cancelled']
    if (upResult?.isSuccess) {
      setOpen(false)
      setOpenItem(false)
      setOpenItemId()
      setEditShow(false)
      setItemPopup(false)
      setUpdateDeliveryModal(false)
      if (orderStatesUpdate?.includes(upResult?.originalArgs?.state)) {
        refetch()
      }
    }
    if (uitemresult?.isSuccess) {
      setOpen(false)
      setOpenItem(false)
      setOpenItemId()
      setNote('')
      setEditShow(false)
      setItemPopup(false)
      setUpdateDeliveryModal(false)
      setItemWeightPopup(false)
      setExtraUpdateModal(false)
      cancelItemQuantityModal(false)
      if (itemStatesUpdate?.includes(uitemresult?.originalArgs?.state)) {
        refetch()
      }
    }

  }, [upResult, uitemresult, openItemId, openItem]);


  useEffect(() => {
    if ((viewmanifestresult?.isSuccess) && (!viewmanifestresult?.originalArgs?.pdf)) {
      setManifestModal(true)
      setManifestDetails(viewmanifestresult?.data)
    }

    if ((viewmanifestresult?.isSuccess) && (viewmanifestresult?.originalArgs?.pdf === 'pdf')) {
      window.open(`${viewmanifestresult?.data?.data?.awb_pdf_url}`, '_blank');
    }

    if ((viewmanifestresult?.isSuccess) && (viewmanifestresult?.originalArgs?.pdf === 'pdf46')) {
      window.open(`${viewmanifestresult?.data?.data?.awb_pdf_url_4x6}`, '_blank');
    }

  }, [viewmanifestresult]);

  useEffect(() => {
    if (manifetsresult?.isSuccess && !manifetsresult?.originalArgs?.customs) {
      setManifestModal(true)
      setManifestDetails(manifetsresult?.data)
    }

  }, [manifetsresult]);


  function isCouponDiscount() {
    if (orderData?.coupon?.coupon_code) {
      return Number(orderData?.amount) + Number(orderData?.coupon?.discount)
    } else {
      return Number(orderData?.amount)
    }
  }

  function getDestination() {
    if (orderData?.shipping_address?.name) {
      return {
        name: orderData?.shipping_address?.name,
        address: `${orderData?.shipping_address?.address}, ${orderData?.shipping_address?.street}, ${orderData?.shipping_address?.city}, ${orderData?.shipping_address?.country}`,
        city: orderData?.shipping_address?.city,
        country: orderData?.shipping_address?.country,
        phone: orderData?.shipping_address?.phone
      }
    } else {
      return {
        name: orderData?.guest_shipping?.[0]?.name,
        address: `${orderData?.guest_shipping?.[0]?.address}, ${orderData?.guest_shipping?.[0]?.street}, ${orderData?.guest_shipping?.[0]?.city}, ${orderData?.guest_shipping?.[0]?.country}`,
        city: orderData?.guest_shipping?.[0]?.city,
        country: orderData?.guest_shipping?.[0]?.country,
        phone: orderData?.guest_shipping?.[0]?.phone
      }
    }
  }

  function getLinearProgress() {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
        <LinearProgress style={{ height: '2px' }} />
      </div>
    )
  }


  const name = orderData?.shipping_address?.name ? orderData?.shipping_address?.name : orderData?.guest_shipping?.[0]?.name || orderData?.guest_shipping?.name
  const email = orderData?.shipping_address?.email ? orderData?.shipping_address?.email : orderData?.guest_shipping?.[0]?.email || orderData?.guest_shipping?.email

  const shippingName = name;
  const shippingEmail = email ? email : orderData?.user?.email;

  const cityCode = orderData?.shipping_address?.city_code ? orderData?.shipping_address?.city_code : orderData?.guest_shipping?.[0]?.city_code || orderData?.guest_shipping?.city_code

  function getThisiTemLoader(loading1, loading3, loading4, loading5) {
    if (loading1) {
      return true
    } else if (loading3) {
      return true
    } else if (loading4) {
      return true
    } else if (loading5) {
      return true
    } else {
      return false
    }
  }

  function orderStateColor(states) {
    if (states === 'approved') {
      return 'badge badge-success'
    } else if (states === 'fraud') {
      return 'badge badge-danger'
    } else if (states === 'cancelled') {
      return 'badge badge-danger'
    } else if (states === 'require_attention') {
      return 'badge badge-dark'
    } else {
      return 'badge badge-warning'
    }
  }

  const [getEmailSend, setEmailSend] = useState(true);
  const handleChange = (data) => {
    setEmailSend(data);
  };

  const [getMessageOpen, setMessageOpen] = React.useState(false);

  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`/order/${result}`)
  }

  function orderPageLoading(loading, fetching, deletLoading) {
    if (loading) {
      return true
    } else if (fetching) {
      return true
    } else if (deletLoading) {
      return true
    } else {
      return false
    }
  }

  const [deleteinventory, deleteresult] = useDeleteInventoryMutation()
  function removeFromInventory(id) {
    deleteinventory({
      id: id,
      remove_as_inventory: true,
      userId: userId,
      userToken: userToken,
    })
  }


  const [getRefundPopup, setRefundPopup] = useState(false);
  function refundModal() {
    setRefundPopup(true)
  }
  function cancelRefundPopup() {
    setRefundPopup(false)
  }

  const [getItemChecked, setItemChecked] = useState([]);

  let itemSerialNumber = 1

  const stateArray = ['succeeded', 'SUCCESS', 'success', 'sucess']

  function getState(state) {
    if (stateArray?.includes(state)) {
      return <span className='badge badge-success' style={{ textTransform: 'capitalize' }}>{state}</span>
    } else {
      return <span className='badge badge-warning' style={{ textTransform: 'capitalize' }}>{state}</span>
    }
  }

  return (
    <>
      <div className="tabel_cards">

        <Refund getRefundPopup={getRefundPopup} cancelRefundPopup={cancelRefundPopup} orderInfo={orderInfo} />
        <ManifestDetails getManifestModal={getManifestModal} closeManifestModal={closeManifestModal} getmManifestDetails={getmManifestDetails} viewmanifest={viewmanifest} userId={userId} userToken={userToken} viewmanifestresult={viewmanifestresult} />

        <UpdateDelivery getUpdateDeliveryModal={getUpdateDeliveryModal} cancelUpdateDeliveryModal={cancelUpdateDeliveryModal} getUpdateDeliveryData={getUpdateDeliveryData} uitemresult={uitemresult} updateItemState={updateItemState} shippingName={shippingName} shippingEmail={shippingEmail} />

        <ItemCancel getItemPopup={getItemPopup} cancelItemPopup={cancelItemPopup} uitemresult={uitemresult} orderData={orderData} getItemImage={getItemImage} getItemId={getItemId} userId={userId} userToken={userToken} updateitem={updateitem} shippingName={shippingName} shippingEmail={shippingEmail} />

        <OrderCancel getEditShow={getEditShow} handleCloseEdit={handleCloseEdit} orderData={orderData} upResult={upResult} userId={userId} userToken={userToken} updateOrder={updateOrder} shippingName={shippingName} shippingEmail={shippingEmail} getOrderUpdateActionType={getOrderUpdateActionType} />

        <ItemWeight getItemWeightPopup={getItemWeightPopup} cancelItemWeightModal={cancelItemWeightModal} uitemresult={uitemresult} orderData={orderData} getItemId={getItemId} userId={userId} userToken={userToken} updateitem={updateitem} />

        <ItemQuantity getItemQuantityPopup={getItemQuantityPopup} cancelItemQuantityModal={cancelItemQuantityModal} uitemresult={uitemresult} orderData={orderData} getItemId={getItemId} userId={userId} userToken={userToken} updateitem={updateitem} />

        <ExtraUpdate getExtraUpdateModal={getExtraUpdateModal} cancelExtraUpdateModal={cancelExtraUpdateModal} uitemresult={uitemresult} orderData={orderData} getItemData={getItemData} userId={userId} userToken={userToken} updateitem={updateitem} />

        <Messages getMessageOpen={getMessageOpen} setMessageOpen={setMessageOpen} messages={messages} orderData={orderData} userId={userId} userToken={userToken} userEmail={userEmail} />

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi" style={{ background: borderColor(orderData?.states) }}>
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div className='scroll-menu' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton variant='soft' size='sm' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>

                      <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #eee', background: '#fff', borderRadius: '10px', margin: '0px 10px' }}>
                        {orderPageLoading(orderInfo?.isLoading, orderInfo?.isFetching) ? <span style={{ margin: '0px 10px' }}><Loader /></span> :
                          <div
                            style={{ border: 'none', margin: '0px 10px', textTransform: 'capitalize' }}
                            className={orderStateColor(orderData?.states)}
                          >
                            {orderData?.states?.replace(/_/g, ' ')}
                          </div>
                        }

                        <div style={{ position: 'relative' }}>
                          <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                            <input
                              // defaultValue={id}
                              style={{ border: 'none' }}
                              type="text"
                              className="form-control order_search"
                              placeholder="Search New Order..."
                              id="orderId"
                              aria-invalid={errors.orderId ? "true" : "false"}
                              {...register("orderId", { required: true })}
                            />
                            <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                              <SearchIcon style={{ fontSize: 'inherit' }} />
                            </IconButton>
                          </form>
                        </div>
                      </div>

                    </div>
                    <div className="Order-Pagination">
                      <div style={{ fontSize: '1rem', fontWeight: 400 }}>Order From {orderData?.domain}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {orderPageLoading(orderInfo?.isLoading, orderInfo?.isFetching, deleteresult?.isLoading) &&
          <LinearProgress className='progress-bar-op' style={{ height: '2px' }} />
        }
        {!orderInfo?.isLoading &&
          <>
            {orderData ?
              <>

                <div className="row" style={{ margin: 0, padding: '5px 0px 5px 0px', position: 'sticky', top: '54px', zIndex: 999, background: 'white' }}>
                  <div className="col-md-12" style={{ position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '0px', overflow: 'auto', height: '58px', whiteSpace: 'nowrap' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ position: 'relative', marginRight: '20px' }}>
                          <IconButton
                            style={{ position: 'relative', minHeight: '30px', maxHeight: '30px', }}
                            variant="soft"
                            size='md'
                            onClick={() => setMessageOpen(true)}
                          >
                            <small className='badge badge-warning'
                              style={{ position: 'absolute', right: '-10px', top: '-10px' }}>
                              {messages?.length}
                            </small>
                            <MailOutlineIcon style={{ fontSize: '1.2rem' }} />
                          </IconButton>
                        </div>
                        {orderData?.states !== 'approved' &&
                          <Button
                            onClick={(() => {
                              if (window.confirm('Are you sure to change?'))
                                updateOrder({
                                  id: orderData?.id,
                                  order_states: true,
                                  state: 'approved',
                                  userId: userId,
                                  userToken: userToken
                                })
                            }
                            )}
                            className='pg-button-hp'
                            disabled={!orderData?.status}
                            size='md'
                            style={{ minHeight: '30px', maxHeight: '30px', fontWeight: 500, marginRight: '10px' }}
                            variant='outlined'
                          >
                            <div>Approve</div>
                          </Button>
                        }
                        {!orderData?.status &&
                          <Button
                            className='m-margin-0 pg-button-hp'
                            onClick={(() => {
                              if (window.confirm('Are you sure to change?'))
                                updateState('order_confirm')
                            })}
                            style={{ marginRight: '10px', fontWeight: 500, minHeight: '30px', maxHeight: '30px' }}
                            variant="outlined"
                            size="sm"
                            color={'success'}
                          >
                            Click to confirm
                          </Button>
                        }
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', padding: '0px 0px 0px 0px', justifyContent: 'right' }}>
                        <div style={{ margin: '0px 10px' }}>
                          <CreateManifest createManifest={createManifest} orderData={orderData} viewmanifest={viewmanifest} userId={userId} userToken={userToken} cityCode={cityCode} getDestination={getDestination()} getItemChecked={getItemChecked} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ margin: 0, padding: 0 }}>
                  <div className="col-md-5 list-card-op cont-p0" style={{ position: 'relative' }}>

                    <div className="tabelOverflow details-card" style={{ position: 'relative', overflow: 'hidden', whiteSpace: 'normal', border: !orderData?.status ? '1px solid tomato' : '1px solid #eee' }}>
                      {upResult?.isLoading &&
                        getLinearProgress()
                      }
                      <div className='dfww-sb'>
                        <div className='f-flex-wrap' style={{ display: 'flex', alignItems: 'center' }}>
                          <>
                            {orderData?.status ?
                              <div
                                className='alert alert-success pg-button-hp'
                                style={{ margin: 0, height: '30px', display: 'flex', alignItems: 'center', fontSize: '0.9rem' }}>
                                <CheckCircleOutlineIcon style={{ fontSize: '1.3rem', marginRight: '5px' }} />
                                <span>Checkout confirmed</span>
                              </div>
                              :
                              <>
                                <div
                                  className='alert alert-danger pg-button-hp'
                                  style={{ margin: '0px', height: '30px', display: 'flex', alignItems: 'center', fontSize: '0.9rem' }}>
                                  <ErrorOutlineIcon style={{ fontSize: '1.3rem', marginRight: '5px' }} />
                                  <span>{orderData?.checkout === 'failed' ? 'Payment Failed' : 'Payment is not confirmed'}</span>
                                </div>
                              </>
                            }

                          </>
                        </div>
                        <Dropdown>
                          <MenuButton
                            disabled={!orderData?.status}
                            style={{ padding: '0px 8px', borderRadius: '6px', minHeight: '30px', maxHeight: '30px', fontWeight: 400 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <small>Update</small>
                              <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                            </div>
                          </MenuButton>
                          <Menu className='listUpdate-card'>
                            {orderStates?.map((item, i) =>
                              <MenuItem
                                onClick={(() => {
                                  if (window.confirm('Are you sure to change?'))
                                    updateOrder({
                                      id: orderData?.id,
                                      order_states: true,
                                      state: item,
                                      userId: userId,
                                      userToken: userToken
                                    })
                                }
                                )}
                                key={i}
                                disabled={orderStateButton(item)}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Checkbox
                                    disabled={orderData?.states === item}
                                    size={'sm'}
                                    checked={orderData?.states === item} />
                                  <div style={{ margin: '0px 10px', textTransform: 'capitalize' }}>{item?.replace(/_/g, ' ')}</div>
                                </div>
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={(() => cancelOrder('cancelled'))}
                              key="6"
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  disabled={orderData?.states === 'cancelled'}
                                  size={'sm'}
                                  checked={orderData?.states === 'cancelled'} />
                                <div style={{ margin: '0px 10px', textTransform: 'capitalize' }}>Cancel order</div>
                              </div>
                            </MenuItem>

                            <MenuItem
                              onClick={(() => cancelOrder('require_attention'))}
                              key="6"
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  disabled={orderData?.states === 'require_attention'}
                                  size={'sm'}
                                  checked={orderData?.states === 'require_attention'} />
                                <div style={{ margin: '0px 10px', textTransform: 'capitalize' }}>Require Attention</div>
                              </div>
                            </MenuItem>

                            <MenuItem
                              onClick={(() => {
                                if (window.confirm('Are you sure to change?'))
                                  updateState('order_not_confirm')
                              })}
                              key="6"
                              disabled={!orderData?.status}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  disabled={!orderData?.status}
                                  size={'sm'}
                                  checked={!orderData?.status} />
                                <div style={{ margin: '0px 10px', textTransform: 'capitalize' }}>Mark as checkout not confirmed</div>
                              </div>
                            </MenuItem>
                          </Menu>
                        </Dropdown>
                      </div>
                      <>
                        <div
                          className='f-flex-wrap'
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0px 5px 0px' }}>
                          <span>#{orderData?.order_number} -  {orderData?.id}</span>
                        </div>
                        <div className='dfww-sb'>
                          <div className='f-flex-wrap' style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontWeight: 500 }}>Shipping To</span>
                            <img
                              style={{ margin: "0 10px" }}
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${orderData?.country?.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${orderData?.country?.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {orderData?.shipping_address &&
                              <div>{orderData?.shipping_address?.country}</div>
                            }
                            {orderData?.guest_shipping &&
                              <div>{orderData?.guest_shipping?.[0]?.country}</div>
                            }
                          </div>
                          {orderData?.shipping_address &&
                            <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center', margin: 0 }}>User {orderData?.user?.id}</div>
                          }
                          {orderData?.guest_shipping &&
                            <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center', margin: 0 }}>Guest User</div>
                          }
                        </div>
                      </>
                      <>
                        <ShippingAddress orderData={orderData} updateOrder={updateOrder} upResult={upResult} userId={userId} userToken={userToken} />
                      </>

                      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <div className='op-info-text' style={{ flexWrap: 'wrap' }}>
                          <div style={{ fontSize: '0.8rem', fontWeight: 500 }}>Refund Details:</div>
                          <Button
                            disabled={!orderData?.status}
                            className='pg-button-hp'
                            onClick={(() => refundModal())}
                            variant='soft'
                            size='sm'
                            style={{ minHeight: '2px', maxHeight: '25px', fontWeight: 400, fontSize: '12px' }}>
                            Refund Details
                          </Button>
                        </div>

                        {refunded?.length > 0 &&
                          <>
                            <table className='op-td-table'>
                              <tbody>
                                {refunded?.map((refund, i) =>
                                  <tr key={i}>
                                    <td className='op-td-pd' style={{ fontWeight: 500, textTransform: 'uppercase' }}>
                                      {refund?.currency} {refund?.amount}
                                    </td>
                                    <td className='op-td-pd' >{getState(refund?.state)}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </>
                        }
                      </div>

                      <>
                        <div className='op-info-text'>
                          <span style={{ fontWeight: 500 }}>Amount:&nbsp;</span>
                          <span>
                            {orderData?.currency} {isCouponDiscount(orderData?.amount)}
                          </span>
                        </div>
                        {orderData?.coupon?.coupon_code &&
                          <>
                            <div className='op-info-text'>
                              <span style={{ fontWeight: 500 }}>Discount code:&nbsp;{orderData?.coupon?.coupon_code}</span>
                              <span>-{orderData?.currency} {orderData?.coupon?.discount}</span>
                            </div>
                            <div className='op-info-text'>
                              <span style={{ fontWeight: 500 }}>Subtotal:&nbsp;</span><span>{orderData?.currency} {orderData?.amount}</span>
                            </div>
                          </>
                        }
                        <div className='op-info-text'>
                          <span style={{ fontWeight: 500 }}>Shipping:&nbsp;</span>
                          <span>{orderData?.shipping_price === 0 ? 'FREE' : `+${orderData?.shipping_price}`}</span>
                        </div>
                        <div className='op-info-text'>
                          <span style={{ fontWeight: 500 }}>Total:&nbsp;</span>
                          <span style={{ fontWeight: 500 }}>{orderData?.currency} {Number(orderData?.amount) + Number(orderData?.shipping_price)}
                          </span>
                        </div>
                      </>

                      <>
                        <UpdateMessage messages={messages} orderData={orderData} userId={userId} userToken={userToken} userEmail={userEmail} />
                      </>
                    </div>
                  </div>
                  <div className="col-md-7 list-card-op cont-p0">
                    <div className='details-card' style={{ position: 'relative', padding: '0px', border: 'none' }}>

                      {getThisiTemLoader(uitemresult?.isLoading, manifetsresult?.isLoading, viewmanifestresult?.isLoading, itemShippingStatusResult?.isLoading) &&
                        <div className="row" style={{
                          margin: 0, padding: 0, height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          right: 0,
                          left: 0,
                          zIndex: 111,
                          background: '#ffffff9c'
                        }}>
                        </div>
                      }
                      {getThisiTemLoader(uitemresult?.isLoading, manifetsresult?.isLoading, viewmanifestresult?.isLoading, itemShippingStatusResult?.isLoading) &&
                        <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
                          <LinearProgress style={{ height: '2px' }} />
                        </div>
                      }
                      <>
                        {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
                          <div key={group} style={{ background: '#fff', padding: '20px', borderRadius: '10px', marginBottom: '15px', border: '1px solid #00000026' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                              <div style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                Delivery by&nbsp;{getDeliverydays(group, orderData?.createdAt)}
                              </div>
                            </div>
                            <div>
                              {groupItems?.map(function (item, index) {
                                const serialNumber = itemSerialNumber++;
                                return <ItemList
                                  id={id}
                                  key={index}
                                  indexNumber={serialNumber}
                                  item={item}
                                  orderData={orderData}
                                  updateItemState={updateItemState}
                                  itemShippingStatus={itemShippingStatus}
                                  updateDeliveryDays={updateDeliveryDays}
                                  updateItemPopup={updateItemPopup}
                                  itemWeightModal={itemWeightModal}
                                  itemQuantityModal={itemQuantityModal}
                                  extraUpdateModal={extraUpdateModal}
                                  userId={userId}
                                  userToken={userToken}
                                  shippingName={shippingName}
                                  shippingEmail={shippingEmail}
                                  uitemresult={uitemresult}
                                  getEmailSend={getEmailSend}
                                  removeFromInventory={removeFromInventory}
                                  setItemChecked={setItemChecked}
                                  getItemChecked={getItemChecked}
                                  viewmanifest={viewmanifest}
                                  handleChange={handleChange}
                                />;
                              })}
                            </div>
                          </div>
                        ))}
                      </>

                    </div>
                  </div>
                </div>
              </> :
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none', fontSize: '1.2rem' }}>
                  Order Not Found #{id}
                </div>
              </div>
            }
          </>
        }
        <br />
        <br />
      </div>
      <PageError loading={orderInfo?.isLoading} originalStatus={orderInfo?.error?.originalStatus} data={orderInfo?.error?.data} />
    </>
  );
}

export default Order;
