import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import LoginC from '../../components/LoginC';

function Login() {
  const { userId, userToken } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && userToken) {
      navigate('/');
    }
  }, [userId, userToken]);

  return (
    <div className="login-page">
      <div className="container">
        <div className="row loginRow">
          <div className="rowBox" style={{ textAlign: 'center' }}>
            <LoginC />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
