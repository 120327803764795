import React, { useState, useEffect, Fragment, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from 'react-redux'
import { useGetDeliveryReportQuery } from '../../services/reports';
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Button from '@mui/joy/Button';
import Action from './Action';
import Loader from '../../Helper/Loader';
import ScheduleIcon from '@mui/icons-material/Schedule';

function Reports() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin, isPartner: LocalPartner } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const { data: ordersData, refetch, isLoading, isFetching, error } = useGetDeliveryReportQuery({
    page: page,
    size: limit,
    search: thisSearch,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const users = ordersData?.entities?.undefined?.results;
  const pagination = ordersData?.entities?.undefined?.pagination;

  function getLoadingProgress(thisLoading, thisFetching, loading1, loading2) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else if (loading1) {
      return true
    } else if (loading2) {
      return true
    } else {
      return false
    }
  }

  const [getNewModal, setNewModal] = useState(false)

  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }

  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState([])


  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  function getStatusInfo(state) {
    let stateName;
    let className;

    if (state == 'in_progress') {
      stateName = 'In Progress...';
      className = 'badge-warning';
    } else if (state == 'completed') {
      stateName = 'Completed';
      className = 'badge-success';
    } else {
      stateName = 'Pending';
      className = 'badge-warning';
    }
    return { stateName, className }
  }


  function checkLinkExpire(created) {
    const expiryTime = Moment(created).add(10, 'minutes');
    const now = Moment();
    if (now.isAfter(expiryTime)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>

      <div className="tabel_cards">
        <Action getNewModal={getNewModal} closeNewModal={closeNewModal} getUserUpdateModal={getUserUpdateModal} closeUserUpdateModal={closeUserUpdateModal} getUserUpdateData={getUserUpdateData} />
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>

                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                    </div>
                  </div>
                </div>
                <div className="table_search_card">
                  <Button
                    style={{ fontWeight: 400 }}
                    variant="soft"
                    size="sm"
                    onClick={() => ebtidaEModal()}>
                    <span style={{ marginRight: '5px' }}> Create New Report</span>
                    <ScheduleIcon style={{ fontSize: '1.1rem' }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                    <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                    <div className="Order-Pagination">
                      <Pagination
                        style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                        page={parseInt(page)}
                        count={parseInt(pagination?.totalPages)}
                        color="primary"
                        variant="outlined"
                        renderItem={(item) => (
                          <PaginationItem
                            className='list-paginatiion-item'
                            component={Link}
                            to={{
                              pathname: `${window.location.pathname}`,
                              search: `?page=${parseInt(item.page)}${thisSearch}`
                            }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
          <div style={{ padding: 0, width: '100%' }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(isLoading, isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th>Created by</th>
                    <th>File</th>
                    <th>Status</th>
                    <th>Days</th>
                    <th>Created At</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>
                      State
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => {
                    const bgColor = item.state === 'pending' ? '#ffc0080a' : '#fff0';
                    return (
                      <tr key={i} style={{ background: '#f6f6f3' }}>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {item.user?.email}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {checkLinkExpire(item.createdAt) ?
                              <span className='badge badge-danger'>Expired</span> :
                              <span className='badge badge-success'>Active</span>
                            }
                            <a
                              style={{ margin: '0px 5px' }}
                              target='_blank'
                              href={item.file} download={item.file}>
                              Download Shipment Report
                            </a>
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {checkLinkExpire(item.createdAt) ?
                              <span className='badge badge-secondary'>Not available</span> :
                              <>
                                <span
                                  className={`badge ${getStatusInfo(item.state)?.className}`}
                                  style={{ textTransform: 'uppercase' }}>
                                  {getStatusInfo(item.state)?.stateName}
                                </span>
                                {item.state === 'in_progress' &&
                                  <span style={{ margin: '0px 10px' }}><Loader /></span>
                                }
                              </>
                            }
                          </div>
                        </td>
                        <td>
                          <div>{item.created} Days</div>
                        </td>
                        <td>
                          <div>{Moment(item.createdAt).format('lll')}</div>
                        </td>
                        <td className="sticky-column" style={{ textAlign: 'right', background: '#f6f6f3' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                            <Dropdown>
                              <MenuButton
                                variant='outlined'
                                size='sm'
                                style={{ borderRadius: '4px', minHeight: '18px', maxHeight: '18px', padding: '0px', marginRight: '5px' }}
                                className="Header-button-connect">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <ArrowDropDownIcon style={{ fontSize: '1.2rem' }} />
                                </div>
                              </MenuButton>
                              <Menu>
                                {/* <MenuItem> </MenuItem> */}
                              </Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {users?.length === 0 ?
              <h5 className="data_not-found">No data available in table</h5>
              : null}
            {error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{error?.data}</div>
              </div>
            }
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}

export default Reports;