import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import IconButton from '@mui/joy/IconButton';
import { Modal } from 'react-bootstrap'
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ShippingCountry from '../../Helper/ShippingCountry';
import Countries from '../../Helper/Countries';
var moment = require('moment');

function ShippingAddress({ orderData, updateOrder, upResult, userId, userToken }) {

  function isGestCountryCode() {
    if (orderData?.guest_shipping?.[0]?.country_code || orderData?.guest_shipping?.country_code) {
      return true
    } else {
      return false
    }
  }

  const guestCountry1 = Countries?.find((c) => c.code === orderData?.guest_shipping?.[0]?.country_code || orderData?.guest_shipping?.country_code)
  const guestCountry2 = orderData?.guest_shipping?.[0]?.country || orderData?.guest_shipping?.country

  const shippingcountry = orderData?.shipping_address?.country ? orderData?.shipping_address?.country : isGestCountryCode() ? guestCountry1?.label : guestCountry2

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)

  const [getShippingData, setShippingData] = React.useState([]);
  const [getShippingType, setShippingType] = React.useState('');
  const [getShippingModal, setShippingModal] = React.useState(false);
  function updateShippingAddress(data) {
    setShippingData(data?.data)
    setShippingType(data?.shipping_type)
    setShippingModal(true)
  }

  function closeShippingModal() {
    setShippingModal(false)
  }

  const findCountry = ShippingCountry?.filter((c) => c.country_name === shippingcountry)

  function shippingAddressUpdate(data) {
    const findCity = findCountry?.find((c) => c.city_code === data.city)
    updateOrder({
      id: getShippingType == 'user' ? orderData?.shipping_address?.id : orderData?.id,
      state: getShippingType == 'user' ? 'user_shipping' : 'guest_shipping',
      data: {
        name: data?.name,
        email: data?.email,
        address: data?.address,
        street: data?.street,
        city: findCity?.city_name,
        city_code: findCity?.city_code,
        country: getShippingData?.country,
        country_code: getShippingData?.country_code,
        phone: getPhone ? getPhone : getShippingData?.phone,
        zip_code: data?.zip_code,
      },
      userId: userId,
      userToken: userToken
    })

  }

  useEffect(() => {
    if (upResult?.isSuccess) {
      setShippingModal(false)
    }
  }, [upResult]);

  useEffect(() => {
    reset()
  }, [orderData]);

  return (
    <>
      <Modal className="cancel-modal" show={getShippingModal} onHide={closeShippingModal} centered animation={false}>

        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
              Update shipping address
            </div>
            <IconButton
              variant='outlined'
              size='sm'
              color='danger'
              style={{ minHeight: '27px', maxHeight: '27px' }}
              onClick={closeShippingModal}
            >
              <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit(shippingAddressUpdate)}>
            <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Name</label>
                  <input
                    defaultValue={getShippingData?.name}
                    type="text"
                    className="form-control"
                    id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Email</label>
                  <input
                    defaultValue={getShippingData?.email}
                    type="text"
                    className="form-control"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Address</label>
                  <input
                    defaultValue={getShippingData?.address}
                    type="text"
                    className="form-control"
                    id="address"
                    aria-invalid={errors.address ? "true" : "false"}
                    {...register('address', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Street</label>
                  <input
                    defaultValue={getShippingData?.street}
                    type="text"
                    className="form-control"
                    id="street"
                    aria-invalid={errors.street ? "true" : "false"}
                    {...register('street', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>City</label>
                  <select
                    className="form-control"
                    id="city"
                    aria-invalid={errors.city ? "true" : "false"}
                    {...register('city', { required: true })}>
                    <option value={''} selected disabled>Select</option>
                    {/* <option value={getShippingData?.city_code}>{getShippingData?.city}</option> */}
                    {findCountry?.map((item) =>
                      <option value={item.city_code}>{item.city_name}</option>
                    )}

                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Country</label>
                  <select className="form-control">
                    <option disabled selected>{getShippingType === 'user' ? getShippingData?.country : isGestCountryCode() ? guestCountry1?.label : guestCountry2}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Phone</label>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={orderData?.country}
                    value={getShippingData?.phone}
                    onChange={setPhone}
                  />
                  {getPhoneError &&
                    <>
                      {getPhone && isValidPhoneNumber(getPhone) ? '' :
                        <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                      }
                    </>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label style={{ fontSize: '14px' }}>Zip Code</label>
                  <input
                    defaultValue={getShippingData?.zip_code}
                    type="text"
                    className="form-control"
                    id="zip_code"
                    aria-invalid={errors.zip_code ? "true" : "false"}
                    {...register('zip_code', { required: false })} />
                </div>
              </div>
              <div className="col-md-12 Header-signin-button">
                <div className="form-group">
                  <Button
                    disabled={upResult?.isLoading}
                    variant='soft'
                    size='lg'
                    style={{ fontWeight: 500, width: '100%' }}
                    type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {orderData?.shipping_address &&
        <div style={{ border: '1px solid #1890ff75', padding: '10px', margin: '10px 0px', borderRadius: '4px', position: 'relative' }}>
          <IconButton
            disabled={!orderData?.status}
            variant='outlined'
            size='sm'
            onClick={(() => updateShippingAddress({ data: orderData?.shipping_address, shipping_type: 'user' }))}
            style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1, minHeight: '25px', maxHeight: '25px' }}>
            <svg width="12px" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
            </svg>
          </IconButton>
          <div className='wba'>{orderData?.shipping_address?.name}</div>
          <div className='wba'>{orderData?.shipping_address?.address}, {orderData?.shipping_address?.street} </div>
          <div className='wba'>{orderData?.shipping_address?.city}, {orderData?.shipping_address?.city_code}</div>
          <div className='wba'>{orderData?.shipping_address?.country}</div>
          <div className='wba'>{orderData?.shipping_address?.landmark}</div>
          <div className='wba'>{orderData?.shipping_address?.phone}</div>
          <div className='wba'>{orderData?.user?.email}</div>
          <div className='wba'>{orderData?.shipping_address?.zip_code}</div>
        </div>
      }
      {orderData?.guest_shipping &&
        <div style={{ border: '1px solid #1890ff75', padding: '10px', margin: '10px 0px', borderRadius: '4px', position: 'relative' }}>
          <IconButton
            disabled={!orderData?.status}
            variant='outlined'
            size='sm'
            onClick={(() => updateShippingAddress({ data: orderData?.guest_shipping?.[0] || orderData?.guest_shipping, shipping_type: 'guest' }))}
            style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1, minHeight: '25px', maxHeight: '25px' }}>
            <svg width="12px" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
            </svg>
          </IconButton>
          <div className='wba'>{orderData?.guest_shipping?.[0]?.name || orderData?.guest_shipping?.name}</div>
          <div className='wba'>{orderData?.guest_shipping?.[0]?.address || orderData?.guest_shipping?.address}, {orderData?.guest_shipping?.[0]?.street || orderData?.guest_shipping?.street} </div>
          <div className='wba'>
            {orderData?.guest_shipping?.[0]?.city || orderData?.guest_shipping?.city}, {orderData?.guest_shipping?.[0]?.city_code || orderData?.guest_shipping?.city_code}</div>
          <div className='wba'>
            {orderData?.guest_shipping?.[0]?.country || orderData?.guest_shipping?.country}</div>
          <div className='wba'>{orderData?.guest_shipping?.[0]?.landmark || orderData?.guest_shipping?.landmark}</div>
          <div className='wba'>{orderData?.guest_shipping?.[0]?.phone || orderData?.guest_shipping?.phone}</div>
          <div className='wba'>{orderData?.guest_shipping?.[0]?.email || orderData?.guest_shipping?.email}</div>
          <div className='wba'>{orderData?.guest_shipping?.[0]?.zip_code || orderData?.guest_shipping?.zip_code}</div>
        </div>
      }


      <div className='op-info-text' style={{ flexWrap: 'wrap' }}>
        <span style={{ fontWeight: 500 }}>Payment by:&nbsp;</span>
        <span>{orderData?.payment_type?.replace('_', ' ')}</span>
      </div>

      <div className='op-info-text' style={{ flexWrap: 'wrap' }}>
        <span style={{ fontWeight: 500 }}>Created At:&nbsp;</span>
        <span>{moment(orderData?.createdAt).format('lll')}</span>
      </div>

      <div className='op-info-text' style={{ flexWrap: 'wrap' }}>
        <span style={{ fontWeight: 500 }}>Last updated:&nbsp;</span>
        <span>{moment(orderData?.updatedAt).format('lll')}</span>
      </div>
    </>
  )

}

export default ShippingAddress;
