import React from 'react';
import DelayedOrders from '../../components/DistributionsC/DelayedOrders';

function Shipments() {
  return (
    <div className="tabel_cards">
      <DelayedOrders />
    </div>

  );
}

export default Shipments;
