import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';
import "antd/dist/antd.min.css";
import { useImportFileMutation, useFetchStoreProductsCashedQuery } from '../../services/action';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CSVLink, CSVDownload } from 'react-csv';
import { useDropzone } from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Loader from '../../Helper/Loader';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const socket = io(process.env.REACT_APP_API_CONNECT);

function ProductsAction() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { action, merchant_id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const storeProductsInfo = useFetchStoreProductsCashedQuery({ id: merchant_id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const storeProducts = storeProductsInfo?.data?.results;

  const [updatefile, updatefileresult] = useImportFileMutation()
  const [getUpdateImage, setUpdateImage] = useState();
  function updateBookImage() {
    updatefile({
      image_file: getUpdateImage,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      merchant_id: merchant_id,
    })
  }

  const [progress, setProgress] = useState({ progress: 0, type: '', in_progress: false });
  useEffect(() => {
    socket.on(`csv-file-action`, (data) => {
      setProgress(data?.results);
    });
    return () => {
      socket.off(`csv-file-action`);
    };
  }, [socket]);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  function buttonDisabled() {
    if (updatefileresult.isLoading) {
      return true
    } else if (!getUpdateImage) {
      return true
    } else if (progress?.in_progress) {
      return true
    } else {
      return false
    }
  }

  const csvData = [
    ['id', 'country'],
    ['12345', 'AE']
  ];

  const MAX_FILE_SIZE = 200 * 1024 * 1024;

  const FileUpload = ({ setUpdateImage }) => {

    const onDropRejected = React.useCallback((fileRejections) => {
      fileRejections?.forEach((rejection) => {
        const { file, errors } = rejection;
        errors.forEach((error) => {
          if (error.code === "file-too-large") {
            alert(`File "${file.name}" is too large. Max size allowed is 200 MB.`);
          }
          if (error.code === "file-invalid-type") {
            alert(`File "${file.name}" is not a CSV file.`);
          }
        });
      });
    }, []);

    const onDrop = React.useCallback(async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      setUpdateImage(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      onDropRejected,
      accept: { "text/csv": [".csv"] },
      maxFiles: 1,
      maxSize: MAX_FILE_SIZE,
    });
    return (
      <>
        <IconButton
          title='Attach Invoice'
          {...getRootProps()}
          style={{
            border: '1px dashed #aaa',
            textAlign: 'center',
            width: '100%',
            padding: '10px',
            display: 'block',
            borderRadius: '10px'
          }}
        >
          <input {...getInputProps()} />
          <div><AttachFileIcon style={{ fontSize: '3rem', color: '#5f6264' }} /></div>
          <div> <small>Select a file or drag and drop it here</small></div>

        </IconButton>
      </>
    )
  }

  const fileSizeInMB = (getUpdateImage?.size / (1024 * 1024)).toFixed(4);
  const fileSizeInGB = (getUpdateImage?.size / (1024 * 1024 * 1024)).toFixed(2);


  function getProgressType(type) {
    if (type == 'uploading') {
      return 'badge-info'
    } else if (type == 'importing') {
      return 'badge-warning'
    } else if (type == 'completed') {
      return 'badge-success'
    } else {
      return 'badge-warning'
    }
  }

  return (
    <>
      <div className="tabel_cards">


        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'auto',
                    width: '100%',
                    whiteSpace: 'nowrap'
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton size='sm' variant='soft' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="row" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>

              <div className="col-md-7">
                <div style={{ background: '#f0f4f840', padding: '20px', border: '1px solid #eee', borderRadius: '10px' }}>
                  <div className="row">
                    <div className="col-md-12">

                      <div style={{ fontSize: '1rem', fontWeight: 400, padding: '20px 0px 10px 0px' }}>
                        Import a CSV file containing product IDs to remove them from Google Merchant Center and disable them in the database.
                      </div>

                      <div style={{ display: 'block' }}>
                        <CSVLink data={csvData} filename={"action-products.csv"} target="_blank">
                          <Button
                            size="sm"
                            variant='light'
                            style={{ width: 'auto', textTransform: 'capitalize', fontWeight: 400, padding: 0 }}
                            onClick={updateBookImage}
                          >
                            Download sample CSV
                          </Button>
                        </CSVLink>
                      </div>

                      {updatefileresult?.error?.data?.error &&
                        <div className='alert alert-danger'>
                          {updatefileresult?.error?.data?.error}
                        </div>
                      }


                      {progress?.type &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={progress?.progress} />
                          </Box>

                          <div className={`badge ${getProgressType(progress?.type)}`} style={{ marginRight: '10px' }}>
                            {progress?.type}
                          </div>

                          {progress?.in_progress &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Loader />
                            </div>
                          }
                        </div>
                      }
                    </div>

                    <div className="col-md-12" style={{ display: 'block' }}>
                      <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="col-md-12">
                          <div style={{ padding: '10px 0px 10px 0px', }}>
                            <span style={{ fontWeight: 600 }}>Merchant ID:&nbsp;</span>
                            <span>{merchant_id}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {storeProducts &&
                      <div className="col-md-12" style={{ display: 'block' }}>
                        <div className="row" style={{ justifyContent: 'center' }}>

                          <div className="col-md-12">
                            <div style={{ fontSize: '1rem', fontWeight: '600', padding: '10px 0px 10px 0px', textAlign: 'start' }}>Last updated</div>
                          </div>

                          <div className="col-md-12">
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                              <div style={{ marginRight: '10px', fontWeight: 500 }}>File Name:</div>
                              <div>{storeProducts?.file_name}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                              <div style={{ marginRight: '10px', fontWeight: 500 }}>Progress:</div>
                              <div>{storeProducts?.progress}%</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                              <div style={{ marginRight: '10px', fontWeight: 500 }}>Rows:</div>
                              <div>{storeProducts?.limit}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                              <div style={{ marginRight: '10px', fontWeight: 500 }}>Processed:</div>
                              <div>{storeProducts?.processed}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                              <div style={{ marginRight: '10px', fontWeight: 500 }}>Chunk Count:</div>
                              <div>{storeProducts?.chunk_count}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', flexWrap: 'wrap' }}>
                              <div style={{ marginRight: '10px', fontWeight: 500 }}>Chunk Size:</div>
                              <div>{storeProducts?.chunk_size}</div>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    }
                    {/* <div className="col-md-12">
                      <div className="form-group">
                        <input multiple type="file" className="form-control"
                          onChange={(e) => setUpdateImage(e.target.files[0])} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group" style={{ textAlign: 'center' }}>
                        <Button
                          color="info"
                          variant="contained"
                          size="medium"
                          style={{ width: '100%', height: '45px' }}
                          disabled={buttonDisabled()}
                          onClick={updateBookImage}>
                          Update
                        </Button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div style={{ background: '#f0f4f840', padding: '20px', border: '1px solid #eee', borderRadius: '10px' }}>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: 'center' }}>

                      <FileUpload setUpdateImage={setUpdateImage} />
                      <div style={{ textAlign: 'start', padding: '10px 0px 10px 0px' }}>
                        {!isNaN(fileSizeInMB) &&
                          <div> <small><span style={{ fontWeight: 500 }}>File Size:</span> {fileSizeInMB} MB</small></div>
                        }
                        {getUpdateImage?.name &&
                          <div><small><span style={{ fontWeight: 500 }}>File Name:</span> {getUpdateImage?.name}</small></div>
                        }
                      </div>

                    </div>
                    <div className="col-md-12">
                      <div className="form-group" style={{ textAlign: 'center' }}>
                        <Button
                          variant="soft"
                          size="lg"
                          style={{ width: '100%', textTransform: 'capitalize', fontWeight: 500 }}
                          disabled={buttonDisabled()}
                          onClick={updateBookImage}>
                          {progress?.type ? progress?.type : 'Update'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div >
        <br /> <br />
      </div >
    </>
  );
}

export default ProductsAction;
