import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllCountriesQuery, useDeleteCountriesMutation, useCreateCountriesMutation, useUpdateCountriesMutation } from '../../services/route_one';
import { useSelector } from "react-redux";
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/joy/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
function RouteOne({ route }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const suppliersData = useGetAllCountriesQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, search: search, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.countries;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteCountriesMutation()
  const [createNew, createresult] = useCreateCountriesMutation()


  function createNewFunc(data) {
    createNew({
      name: data.name,
      code: data.code,
      currency: data.currency,
      conversion: data.conversion,
      discount: data.discount,
      price: data.price,
      shipping: data.shipping,
      duty: data.duty,
      vat_in: data.vat_in,
      margin: data.margin,
      vat_out: data.vat_out,
      standard_delivery: data.standard_delivery,
      fastest_delivery: data.fastest_delivery,
      delivery_days: data.delivery_days,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getCode, setCode] = useState("")
  const [getCurrency, setCurrency] = useState("")
  const [getConversion, setConversion] = useState("")
  const [getDiscount, setDiscount] = useState("")
  const [getPrice, setPrice] = useState("")
  const [getShipping, setShipping] = useState("")
  const [getDuty, setDuty] = useState("")
  const [getVatIn, setVatIn] = useState("")
  const [getMargin, setMargin] = useState("")
  const [getVatOut, setVatOut] = useState("")
  const [getStandardDelivery, setStandardDelivery] = useState("")
  const [getFastestDelivery, setFastestDelivery] = useState("")
  const [getDeliveryDays, setDeliveryDays] = useState("")
  const [getId, setId] = useState("")

  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setName(data.name)
    setCode(data.code)
    setCurrency(data.currency)
    setConversion(data.conversion)
    setDiscount(data.discount)
    setPrice(data.price)
    setShipping(data.shipping)
    setDuty(data.duty)
    setVatIn(data.vat_in)
    setMargin(data.margin)
    setVatOut(data.vat_out)
    setStandardDelivery(data.standard_delivery)
    setFastestDelivery(data.fastest_delivery)
    setDeliveryDays(data.delivery_days)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    code: getCode,
    currency: getCurrency,
    conversion: getConversion,
    discount: getDiscount,
    price: getPrice,
    shipping: getShipping,
    duty: getDuty,
    vat_in: getVatIn,
    margin: getMargin,
    vat_out: getVatOut,
    standard_delivery: getStandardDelivery,
    fastest_delivery: getFastestDelivery,
    delivery_days: getDeliveryDays,
    userId: userId,
    userToken: userToken,
    admin: admin
  }
  const [updatePost, updateresult] = useUpdateCountriesMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
      reset()
    }
  }, [updateresult, createresult]);

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  const [getSubscriptionsModal, setSubscriptionsModal] = React.useState(false);
  const [getSubscriptionsData, setSubscriptionsData] = React.useState([]);
  function showSubscription(data) {
    setSubscriptionsModal(true);
    setSubscriptionsData(data);
  }
  function closeSubscriptionsModal() {
    setSubscriptionsModal(false);
  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset2()
    navigate(`${window.location.pathname}`)
  }

  return (
    <>
      <div className="tabel_cards">


        <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={handleClose}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>New</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <h5 style={{ textAlign: 'start', fontSize: '14px', color: 'tomato' }}>Value must be on local currency</h5>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit(createNewFunc)}>
              <div className="row">

                <div className="col-md-8">
                  <div className="form-group">
                    <label>Country Name</label>
                    <input type="text" className="form-control" id="name"
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register('name', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Country Code</label>
                    <input type="text" className="form-control" id="code"
                      aria-invalid={errors.code ? "true" : "false"}
                      {...register('code', { required: true })} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Currency</label>
                    <input type="text" className="form-control" id="currency"
                      aria-invalid={errors.currency ? "true" : "false"}
                      {...register('currency', { required: true })} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Discount</label>
                    <input type="number" className="form-control" id="discount"
                      aria-invalid={errors.discount ? "true" : "false"}
                      {...register('discount', { required: true })} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Price</label>
                    <input type="number" className="form-control" id="price"
                      aria-invalid={errors.price ? "true" : "false"}
                      {...register('price', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Conversion <small>x100</small></label>
                    <input type="number" className="form-control" id="conversion"
                      aria-invalid={errors.conversion ? "true" : "false"}
                      {...register('conversion', { required: true })} />
                  </div>
                </div>


                <div className="col-md-4">
                  <div className="form-group">
                    <label>Margin % <small>x100</small></label>
                    <input type="number" className="form-control" id="margin"
                      aria-invalid={errors.margin ? "true" : "false"}
                      {...register('margin', { required: true })} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Shipping <small>x100</small></label>
                    <input type="number" className="form-control" id="shipping"
                      aria-invalid={errors.shipping ? "true" : "false"}
                      {...register('shipping', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Duty % <small>x100</small></label>
                    <input type="number" className="form-control" id="duty"
                      aria-invalid={errors.duty ? "true" : "false"}
                      {...register('duty', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Vat in % <small>x100</small></label>
                    <input type="number" className="form-control" id="vat_in"
                      aria-invalid={errors.vat_in ? "true" : "false"}
                      {...register('vat_in', { required: true })} />
                  </div>
                </div>



                <div className="col-md-4">
                  <div className="form-group">
                    <label>Vat out % <small>x100</small></label>
                    <input type="number" className="form-control" id="vat_out"
                      aria-invalid={errors.vat_out ? "true" : "false"}
                      {...register('vat_out', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Stdrd delivery <small>x100</small></label>
                    <input type="number" className="form-control" id="standard_delivery"
                      aria-invalid={errors.standard_delivery ? "true" : "false"}
                      {...register('standard_delivery', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Fas delivery <small>x100</small></label>
                    <input type="number" className="form-control" id="fastest_delivery"
                      aria-invalid={errors.fastest_delivery ? "true" : "false"}
                      {...register('fastest_delivery', { required: true })} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Delivery Days</label>
                    <input type="number" className="form-control" id="delivery_days"
                      aria-invalid={errors.delivery_days ? "true" : "false"}
                      {...register('delivery_days', { required: true })} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>

                    <Button
                      variant="soft"
                      size="sm"
                      disabled={createresult?.isLoading ? true : false}
                      type="submit"
                      style={{ fontWeight: 400 }}
                    >
                      {createresult?.isLoading ? 'loading...' : 'Create'}
                    </Button>

                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
          <Modal.Body>

            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={handleCloseEdit}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Update</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <h5 style={{ textAlign: 'start', fontSize: '14px', color: 'tomato' }}>Value must be on local currency</h5>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <label>Country Name</label>
                  <input type="text" className="form-control" value={getName}
                    onChange={(e) => setName(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Country Code</label>
                  <input type="text" className="form-control" value={getCode}
                    onChange={(e) => setCode(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Currency</label>
                  <input type="text" className="form-control" value={getCurrency}
                    onChange={(e) => setCurrency(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Discount</label>
                  <input type="number" className="form-control" value={getDiscount}
                    onChange={(e) => setDiscount(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Price</label>
                  <input type="text" className="form-control" value={getPrice}
                    onChange={(e) => setPrice(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Conversion <small>x100</small></label>
                  <input type="number" className="form-control" value={getConversion}
                    onChange={(e) => setConversion(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Margin % <small>x100</small></label>
                  <input type="number" className="form-control" value={getMargin}
                    onChange={(e) => setMargin(e.target.value)} />
                </div>
              </div>


              <div className="col-md-4">
                <div className="form-group">
                  <label>Shipping <small>x100</small></label>
                  <input type="number" className="form-control" value={getShipping}
                    onChange={(e) => setShipping(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Duty % <small>x100</small></label>
                  <input type="number" className="form-control" value={getDuty}
                    onChange={(e) => setDuty(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Vat in % <small>x100</small></label>
                  <input type="number" className="form-control" value={getVatIn}
                    onChange={(e) => setVatIn(e.target.value)} />
                </div>
              </div>



              <div className="col-md-4">
                <div className="form-group">
                  <label>Vat out % <small>x100</small></label>
                  <input type="number" className="form-control" value={getVatOut}
                    onChange={(e) => setVatOut(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Stndrd delivery <small>x100</small></label>
                  <input type="number" className="form-control" value={getStandardDelivery}
                    onChange={(e) => setStandardDelivery(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Fas delivery <small>x100</small></label>
                  <input type="number" className="form-control" value={getFastestDelivery}
                    onChange={(e) => setFastestDelivery(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Delivery Days</label>
                  <input type="number" className="form-control" value={getDeliveryDays}
                    onChange={(e) => setDeliveryDays(e.target.value)} />
                </div>
              </div>


              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    variant="soft"
                    size="sm"
                    style={{ fontWeight: 400 }}
                    disabled={updateresult?.isLoading ? true : false}
                    onClick={() => { updatePost(updateData) }}>
                    {updateresult?.isLoading ? 'loading...' : 'Update'}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    disabled={suppliersData?.isLoading ? true : false}
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}${thisSearch}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit2(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search..."
                        id="orderId"
                        aria-invalid={errors2.orderId ? "true" : "false"}
                        {...register2("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                  <Button
                    disabled={route === 'routeTwo'}
                    component={Link}
                    to={`/countries/routeTwo`}
                    style={{ fontWeight: 400, margin: '0 10px' }}
                    variant="soft"
                    size="sm"
                  >
                    Route Two
                  </Button>
                  <Button
                    disabled={route === 'routeOne'}
                    component={Link}
                    to={`/countries/routeOne`}
                    style={{ fontWeight: 400, margin: '0 10px' }}
                    variant="soft"
                    size="sm"
                  >
                    Route One
                  </Button>
                </div>

                <div className="table_search_card">
                  <div>
                    <Button
                      className='pg-button-hp'
                      style={{ fontWeight: 400 }}
                      variant="soft"
                      size="sm"
                      onClick={() => handleOpen(true)}>
                      <span style={{ marginRight: '5px' }}>Add New</span>
                      <AddCircleOutlinedIcon style={{ fontSize: '1.1rem', }} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>






        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(suppliersData?.isLoading, suppliersData?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Currency</th>
                    <th>Conversion</th>
                    <th>Discount</th>
                    <th>Price</th>
                    <th>Shipping</th>
                    <th>Duty</th>
                    <th>Vat In</th>
                    <th>Margin</th>
                    <th>Vat Out</th>
                    <th>Stnd. Dlvry</th>
                    <th>Fst. Dlvry</th>
                    <th>Dlvry Dys</th>
                    <th>Updated</th>

                    <th className="sticky-column" style={{ textAlign: 'right', width: '1px' }}>
                      Manage
                    </th>
                  </tr>
                </thead>
                <tbody style={{ position: 'relative' }}>

                  {suppliers?.map((item, i) =>
                    <tr key={i}>
                      <td style={{ width: '0px' }}>
                        {item.id}
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => handleOpenEdit(item)}
                          >
                            <EditIcon style={{ fontSize: '12px' }} />
                          </IconButton>
                          <div>{item.name}</div>
                        </div>

                      </td>
                      <td>{item.code}</td>
                      <td>{item.currency}</td>
                      <td>{item.conversion}</td>
                      <td>{item.discount}</td>
                      <td>{item.price}</td>
                      <td>{item.shipping}</td>
                      <td>{item.duty}</td>
                      <td>{item.vat_in}</td>
                      <td>{item.margin}</td>
                      <td>{item.vat_out}</td>
                      <td>{item.standard_delivery}</td>
                      <td>{item.fastest_delivery}</td>
                      <td>{item.delivery_days}</td>
                      <td>{moment(item.createdAt).local().format("MMM Do YYYY")}</td>

                      <td className="sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <Button
                            style={{ textTransform: 'capitalize', border: '0.5px solid #4e5053', color: '#4e5053' }}
                            disabled={updateresult?.isLoading}
                            variant="outlined"
                            size="small"
                            onClick={() => updatePost({
                              update_json: true,
                              userId: userId,
                              userToken: userToken,
                              admin: admin,
                              id: item.id
                            })}>
                            <small>{updateresult?.isLoading && updateresult?.originalArgs?.id === item.id ? 'Updating...' : 'Update Sheet'}</small>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {suppliers?.length === 0 &&
              <h5 className="data_not-found">No data available in table</h5>
            }
            {suppliersData?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{suppliersData?.error?.data}</div>
              </div>
            }
          </div>
        </div>
        <br /><br />
      </div>
    </>
  );
}

export default RouteOne;
