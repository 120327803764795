import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useSelector } from "react-redux";
import { useYearsCountQuery, useYearsCountFailedQuery } from '../../services/index';
import { BarChart } from '@mui/x-charts/BarChart';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Button from '@mui/joy/Button';
import moment from 'moment'
function YearChart() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const { data: data, refetch, isLoading, isFetching } = useYearsCountQuery({
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const { data: failedData } = useYearsCountFailedQuery({
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })


  const countsfailed = failedData?.weeklyData?.map(item => item.totalOrders);
  const weeks = data?.weeklyData?.map(item => item.day);
  const counts = data?.weeklyData?.map(item => item.totalOrders);

  const getMonthName = (monthNumber) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
      'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    return monthNames[monthNumber - 1];
  };

  const monthNumber = data?.month; 
  const monthName = getMonthName(monthNumber);

  const EmptyWeekCount = [0, 0, 0, 0, 0, 0, 0]
  const EmptyWeekName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  function showThisChart() {
    return (
      <BarChart
        series={[
          { data: counts || EmptyWeekCount, color: '#a5d0d6', label: 'Success' },
          { data: countsfailed || EmptyWeekCount, color: '#e5aa8c', label: 'Failed' },
          { data: EmptyWeekCount, color: '#f8bb087d', label: 'Shipments' },
        ]}
        height={190}
        xAxis={[{ data: weeks || EmptyWeekName, scaleType: 'band' }]}
        margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
      />
    );
  }


  return (
    <>
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12 list-card-op" style={{ position: 'relative' }}>
          <div className='db-card-count' style={{ fontWeight: 600, fontSize: '1.2rem' }}>
            {!isLoading &&
              <Button
                disabled
                size='sm'
                variant='soft'
                style={{ fontWeight: 400, minHeight: '30px', maxHeight: '30px', display: 'flex', alignItems: 'center', textTransform: 'uppercase', color: '#5e6f82', background: '#fff0' }}
              >
                <span style={{ marginRight: '5px' }}>{moment(data?.weekStart).format('ll')} TO {moment(data?.weekEnd).format('ll')}</span>
                <CalendarMonthOutlinedIcon style={{ fontSize: '1.1rem', position: 'relative', top: '-0.5px' }} />
              </Button>
            }
          </div>
        </div>
        <div className="col-md-12 list-card-op" style={{ position: 'relative' }}>
          <div className="db-section-card" style={{ height: 'auto', border: 'none', padding: 0, margin: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {showThisChart()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default YearChart;
