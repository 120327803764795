import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/joy/Checkbox';

function OrderCancel({ getEditShow, handleCloseEdit, orderData, upResult, userId, userToken, updateOrder, shippingName, shippingEmail, getOrderUpdateActionType }) {
  const [getComment, setComment] = useState('');

  let itemImages = []
  if (orderData?.order_items) {
    orderData?.order_items?.map((item, i) =>
      itemImages.push(item.image)
    );
  }

  const [getEmailSend, setEmailSend] = useState(false);
  const handleChange = (data) => {
    setEmailSend(data);
  };

  return (
    <>

      <Modal className="cancel-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>

        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
              Reason for order {getOrderUpdateActionType?.replace('_', ' ')}
            </div>
            <IconButton
              variant='outlined'
              size='sm'
              color='danger'
              style={{ minHeight: '27px', maxHeight: '27px' }}
              onClick={handleCloseEdit}
            >
              <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0px 10px 0px' }}>
                  <Checkbox
                    className='update-check'
                    size="md"
                    checked={getEmailSend}
                    onChange={((event) => handleChange(event.target.checked))}
                    label="Send Email"
                  />
                </div>
                <div className='form-group'>
                  <label>Comment <small>{`(optional)`}</small></label>
                  <textarea className='form-control' required onChange={((e) => setComment(e.target.value))}></textarea>
                </div>
                <div className='form-group'>
                  <Button
                    disabled={upResult?.isLoading}
                    onClick={(() => updateOrder({
                      name: shippingName,
                      email: shippingEmail,
                      email_send: getEmailSend,
                      domain: orderData?.domain,
                      order_number: orderData?.order_number,
                      images: itemImages && itemImages,
                      id: orderData?.id,
                      state: getOrderUpdateActionType,
                      userId: userId,
                      userToken: userToken,
                      comment: getComment
                    })
                    )}
                    style={{ width: '100%', fontWeight: 500 }}
                    variant="soft"
                    size="lg"
                  >
                    {upResult?.isLoading ? 'updating...' : 'Update'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrderCancel;
