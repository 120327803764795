import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useForm } from "react-hook-form";

function CreateManifest({ createManifest, orderData, viewmanifest, userId, userToken, cityCode, getDestination, getItemChecked }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getCustomsValue, setCustomsValue] = useState(null);
  const [getPackageWeight, setPackageWeight] = useState(null);
  const [getPackages, setPackages] = useState(null);
  const [getItemNotSelectedMessage, setItemNotSelectedMessage] = useState('');

  const [isModal, setModal] = useState(false);
  const openModal = () => {
    setModal(true)
  };
  const closeModal = () => {
    setModal(false)
  };



  function createManifestFunc(data) {
    if (getItemChecked?.length === 0) {
      setItemNotSelectedMessage('Item not selected')
    } else {
      setModal(false)
      const domainName = orderData?.domain?.split('.')
      createManifest({
        order_number: orderData?.order_number,
        country: orderData?.country,
        city_code: cityCode,
        id: orderData?.id,
        name: 'flipexp',
        destination: getDestination,
        userId: userId,
        userToken: userToken,
        packages: data.packages,
        currency: orderData?.currency,
        customs_value: data.customs_value,
        package_weight: data.package_weight,
        items_data: getItemChecked,
        domain_name: domainName[1]
      })
    }
  }

  return (
    <>
      <Modal className="cancel-modal" show={isModal} onHide={closeModal} centered animation={false}>

        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
              Create manifest
            </div>
            <IconButton
              variant='outlined'
              size='sm'
              color='danger'
              style={{ minHeight: '27px', maxHeight: '27px' }}
              onClick={closeModal}
            >
              <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Modal.Header>

        <Modal.Body>
          {getItemNotSelectedMessage &&
            <div className="col-md-12 alert alert-danger" style={{ textAlign: 'center' }}>
              <small>{getItemNotSelectedMessage}</small>
            </div>
          }
          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                <form onSubmit={handleSubmit(createManifestFunc)}>
                  <div className='form-group'>
                    <label>Packages <small>(count)</small></label>
                    <input type='number' className='form-control'
                      // onChange={((e) => setPackages(e.target.value))}
                      id="packages"
                      aria-invalid={errors.packages ? "true" : "false"}
                      {...register('packages', { required: false })}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Package Customs Value <small>({orderData?.currency})</small></label>
                    <input type='number' className='form-control'
                      // onChange={((e) => setCustomsValue(e.target.value))}
                      id="customs_value"
                      aria-invalid={errors.customs_value ? "true" : "false"}
                      {...register('customs_value', { required: false })}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Package Weight <small>(in grams only)</small></label>
                    <input type='number' className='form-control'
                      // onChange={((e) => setPackageWeight(e.target.value))}
                      id="package_weight"
                      aria-invalid={errors.package_weight ? "true" : "false"}
                      {...register('package_weight', { required: false })}
                    />
                  </div>
                  <div className='form-group'>
                    <Button
                      // onClick={(() => createManifestFunc())}
                      style={{ fontWeight: 500, width: '100%' }}
                      variant="soft"
                      size="lg"
                      type='submit'
                    >
                      Create
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Dropdown>
        <MenuButton
          disabled={!orderData?.status}
          color='warning'
          style={{ padding: '0px 8px', fontSize: '12px', fontWeight: 400, minHeight: '30px' }}
          className="Header-button-connect">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Manifest</span>
            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
          </div>
        </MenuButton>
        <Menu>
          <MenuItem style={{ paddingBottom: '10px' }} onClick={(() => openModal())}>
            <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>Create FlipEx Manifest</div>
          </MenuItem>
        </Menu>
      </Dropdown>


    </>
  )

}

export default CreateManifest;
