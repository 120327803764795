import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Users from './components/Users';
import Login from './Pages/Login';
import Countries from './components/Countries';
import Admin from './Private/admin';
import Media from './components/Media';
import Team from "./components/Team";
import Boxes from "./components/Boxes";
import Box from "./components/Box";
import GoogleMerchant from "./components/GoogleMerchant";
import GoogleMerchants from "./components/GoogleMerchants";
import ProductsImport from "./components/ProductsImport";
import ProductsAction from "./components/ProductsAction";
import OrdersAction from "./components/OrdersAction";
import Products from "./Pages/Products";
import Product from "./components/Product";
import Orders from "./Pages/Orders";
import Order from "./components/Order";
import OrderItems from "./Pages/OrderItems";
import UserOrders from "./components/UserOrders";
import Stores from "./components/Stores";
import Coupons from "./components/Coupons";
import Store from "./components/Store";
import Refund from "./components/Refund";
import Refunds from "./components/Refunds";
import Inventory from "./Pages/Inventory";
import ManageKeys from "./Pages/ManageKeys";
import Shipments from "./Pages/Shipments";
import GenerateCSV from "./components/GenerateCSV";
import GenerateCSVAll from "./components/GenerateCSVAll";
import OrdersSearch from "./components/OrdersSearch";
import ProductsSearch from "./components/ProductsSearch";
import Consolidation from "./Pages/Consolidation";
import CreateOrder from "./Pages/CreateOrder";
import Imports from "./components/Imports";
import Reports from "./components/Reports";
import NotFound from './NotFound';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Switch> */}
          {/* <Route exact path="/">
            <Admin component={Dashboard} />
          /> */}
          <Route index element={<Admin component={Dashboard} />} />
          <Route exact path="/items" element={<Admin component={OrderItems} />} />
          <Route exact path="/imports" element={<Admin component={Imports} />} />
          <Route exact path="/reports" element={<Admin component={Reports} />} />
          <Route exact path="/consolidation" element={<Admin component={Consolidation} />} />
          <Route exact path="/shipments" element={<Admin component={Shipments} />} />
          <Route exact path="/create" element={<Admin component={CreateOrder} />} />
          <Route exact path="/refunds"
            element={<Admin component={Refunds} />}
          />
          <Route exact path="/inventory"
            element={<Admin component={Inventory} />}
          />
          <Route exact path="/boxes"
            element={<Admin component={Boxes} />}
          />
          <Route exact path="/box/:id/:number"
            element={<Admin component={Box} />}
          />
          <Route exact path="/manage-keys"
            element={<Admin component={ManageKeys} />}
          />
          <Route exact path="/stores"
            element={<Admin component={Stores} />}
          />
          <Route exact path="/coupons"
            element={<Admin component={Coupons} />}
          />
          <Route exact path="/store/:id"
            element={<Admin component={Store} />}
          />
          <Route exact path="/store/:storeId/:import"
            element={<Admin component={ProductsImport} />}
          />
          <Route exact path="/google-merchant/:action/:merchant_id"
            element={<Admin component={ProductsAction} />}
          />
          <Route exact path="/refund/:action"
            element={<Admin component={OrdersAction} />}
          />
          <Route exact path="/products"
            element={<Admin component={Products} />}
          />
          <Route exact path="/generate-csv"
            element={<Admin component={GenerateCSV} />}
          />
          <Route exact path="/generate-csv-all"
            element={<Admin component={GenerateCSVAll} />}
          />
          <Route exact path="/google-merchant/:merchant_id"
            element={<Admin component={GoogleMerchant} />}
          />
          <Route exact path="/google-merchants"
            element={<Admin component={GoogleMerchants} />}
          />
          <Route exact path="/product/:id"
            element={<Admin component={Product} />}
          />
          <Route exact path="/orders/search/:id"
            element={<Admin component={OrdersSearch} />}
          />
          <Route exact path="/products/search/:id"
            element={<Admin component={ProductsSearch} />}
          />
          <Route exact path="/orders"
            element={<Admin component={Orders} />}
          />
          <Route exact path="/orders/user/:id"
            element={<Admin component={UserOrders} />}
          />
          <Route exact path="/order/:id"
            element={<Admin component={Order} />}
          />
          <Route exact path="/refund/:id"
            element={<Admin component={Refund} />}
          />
          <Route exact path="/media"
            element={<Admin component={Media} />}
          />
          <Route exact path="/countries/:route"
            element={<Admin component={Countries} />}
          />
          <Route exact path="/users"
            element={<Admin component={Users} />}
          />
          <Route exact path="/team"
            element={<Admin component={Team} />}
          />
          <Route exact path="/login"
            element={<Login />}
          />
          <Route path="*"
            element={<Admin component={NotFound} />}
          />
          {/* </Switch> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
