import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


import { createEntityAdapter } from '@reduxjs/toolkit'
import io from "socket.io-client";
const messagesAdapter = createEntityAdapter()
const access = "full_access"

export const actionApi = createApi({

  reducerPath: 'actionApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Action'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({
 
    importFile: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("myFile", data.image_file);
        return {
          url: `action/products`,
          method: 'POST',
          body: formData,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            // 'Content-Type': 'multipart/form-data',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'X-Merchant-Id': data.merchant_id
          }
        }
      },
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['Action'],
    }),

    fetchStoreProductsCashed: builder.query({
      query: (data) => ({
        url: `action/products/${data.id}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      keepUnusedDataFor: 86400000,
      providesTags: ['Action'],
    }),


    importFileOrders: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("myFile", data.image_file);
        return {
          url: `action/orders`,
          method: 'POST',
          body: formData,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            // 'Content-Type': 'multipart/form-data',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'X-Merchant-Id': data.merchant_id
          }
        }
      },
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['Action'],
    }),




    fetchActionOrdersCashed: builder.query({
      query: (data) => ({
        url: `action/orders`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      
      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
            shipping_status: arg.shipping_status
          }
          const listener = (event) => {
            // if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return
            const values = event.email?.split(',');
            const localDomain = localStorage.getItem('domain')
            const adminDomain = process.env.REACT_APP_API_DOMAIN

            // if (event.adminAccess && !JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
            //   if (values?.includes(arg.userEmail)) {
            //     updateCachedData((draft) => {
            //       messagesAdapter.upsertOne(draft, event?.domain === localDomain ? event.data : '')
            //     })
            //   }
            // }
            if (!event.adminAccess && JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, event?.data)
              })
            }

          }
          socket.addEventListener('ordersactioncsv', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },

      // keepUnusedDataFor: 1,
      providesTags: ['Media'],
    }),





  }),

})

export const { useFetchStoreProductsCashedQuery, useImportFileMutation, useImportFileOrdersMutation, useFetchActionOrdersCashedQuery } = actionApi