import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { Spinner, Modal } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetStoresQuery, useCreateStoreMutation } from '../../services/box';
import Moment from 'moment';
import Button from '@mui/joy/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Tag } from 'antd';

function Boxes() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')


  const storesInfo = useGetStoresQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const stores = storesInfo?.data?.boxes;
  const pagination = storesInfo?.data?.pagination;


  const [createUser, createresult] = useCreateStoreMutation()

  function createUserFunc(data) {
    createUser({
      name: data.name,
      box_number: data.box_number,
      userId: userId,
      userToken: userToken,
    })
  }

  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }

  }, [createresult]);

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards">

      <Modal className="user-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeNewModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Create new box</h5>
            </div>
          </div>

          <form onSubmit={handleSubmit(createUserFunc)}>
            {createresult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                <span className="alert alert-danger">{createresult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control"
                    id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Box Number</label>
                  <input type="text" className="form-control"
                    id="box_number"
                    aria-invalid={errors.box_number ? "true" : "false"}
                    {...register('box_number', { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    style={{ fontWeight: 400 }}
                    disabled={createresult.isLoading ? true : false}
                    variant="soft"
                    size="sm"
                    type="submit">
                    {createresult.isLoading ? 'Loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    disabled={storesInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                  <input style={{ borderRadius: '100px' }} className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                  <IconButton
                    disabled
                    size='sm'
                    style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                    className="table_search_icon">
                    <SearchIcon style={{ fontSize: 'inherit' }} />
                  </IconButton>
                </div>
              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    className='pg-button-hp'
                    style={{ fontWeight: 400 }}
                    variant="soft"
                    size="sm"
                    onClick={() => ebtidaEModal()}>
                    <span style={{ marginRight: '5px' }}>Add New</span>
                    <AddCircleOutlinedIcon style={{ fontSize: '1.1rem', }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(storesInfo?.isLoading, storesInfo?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Box Number</th>
                  {/* <th>Name</th> */}
                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                    Update on
                  </th>
                </tr>
              </thead>

              <tbody>
                {stores?.map((item, i) =>
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>
                      <Link to={`/box/${item.id}/${item.box_number}`}>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 0, background: '#fff0', border: 'none', margin: 0 }} className='alert alert-info'>
                          <span className='badge badge-info' style={{ marginRight: '10px', minWidth: '20px' }}>{item.box_number}</span>
                          <div>{item.name}</div>
                        </div>
                      </Link>
                    </td>
                    {/* <td>
                      <Link to={`/box/${item.id}/${item.box_number}`}>
                        <Tag>{item.name}</Tag>
                      </Link>
                    </td> */}
                    <td className="sticky-column" style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                      </div>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>
          {stores?.length === 0 ?
            <h5 className="data_not-found">No data available in table</h5>
            : null}
          {storesInfo?.error?.originalStatus === 403 &&
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{storesInfo?.error?.data}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Boxes;
