import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit'
import io from "socket.io-client";

const messagesAdapter = createEntityAdapter()

const access = "full_access"

export const reportsApi = createApi({

  reducerPath: 'reportsApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Reports'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({

    getDeliveryReport: builder.query({
      query: (data) => ({
        url: `reports?page=${data.page}&size=${data.size}${data.search}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }),

      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
          }
          const listener = (event) => {
            if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return
            const isPartner = localStorage.getItem('isPartner')
            const userId = localStorage.getItem('userId')
            if (JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, event?.adminData)
              })
            } else {
              updateCachedData((draft) => {
                messagesAdapter.upsertOne(draft, '')
              })
            }
          }
          socket.addEventListener('reports_list', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Reports'],
    }),





    createDeliveryReport: builder.mutation({
      query: (data) => {
        return {
          url: `reports/${data.id}`,
          method: 'POST',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Reports'],
    }),



  }),

})

export const { useGetDeliveryReportQuery, useCreateDeliveryReportMutation } = reportsApi