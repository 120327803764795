import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import { useSelector } from 'react-redux'
import { useCreateDeliveryReportMutation } from '../../services/reports';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import { notification } from 'antd';
import "antd/dist/antd.min.css";

function Action({ getNewModal, closeNewModal, getUserUpdateModal, closeUserUpdateModal, getUserUpdateData }) {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, cCode } = useSelector((state) => state.auth);
  const { register, setValue, handleSubmit, formState: { errors }, reset } = useForm();
  const [isStartDate, setStartDate] = useState();
  const [isEndDate, setEndDate] = useState();
  const [getDays, setDays] = useState();
  const [createUser, createresult] = useCreateDeliveryReportMutation()
  function createUserFunction(data) {
    createUser({
      days: getDays,
      id: userId,
      userToken: userToken,
      userId: userId,
      userRole: userRole
    })
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      closeNewModal()
      reset()
    }
  }, [createresult]);

  const [api, contextHolder] = notification.useNotification();
  React.useEffect(() => {
    if (createresult?.isSuccess) {
      api['success']({
        message: 'successfully created',
        showProgress: true,
        pauseOnHover: false,
        duration: 3
      });
    }
    if (createresult?.error) {
      api['error']({
        message: createresult?.error?.data?.error,
        showProgress: true,
        pauseOnHover: false,
        duration: 3
      });
    }
  }, [createresult]);

  function buttonAction(loading, days) {
    if (loading) {
      return true
    } else if (!days) {
      return true
    } else {
      return false
    }
  }

  const reportDays = [2, 3, 4, 5, 7, 15, 30, 60, 90]

  return (
    <>
      {contextHolder}
      <Modal className="shipment-import-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        <Modal.Body>
          <div style={{ padding: '10px 20px 10px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #eee' }}>
            <div style={{ textAlign: 'start', fontSize: '1rem', fontWeight: 500 }}>
              Create Delivery Report
            </div>
            <IconButton
              variant='soft'
              size='sm'
              onClick={closeNewModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
          </div>
          <div style={{ padding: '10px 20px 10px 20px' }}>
            <form onSubmit={handleSubmit(createUserFunction)}>
              {createresult?.error?.data?.error &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', fontWeight: 400, textAlign: 'center' }}>
                  <span className="col-md-12 alert alert-danger">{createresult?.error?.data?.error}</span>
                </div>
              }
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-md-12">
                  <label>Select Days</label>
                  <div className="form-group">
                    <select className='form-control' onChange={(e) => setDays(e.target.value)}>
                      <option selected disabled value={''}>Select</option>
                      {reportDays?.map((d) =>
                        <option value={d}>{d} Days</option>
                      )}
                    </select>
                  </div>
                </div>
                <div
                  style={{ position: 'sticky', bottom: 0, padding: '10px 15px 10px 15px', background: '#fcfcfc', width: '100%', borderTop: '1px solid #eee', textAlign: 'center' }}>
                  <Button
                    style={{ fontWeight: 400, width: '100%' }}
                    disabled={buttonAction(createresult.isLoading, getDays)}
                    variant="soft"
                    size="lg"
                    type="submit">
                    {createresult.isLoading ? 'Creating...' : 'Generate'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Action;
