import React from 'react';
import ConsolidationComponent from '../../components/ConsolidationComponent';

function Consolidation() {
  return (
    <div className="tabel_cards">
      <ConsolidationComponent />
    </div>

  );
}

export default Consolidation;
