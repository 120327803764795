import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const IndexApi = createApi({

  reducerPath: 'IndexApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Index'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({

    yearsCount: builder.query({
      query: (data) => {
        return {
          url: `index/count/years`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),

    yearsCountFailed: builder.query({
      query: (data) => {
        return {
          url: `index/count/years/failed`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),

    ordersCount: builder.query({
      query: (data) => {
        return {
          url: `index/count/orders`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),

    ordersFailedCount: builder.query({
      query: (data) => {
        return {
          url: `index/count/failed`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),

    ordersSuccessCount: builder.query({
      query: (data) => {
        return {
          url: `index/count/success`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),


    indexCountDelivered: builder.query({
      query: (data) => {
        return {
          url: `index/count/delivered`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),

    indexCountPending: builder.query({
      query: (data) => {
        return {
          url: `index/count/pending`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Index'],
    }),

    resetYears: builder.mutation({
      query: (data) => {
        return {
          url: `index/reset/${data.key}/month`,
          method: 'POST',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Index'],
    }),

    
  }),

})

export const { useYearsCountQuery, useYearsCountFailedQuery, useOrdersCountQuery, useOrdersSuccessCountQuery, useOrdersFailedCountQuery, useIndexCountDeliveredQuery, useIndexCountPendingQuery, useResetYearsMutation } = IndexApi