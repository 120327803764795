import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Countries from '../../Helper/Countries';
import Checkbox from '@mui/joy/Checkbox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography from '@mui/joy/Typography';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FormControlLabel from '@mui/material/FormControlLabel';
import ViewManifest from './ViewManifest';
import bwipjs from "bwip-js";
import { useDropzone } from 'react-dropzone';
import { useUpdateMediaMutation } from '../../services/media';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Loader from '../../Helper/Loader';
import FilePresentIcon from '@mui/icons-material/FilePresent';

var moment = require('moment');
const itemGroupStates = ['processing', 'dispatched', 'out_for_delivery', 'delivered']

function ItemList({ indexNumber, item, orderData, updateItemState, itemShippingStatus, updateDeliveryDays, updateItemPopup, itemWeightModal, userId, userToken, shippingName, shippingEmail, uitemresult, getEmailSend, removeFromInventory, setItemChecked, getItemChecked, id, viewmanifest, extraUpdateModal, handleChange, itemQuantityModal }) {
  const componentRef = React.useRef();


  useEffect(() => {
    setItemChecked([]);
  }, [orderData?.number, id]);
  const handleAdminAccess = (event) => {
    const { checked, id, price, title, country } = event;
    const thisdata = {
      id: Number(id),
      price: Number(price),
      title,
      country
    };
    if (checked) {
      setItemChecked(prevState => {
        if (prevState.some(item => item.id === thisdata.id)) {
          return prevState;
        }
        return [...prevState, thisdata];
      });
    } else {
      setItemChecked(prevState => prevState.filter(item => item.id !== thisdata.id));
    }
  };

  function findId(id) {
    const value = getItemChecked?.find((item) => item.id === id)
    return value ? true : false
  }

  function oiBgColor(data) {
    if (data === 'approved') {
      return 'badge badge-success'
    } else if (data === 'cancelled') {
      return 'badge badge-danger'
    } else if (data === 'accepted') {
      return 'badge badge-success'
    } else if (data === 'highlight') {
      return 'badge badge-info'
    } else if (data === 'requires_attention') {
      return 'badge badge-dark'
    } else {
      return 'badge badge-warning'
    }
  }

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  const ComponentToPrint = React.forwardRef(({ value, orderId, title }, ref) => {
    const canvasRef = React.useRef();
    React.useEffect(() => {
      if (canvasRef.current) {
        try {
          bwipjs.toCanvas(canvasRef.current, {
            bcid: "code128",
            text: `${value}`,
            scale: 3,
            height: 8,
            includetext: true,
            textxalign: "center",
            textsize: 6,
            includetext: false
          });
        } catch (err) {
          console.error("Barcode generation failed:", err);
        }
      }
    }, [value]);
    return (
      <div className='print-only'>
        <div ref={ref} className="react-barcode" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '12px' }}>{orderId} - {value}</div>
            <canvas ref={canvasRef}></canvas>
            <div className='react-barcode-title'>{title?.substring(0, 34)?.replace(/-/g, ' ')}...</div>
          </div>
        </div>
      </div>
    );
  });

  function getCountry(code) {
    const country = Countries?.find((c) => c.code === code)
    return country?.label
  }

  function getPadding(state) {
    if (state === 'processing') {
      return false
    } else if (state === 'shipping') {
      return false
    } else if (state === 'procuring') {
      return false
    } else if (state === 'delivered') {
      return false
    } else {
      return true
    }
  }

  function shippingUpdatedData(shipping_status) {
    return {
      name: shippingName,
      email: shippingEmail,
      email_send: getEmailSend,
      domain: orderData?.domain,
      order_number: orderData?.order_number,
      shipping_status: shipping_status,
      shipping: true,
      itemId: item.id,
      orderId: orderData?.id,
      userToken: userToken,
      userId: userId,
      image: item.image
    }
  }

  function getShippingStep(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else if (thisData.includes('out_for_delivery')) {
      return true
    } else {
      return false
    }
  }

  const [isTrackFrom, setTrackingForm] = useState(false)
  const [isTrackId, setTrackingId] = useState('')

  useEffect(() => {
    if (uitemresult?.isSuccess) {
      setTrackingForm(false)
      setTrackingId('')
    }

  }, [uitemresult]);

  function tableBgColor(state, sstate, ostate) {
    if (ostate === 'cancelled') return '#cd201f05'
    if (ostate === 'fraud') return '#cd201f05'
    if (state === 'cancelled') return '#cd201f05'
    if (sstate === 'processing') {
      return '#17a2b80f'
    } else if (sstate === 'delivered') {
      return '#00800108'
    } else {
      return '#ffa5000a'
    }
  }



  const FileUpload = ({ itemId, sInvoice }) => {

    const [updateImage, imageresult] = useUpdateMediaMutation()
    const [getUploadedFile, setUploadedFile] = useState('')

    useEffect(() => {
      if (imageresult?.isSuccess) {
        setUploadedFile(imageresult?.data?.file)
      }

    }, [imageresult]);

    const onDrop = React.useCallback(async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      updateImage({
        acceptedFiles: acceptedFiles,
        itemId: itemId,
        userId: userId,
        userToken: userToken,
      })
    }, [itemId]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <>
        {getUploadedFile ?
          <a title='Attached Invoice' href={`https://m.ebtida.com/invoices/${getUploadedFile}`} target="_blank" style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
            <IconButton variant='outlined' size='sm' style={{ minHeight: '25px', }}>
              <FilePresentIcon style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </a> :
          sInvoice ?
            <a title='Attached Invoice' href={`https://m.ebtida.com/invoices/${sInvoice}`} target="_blank" style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
              <IconButton variant='outlined' size='sm' style={{ minHeight: '25px', }}>
                <FilePresentIcon style={{ fontSize: '1.2rem' }} />
              </IconButton>
            </a> : ''
        }


        <IconButton title='Attach Invoice' variant='outlined' size='sm'
          {...getRootProps()}
          style={{
            border: '1px dashed #007BFF',
            textAlign: 'center',
            cursor: 'pointer',
            minHeight: '25px',
          }}
        >
          {imageresult?.isLoading ? <Loader /> :
            <>
              <input {...getInputProps()} />
              <AttachFileIcon style={{ fontSize: '1.2rem' }} />
            </>
          }
        </IconButton>
      </>
    )
  }



  function getItemStatus(status) {
    let name;
    let classname;
    if (status === 1) {
      name = 'Good'
      classname = 'badge badge-success'
    } else if (status === 2) {
      name = 'Damaged'
      classname = 'badge badge-danger'
    } else if (status === 3) {
      name = 'Wrong'
      classname = 'badge badge-danger'
    } else {
      name = 'Pending'
      classname = 'badge badge-warning'
    }
    return { name, classname }
  }


  return (
    <div
      style={{
        marginBottom: '10px',
        border: '1px solid #eee',
        padding: '10px',
        borderRadius: '10px',
        position: 'relative',
        background: tableBgColor(item.state, item.shipping_state, orderData?.states)
      }}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', flexWrap: 'wrap' }}>

        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

          <Dropdown>
            <MenuButton
              disabled={!orderData?.status}
              className='m-margin-0'
              style={{ minHeight: '25px', maxHeight: '25px', fontWeight: 400, marginRight: '10px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <small>Shipping</small>
                <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
              </div>
            </MenuButton>
            <Menu className='listUpdate-card'>
              <div style={{ padding: '10px 15px 10px 15px' }}>
                <Checkbox
                  className='update-check update-check-o'
                  size="md"
                  checked={getEmailSend}
                  onChange={((event) => handleChange(event.target.checked))}
                  label="Send Email on shipping states update"
                />
              </div>

              {itemGroupStates?.map((state, i) =>
                <>

                  {state === 'dispatched' &&
                    <MenuItem disabled>Procuring</MenuItem>
                  }
                  <div style={{ paddingLeft: getPadding(state) ? '20px' : '0px' }}>
                    <MenuItem
                      disabled={item.shipping_status?.split(',')?.includes(state)}
                      onClick={(() => {
                        if (window.confirm('Are you sure to change?'))
                          itemShippingStatus(shippingUpdatedData(state))
                      })}
                      key={i}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          disabled={item.shipping_status?.split(',')?.includes(state)}
                          size={getPadding(state) ? 'sm' : 'md'}
                          checked={item.shipping_status?.split(',')?.includes(state)} />
                        <div style={{ margin: '0px 10px' }}>{state?.replace(/_/g, ' ')}</div>
                      </div>
                    </MenuItem>
                  </div>

                  {state === 'dispatched' &&
                    <>
                      <MenuItem disabled>Shipping</MenuItem>
                      <div style={{ paddingLeft: '20px' }}>
                        {item.country !== 'AE' &&
                          <>
                            <MenuItem
                              disabled={item.shipping_status?.split(',')?.includes(`arrived_in_${item.country}`)}
                              onClick={(() => {
                                if (window.confirm('Are you sure to change?'))
                                  itemShippingStatus(shippingUpdatedData(`arrived_in_${item.country}`))
                              })}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  disabled={item.shipping_status?.split(',')?.includes(`arrived_in_${item.country}`)}
                                  size='sm'
                                  checked={item.shipping_status?.split(',')?.includes(`arrived_in_${item.country}`)} />
                                <span style={{ margin: '0px 10px' }}>Arrived in {getCountry(item.country)}</span>
                              </div>
                            </MenuItem>

                            <MenuItem
                              disabled={item.shipping_status?.split(',')?.includes(`departed_from_${item.country}`)}
                              onClick={(() => {
                                if (window.confirm('Are you sure to change?'))
                                  itemShippingStatus(shippingUpdatedData(`departed_from_${item.country}`))
                              })}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  disabled={item.shipping_status?.split(',')?.includes(`departed_from_${item.country}`)}
                                  size='sm' checked={item.shipping_status?.split(',')?.includes(`departed_from_${item.country}`)} />
                                <span style={{ margin: '0px 10px' }}>Departed from {getCountry(item.country)}</span>
                              </div>
                            </MenuItem>
                          </>
                        }

                        <MenuItem
                          disabled={item.shipping_status?.split(',')?.includes(`arrived_in_uae`)}
                          onClick={(() => {
                            if (window.confirm('Are you sure to change?'))
                              itemShippingStatus(shippingUpdatedData(`arrived_in_uae`))
                          })}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              disabled={item.shipping_status?.split(',')?.includes(`arrived_in_uae`)}
                              size='sm' checked={item.shipping_status?.split(',')?.includes(`arrived_in_uae`)} />
                            <span style={{ margin: '0px 10px' }}>Arrived in UAE</span>
                          </div>
                        </MenuItem>
                        <MenuItem
                          disabled={item.shipping_status?.split(',')?.includes(`departed_from_uae`)}
                          onClick={(() => {
                            if (window.confirm('Are you sure to change?'))
                              itemShippingStatus(shippingUpdatedData(`departed_from_uae`))
                          })}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              disabled={item.shipping_status?.split(',')?.includes(`departed_from_uae`)}
                              size='sm' checked={item.shipping_status?.split(',')?.includes(`departed_from_uae`)} />
                            <span style={{ margin: '0px 10px' }}>Departed from UAE</span>
                          </div>
                        </MenuItem>
                      </div>
                    </>
                  }

                </>
              )}
              <MenuItem
                disabled={item.shipping_state === `processing`}
                onClick={(() => {
                  if (window.confirm('Are you sure to change?'))
                    itemShippingStatus({
                      shipping_status: 'processing',
                      shipping_reset: true,
                      itemId: item.id,
                      orderId: orderData?.id,
                      userToken: userToken,
                      userId: userId
                    })
                })}
              >
                <span>RESET</span>
              </MenuItem>
            </Menu>
          </Dropdown>

          <div style={{ whiteSpace: 'normal' }}>
            <ViewManifest item={item} viewmanifest={viewmanifest} userId={userId} userToken={userToken} orderData={orderData} />
          </div>
        </div>
        {item.delivered_on &&
          <>
            {item.shipping_state === 'delivered' &&
              <div style={{ fontSize: '10px', whiteSpace: 'normal' }} className='badge badge-success'>
                Delivered:&nbsp;{moment(item.delivered_on).format('Do MMM YY HH:mm')}
              </div>
            }
          </>
        }
      </div>
      <div>
        <Stepper
          size="md"
          className="step-card-list"
          sx={{
            width: '100%',
            padding: '4px',
            marginBottom: '5px',
            borderRadius: '6px',
            '--StepIndicator-size': '1.3rem',
            '--Step-connectorInset': '0px',
            [`& .${stepIndicatorClasses.root}`]: {
              borderWidth: 2,
            },
            [`& .${stepClasses.root}::after`]: {
              height: '1.5px',
            },
            [`& .${stepClasses.completed}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                borderColor: 'primary.300',
                // color: 'primary.300',
              },
              '&::after': {
                bgcolor: 'primary.300',
              },
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                borderColor: 'currentColor'
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.outlinedDisabledColor',
            },
          }}
        >
          <Step
            className="step-item-list"
            active={item.shipping_state === 'processing'}
            completed={item.shipping_status?.split(',')?.includes('dispatched')}
            // orientation="vertical"
            indicator={
              <StepIndicator
                variant={item.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'outlined'}
                color={item.shipping_status?.split(',')?.includes('dispatched') ? 'success' : item.shipping_state === 'processing' ? 'primary' : 'neutral'}>

                {item.shipping_status?.split(',')?.includes('dispatched') ?
                  <CheckCircleRoundedIcon style={{ fontSize: '13px' }} /> :
                  <FiberManualRecordIcon className='checkthiss' style={{ fontSize: '13px' }} />
                }
              </StepIndicator>
            }
          >
            <Typography
              style={{ gap: '1px' }}
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '0.65rem',
                letterSpacing: '0.5px',
                gap: '2px',
                margin: '0px 5px',
                marginRight: '10px'
              }}
            >
              Processing
            </Typography>
          </Step>


          <Step
            className="step-item-list"
            active={item.shipping_state === 'dispatched'}
            completed={getShippingStep(item.shipping_status)}
            // orientation="vertical"
            indicator={
              <StepIndicator
                variant={getShippingStep(item.shipping_status) ? 'solid' : 'outlined'}
                color={getShippingStep(item.shipping_status) ? 'success' : item.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                {getShippingStep(item.shipping_status) ?
                  <LocalMallIcon style={{ fontSize: '13px' }} /> :
                  <LocalMallIcon style={{ fontSize: '13px' }} />
                }
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '0.65rem',
                letterSpacing: '0.5px',
                margin: '0px 5px',
                marginRight: '10px'
              }}
            >
              Procuring
            </Typography>
          </Step>

          <Step
            className="step-item-list"
            active={getShippingStep(item.shipping_status)}
            completed={item.shipping_status?.split(',')?.includes('delivered')}
            // orientation="vertical"
            indicator={
              <StepIndicator
                variant={item.shipping_status?.split(',')?.includes('delivered') ? 'solid' : 'outlined'}
                color={item.shipping_status?.split(',')?.includes('delivered') ? 'success' : getShippingStep(item.shipping_status) ? 'primary' : 'neutral'}>
                {item.shipping_status?.split(',')?.includes('delivered') ?
                  <LocalShippingRoundedIcon style={{ fontSize: '13px' }} /> :
                  <LocalShippingRoundedIcon style={{ fontSize: '13px' }} />
                }
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '0.65rem',
                letterSpacing: '0.5px',
                margin: '0px 5px',
                marginRight: '10px'
              }}
            >
              Shipping
            </Typography>
          </Step>


          <Step
            className="step-item-list"
            active={item.shipping_status?.split(',')?.includes('delivered')}
            completed={item.shipping_status?.split(',')?.includes('delivered')}
            // orientation="vertical"
            indicator={
              <StepIndicator
                variant={item.shipping_status?.split(',')?.includes('delivered') ? 'solid' : 'outlined'}
                color={item.shipping_status?.split(',')?.includes('delivered') ? 'success' : 'neutral'}>
                {item.shipping_status?.split(',')?.includes('delivered') ?
                  <CheckCircleRoundedIcon style={{ fontSize: '13px' }} /> :
                  <CheckCircleRoundedIcon style={{ fontSize: '13px' }} />
                }
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: '0.65rem',
                letterSpacing: '0.5px',
                margin: '0px 3px'
              }}
            >
              Delivered
            </Typography>
          </Step>

        </Stepper>
      </div>

      <div style={{ background: '#fcfcfc', padding: '10px', border: '1px solid #e4e4e4', borderRadius: '7px' }}>

        <div className='f-flex-wrap' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', borderBottom: '0.5px solid #d7d7d7', marginBottom: '10px', paddingBottom: '5px' }}>
          <div className='f-flex-wrap' style={{ display: 'flex', alignItems: 'center' }}>

            <div style={{ marginRight: '10px' }}>
              <FormControlLabel
                className='checkbox-label'
                style={{ margin: '0px 0px', flexDirection: 'row-reverse' }}
                disabled={getItemChecked?.length > 0 && getItemChecked?.includes('full_access')}
                onChange={((event) => handleAdminAccess({
                  checked: event.target.checked,
                  title: item.title,
                  price: item.price,
                  id: item.id,
                  country: item.country,
                  shipping_status: item.shipping_status
                }))}
                label={<div style={{ fontSize: '1.4rem', marginRight: '10px', marginTop: '1.5px', fontWeight: 300 }}>{indexNumber}.</div>}
                control={<Checkbox checked={findId(item.id)} className='item-checkbox' />}
              />
            </div>

            {/* {item.inventory ?
            <IconButton
              // onClick={(() => removeFromInventory(item.id))}
              title='Product Available in Inventory'
              size='sm'
              style={{ minHeight: '25px' }}>
              <WarehouseOutlinedIcon style={{ fontSize: '1.5rem', color: '#2da1e5' }} />
            </IconButton>
            : ''} */}
            <ReactToPrint
              trigger={() =>
                <IconButton variant="outlined" size='sm' style={{ minHeight: '25px', margin: item.inventory ? '0px 10px' : 0, }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                    <path d="M2 2h2v2H2z" />
                    <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                    <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                    <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                    <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
                  </svg>
                </IconButton>
              }
              content={() => componentRef.current}
            />
            <a
              style={{ margin: item.inventory ? 0 : '0px 10px' }}
              href={`https://${orderData?.domain?.replace('thwifty.com', 'getthwifty.com')}/product/${item.store_id}/${functionIdLink(item.product_id, item.pid, item.store_id)}`}
              target='_blank'>
              <IconButton variant="outlined" size='sm' style={{ minHeight: '25px' }}>
                <LanguageIcon style={{ fontSize: '1rem' }} />
              </IconButton>
            </a>

            <FileUpload itemId={item.id} sInvoice={item.sinvoice} />

          </div>
          <div className='f-flex-wrap' style={{ display: 'flex', alignItems: 'center' }}>


            <Dropdown>
              <MenuButton
                disabled={!orderData?.status}
                style={{ padding: '0px 8px', marginRight: '10px', borderRadius: '6px', minHeight: '25px', fontWeight: 400 }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <small>Update</small>
                  <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                </div>
              </MenuButton>
              <Menu className='listUpdate-card'>
                <MenuItem
                  disabled={item.state === 'approved'}
                  onClick={(() => updateItemState({
                    item_state: true,
                    state: 'approved',
                    itemId: item.id,
                    orderId: orderData?.id
                  }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      disabled={item.state === 'approved'}
                      size='sm' checked={item.state === 'approved'} />
                    <span style={{ margin: '0px 10px' }}>Approve item</span>
                  </div>
                </MenuItem>
                <MenuItem
                  disabled={item.state === 'pending'}
                  onClick={(() => updateItemState({
                    item_state: true,
                    state: 'pending',
                    itemId: item.id,
                    orderId: orderData?.id
                  }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      disabled={item.state === 'pending'}
                      size='sm' checked={item.state === 'pending'} />
                    <span style={{ margin: '0px 10px' }}>Mark as pending</span>
                  </div>
                </MenuItem>
                <MenuItem
                  disabled={item.state === 'highlight'}
                  onClick={(() => updateItemState({
                    item_state: true,
                    state: 'highlight',
                    itemId: item.id,
                    orderId: orderData?.id
                  }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      disabled={item.state === 'highlight'}
                      size='sm' checked={item.state === 'highlight'} />
                    <span style={{ margin: '0px 10px' }}>Highlight</span>
                  </div>
                </MenuItem>

                <MenuItem
                  disabled={item.state === 'requires_attention'}
                  onClick={(() => updateItemState({
                    item_state: true,
                    state: 'requires_attention',
                    itemId: item.id,
                    orderId: orderData?.id
                  }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      disabled={item.state === 'requires_attention'}
                      size='sm' checked={item.state === 'requires_attention'} />
                    <span style={{ margin: '0px 10px' }}>Requires Attention</span>
                  </div>
                </MenuItem>
                <MenuItem onClick={(() => updateDeliveryDays({
                  state: 'update_delivery',
                  estimated_delivery: item.estimated_delivery,
                  itemId: item.id,
                  image: item.image,
                  orderId: orderData?.id
                }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      size='sm' />
                    <span style={{ margin: '0px 10px' }}>Update delivery days</span>
                  </div>
                </MenuItem>


                <MenuItem onClick={(() => itemWeightModal({
                  itemId: item.id,
                }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      size='sm' />
                    <span style={{ margin: '0px 10px' }}>Update Weight</span>
                  </div>
                </MenuItem>


                <MenuItem onClick={(() => itemQuantityModal({
                  itemId: item.id,
                }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      size='sm' />
                    <span style={{ margin: '0px 10px' }}>Split Item Quantity</span>
                  </div>
                </MenuItem>

                <MenuItem onClick={(() => updateItemPopup({
                  itemId: item.id,
                  image: item.image,
                }))}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      disabled={item.state === 'cancelled'}
                      size='sm' checked={item.state === 'cancelled'} />
                    <span style={{ margin: '0px 10px' }}>Cancel item</span>
                  </div>
                </MenuItem>
              </Menu>
            </Dropdown>
            <Button disabled={!orderData?.status} className='pg-button-hp' size='sm' style={{ margin: '0px 0px', minHeight: '25px', maxHeight: '25px' }} variant='outlined' onClick={(() => extraUpdateModal({
              itemId: item.id,
              pprice: item.pprice,
              pvat: item.pvat,
              pshipping: item.pshipping,
              fmile: item.fmile,
              lmile: item.lmile,
              tracking_id: item.tracking_id,
              s_ref: item.s_ref,
              s_name: item.s_name,
            }))}>
              <small>Purchase Info</small>
            </Button>
          </div>

        </div>

        <div className='order-details-mv' style={{ padding: 0, border: 'none' }}>
          <div style={{ margin: '0 5px', padding: '0px', borderRadius: '4px', position: 'relative' }}>
            <img src={`${item.image}`} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
            <span className='badge badge-warning' style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, borderRadius: '100px', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', color: '#fff', background: '#fe7f4a' }}>{item.quantity}</span>
          </div>
          <div style={{ padding: '0px 0px 0px 10px', width: '100%' }}>
            <ComponentToPrint ref={componentRef} value={item.id} orderId={orderData?.order_number} title={item.title} />
            {item.inventory ?
              <small style={{ fontSize: '12px', color: '#2da1e5', fontWeight: '500' }}>
                This product available in inventory</small>
              : ''}
            <div className='order-list-title' style={{ fontSize: '12px', lineHeight: 'normal' }}>{item.title}</div>
            <div className='dfww-sb' style={{ padding: '4px 0px 4px 0px' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                  <small> {item.currency}  {item.price} </small>
                </div>
                <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{ margin: "0 4px" }}
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    <small>{item.country} </small>
                  </div>
                </div>
                <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                  <small>QTY: {item.quantity}</small>
                </div>
                {item.conditions &&
                  <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                    <small>{item.conditions}</small>
                  </div>
                }
                {item.weight &&
                  <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                    <small>weight: &nbsp;</small> {Number(item.weight) / 1000}kg
                  </div>
                }
                {item.s_ref &&
                  <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                    <small>Ref.: &nbsp;</small> {item.s_ref}
                  </div>
                }
              </div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                Status:&nbsp;
                <span className={oiBgColor(item.state)} style={{ textTransform: 'capitalize' }}>{item.state?.replace('_', ' ')}
                </span>
              </div>
            </div>


            {item.current_variant &&
              <div style={{ padding: '0px 0px 5px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {Object.entries(item.current_variant || []).map(([key, value], index) => (
                  <div key={index} className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                    <small>{key}: &nbsp;</small> {value}
                  </div>
                ))}
              </div>

            }

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 0px 0px 0px', whiteSpace: 'nowrap', overflow: 'auto' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                  <small>Item ID: &nbsp;</small> {item.id}
                </div>

                {Number(item.store_id) === 3 &&
                  <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                    <small>ASIN: &nbsp;</small> {item.asin}
                  </div>
                }
                {item.store_id === 3 &&
                  <>
                    <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                      <small>PID: &nbsp;</small> {item.pid}
                    </div>
                    <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                      <small>product ID: &nbsp;</small> {item.product_id}
                    </div>
                  </>
                }
                {item.store_id !== 3 &&
                  <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                    <small>product ID: &nbsp;</small> {item.product_id}
                  </div>
                }
                <div className='alert alert-light alertLight' style={{ display: 'flex', alignItems: 'center' }}>
                  <small>Supplier: &nbsp;</small> {item.store?.name} - {item.store?.id}
                </div>
              </div>
            </div>
            <small style={{ display: 'flex', alignItems: 'center', fontSize: '13px', justifyContent: 'flex-end' }}>
              Consolidation:&nbsp; <span className={getItemStatus(item.status)?.classname} style={{ textTransform: 'capitalize' }}>{getItemStatus(item.status)?.name}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  )

}

export default ItemList;
