import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { createEntityAdapter } from '@reduxjs/toolkit'
import io from "socket.io-client";
const messagesAdapter = createEntityAdapter()
const access = "full_access"

export const consolidationApi = createApi({

  reducerPath: 'consolidationApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Consolidation'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({

    fetch: builder.query({
      query: (data) => {
        return {
          url: `consolidation/${data.id}?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Consolidation'],
    }),

    update: builder.mutation({
      query: (data) => {
        return {
          url: `consolidation/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Consolidation'],
    }),


  }),

})

export const { useFetchQuery, useUpdateMutation } = consolidationApi