import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllMediaQuery, useUpdateMediaMutation, useDeleteMediaMutation } from '../../services/media';
import ImageUpdate from "./ImageUpdate";
import IconButton from '@mui/joy/IconButton';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import Button from '@mui/joy/Button';
import CloseIcon from "@material-ui/icons/Close";
import Loader from '../../Helper/Loader';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import Add from '@mui/icons-material/Add';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveIcon from '@mui/icons-material/Remove';

function Media() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const location = useLocation();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const getLanguage = new URLSearchParams(location.search).get('language') || 'en'

  const { data: mediaData, isLoading, isFetching, error, refetch } = useGetAllMediaQuery({
    page: page,
    size: limit,
    search: search,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  },
    { count: 1 },
    { refetchOnMountOrArgChange: true })
  const media = mediaData?.media;
  const pagination = mediaData?.pagination;

  const [deleteMedia, deleteresult] = useDeleteMediaMutation()
  const [updateImage, imageresult] = useUpdateMediaMutation()

  const [getUploadingStatus, setUploadingStatus] = useState("");
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getImageError, setImageError] = useState("");
  const [getUpdateImage, setUpdateImage] = useState("");
  const [getImageBookId, setImageBookId] = useState("");


  function updateBookImage() {
    updateImage({
      image_file: getUpdateImage,
      category_id: getImageBookId,
      languageCode: getLanguage,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
    })
  }
  useEffect(() => {
    if (imageresult.isSuccess) {
      setImageUpdateModal(false)
    }
  }, [imageresult]);
  function imageUpdate(data) {
    setImageUpdateModal(true)
    setImageBookId(data.category_id)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }


  function displayMediaFile(name) {
    return (
      <img src={`https://cdn.ebtida.com/${name}`} width={'100%'} />
    )
  }

  function importModal() {
    if (getImageUpdateModal === 'uploading') {
      return true
    } else if (imageresult?.isLoading) {
      return true
    } else {
      return false
    }
  }

  function getLoading(loading, fetching) {
    if (loading) return true
    if (fetching) return true
    return false

  }

  function searchOrder(data) {
    const result = data?.orderId;
    navigate(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset()
    navigate(`${window.location.pathname}`)
  }

  return (
    <>
      <div className="tabel_cards category-page">

        <Modal
          className="image-update-modal-video"
          show={getImageUpdateModal}
          onHide={closeImageUpdate}
          animation={false}
          backdrop={importModal() ? 'static' : 'dynamic'}
        >

          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={importModal() ? '' : closeImageUpdate}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>

            {imageresult?.error &&
              <span className='alert alert-danger' style={{ display: 'block', textAlign: 'center', width: '100%' }}>{imageresult?.error?.data?.error}</span>
            }

            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Add Media</h5>
              </div>
            </div>

            <div>{getImageError && getImageError}</div>
            <ImageUpdate
              setUploadingStatus={setUploadingStatus}
              setUpdateImage={setUpdateImage}
              getUpdateImage={getUpdateImage}
              imageresult={imageresult}
              updateBookImage={updateBookImage}
            />
          </Modal.Body>
        </Modal>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    disabled={isLoading ? true : false}
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                </div>
                <div className="table_search_card">
                  <div>
                    <Button
                      className='pg-button-hp'
                      style={{ fontWeight: 400 }}
                      variant="soft"
                      size="sm"
                      onClick={() => imageUpdate(true)}>
                      <span style={{ marginRight: '5px' }}>Add New</span>
                      <AddCircleOutlinedIcon style={{ fontSize: '1.1rem', }} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12">
            <div style={{ position: 'relative' }}>

              {getLoading(isLoading, isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <div className='row'>
                {media?.map((item, i) =>
                  <div className="col-md-2 media-card" key={i}>
                    <div className="media-subcard">
                      <div style={{ marginBottom: '5px' }}>
                        {displayMediaFile(item.name)}
                      </div>
                      <div style={{ wordBreak: 'break-all', fontSize: '12px', whiteSpace: 'normal' }}>
                        <a href={`https://cdn.ebtida.com/${item.name}`} target="_blank">
                          {`https://cdn.ebtida.com/${item.name}`}
                        </a>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '12px' }}>{item.createdAt}</div>
                        <div className="manage-buttons" style={{ justifyContent: 'right' }}>
                          {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ? <Loader /> :
                            <IconButton
                              variant='soft'
                              size='sm'
                              color='danger'
                              style={{ minHeight: '20px', maxHeight: '20px' }}
                              onClick={() => deleteMedia({
                                id: item.id,
                                name: item.name,
                                userId: userId,
                                userToken: userToken,
                                userRole: userRole,
                              })}
                            >
                              <RemoveIcon style={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>

            </div>
            {error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{error?.data}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;
